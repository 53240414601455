import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import $ from 'jquery';
import Vue from 'vue';
import router from '@/router';
import api from '../../api';
import helper from './common/common';

const addFormStruct = {
  groups: [],
  search: '',
  card: false,
  title: '',
  content: '',
  sendAIToken: '', // 問AI的內容
  secured: 0, // 0: 一般PO文 1: 秘密PO文
  advertise: 0, // 0: 無廣告 1: 有廣告(一般) 2: 愛心廣告 3: 愛心廣告(顯示提示)
  advertiseTemplate: 1, // 廣告版型
  groupOptions: [],
  cardOptions: [],
};

export const state = {
  form: { ...addFormStruct },
  video: [],
  images: [],
  repformID: 0, // PO文完畢取得之文章編號
  quickPostPreview: false, // 愛廣告PO文預覽開啟與否
  quickPostTab: 0, // 愛廣告PO切頁 0: PO文 1: 帳戶
  IsAskingAI: false, // 是否詢問AI中
  ...helper.state,
};

export const mutations = {
  updateField,
  ...helper.mutations,
};

export const getters = { getField };

export const actions = {
  init: ({ state, commit }) => {
    api.exposed.initList({ order: state.order, page: state.page, searchStr: state.searchStr }).then((rep) => {
      if (rep.data.body.exposed.length === 0) {
        commit('setValue', { key: 'empty', val: true });
      } else {
        commit('setValue', { key: 'list', val: [...state.list].concat(rep.data.body.exposed) });
      }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  loadMore: ({ state, commit, dispatch }) => {
    commit('setValue', { key: 'page', val: state.page + 1 });
    dispatch('init');
  },
  search: ({ dispatch, commit }, { searchStr }) => {
    commit('setValue', { key: 'searchStr', val: searchStr });
    commit('setValue', { key: 'list', val: [] });
    dispatch('init');
  },
  initAddForm: ({ commit, rootState }) => {
    if (localStorage.getItem('u')) {
      api.exposed.init().then((rep) => {
        const form = { ...addFormStruct };
        form.cardOptions = rep.data.body.cards;
        form.card = rootState.account.card;
        form.groupOptions = rep.data.body.groups;
        form.groups = [];
        commit('setValue', { key: 'form', val: form });
        commit('setValue', { key: 'images', val: [] });
        commit('setValue', { key: 'video', val: [] });
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  resetForm: ({ commit, rootState }) => {
    const form1 = { ...state.form };
    form1.content = '';
    form1.sendAIToken = '';
    form1.secured = 0;
    form1.advertise = 0;
    commit('setValue', { key: 'form', val: form1 });
    commit('setValue', { key: 'images', val: [] });
    commit('setValue', { key: 'video', val: [] });
  },
  createForm: ({ state, commit, dispatch }) => {
    const formData = new FormData();
    formData.append('title', state.form.title);
    formData.append('card_id', state.form.card.id);
    for (let i = 0; i < state.images.length; i += 1) {
      formData.append(`images[${i}]`, state.images[i], state.images[i].name);
    }
    for (let i = 0; i < state.video.length; i += 1) {
      formData.append(`video[${i}]`, state.video[i], state.video[i].name);
    }
    formData.append('groups', state.form.groups.reduce(
      (a, b) => {
        a.push(b.id);
        return a;
      },
      [],
    ).join());
    api.exposed.createForm(formData).then(() => {
      $('#exampleModal').modal('hide');
      Vue.$gtag.event('文章數量', {
        event_category: '發文數',
        event_label: state.form.card.id,
        value: 1,
      });
      commit('reset');
      dispatch('init');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 新版Po文
  postCreate: ({ state, commit, dispatch }, Data) => {
    const formData = new FormData();
    formData.append('content', state.form.content);
    formData.append('secured', state.form.secured); // 1:秘密PO文 0:公開
    formData.append('locale', Data.language); // 語系 0: 中文 1: 英文 2: 日文
    formData.append('ad', state.form.advertise); // 3:愛心廣告(顯示提示) 2:愛心廣告 1:有廣告(一般) 0:無
    for (let i = 0; i < state.images.length; i += 1) {
      formData.append(`images[${i}]`, state.images[i], state.images[i].name);
    }
    for (let i = 0; i < state.video.length; i += 1) {
      formData.append(`video[${i}]`, state.video[i], state.video[i].name);
    }
    let idx = 0;
    for (let i = 0; i < state.taglist.nowselectTags.length; i += 1) {
      if (state.taglist.nowselectTags[i][4] > 0) { // 非系統類別
        formData.append(`tag_name_array[${idx}]`, state.taglist.nowselectTags[i][1]); // 標籤名稱 字串陣列 ["標籤1","標籤2"]. 後台會建立標籤
        idx += 1;
      }
    }
    idx = 0;
    for (let i = 0; i < state.taglist.nowselectTags.length; i += 1) {
      if (state.taglist.nowselectTags[i][4] === 0) { // 系統
        formData.append(`system_tag_id_array[${idx}]`, state.taglist.nowselectTags[i][0]); // 系統標籤流水號 int陣列 [1,2,3]. 系統標籤不存在會噴error
        idx += 1;
      }
    }
    if (Data.userID > 0) {
      formData.append('user_tag_id_array[0]', Data.userID); // 使用者標籤(社團)流水號 int陣列 [1,2,3]. 該社團不存在會噴error
    }
    console.log(...formData);
    commit('setValue', { key: 'repformID', val: 0 });

    return api.exposed.postcreate(formData).then((rep) => {
      // console.log('postCreate success!');
      dispatch('resetForm');
      if (rep.data.body) {
        commit('setValue', { key: 'repformID', val: rep.data.body.id });
      }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      console.log(`postcreate error:${JSON.stringify(error)}`);
      window.alert('發文失敗，請重新再試。'); // eslint-disable-line no-alert
    });
  },
  getUrlMeta: ({ state, commit }) => {
    api.exposed.getUrlMeta({ url: state.url }).then((rep) => {
      commit('setPreview', { preview: rep.data });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  clearUrlMeta: ({ state, commit }) => {
    commit('setPreview', { preview: {} });
  },
  getPostUrlMeta: ({ state, commit }, { PostID, Url }) => {
    commit('setPostUrlPreview', { key: PostID, val: 'empty' });
    api.exposed.getUrlMeta({ url: Url }).then((rep) => {
      commit('setPostUrlPreview', { key: PostID, val: rep.data });
      // console.log(`getPostUrlMeta:${JSON.stringify(rep.data)}`);
    }).catch((error) => {
      // console.log(`getPostUrlMeta error:${error}`);
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 設定愛廣告PO文預覽是否開啟
  setQuickPostPreview: ({ state, commit }, Value) => {
    commit('setValue', { key: 'quickPostPreview', val: Value });
  },
  // 設定愛廣告PO文切頁
  setQuickPostTab: ({ state, commit }, Value) => {
    commit('setValue', { key: 'quickPostTab', val: Value });
  },
  // 設定是否詢問AI中
  setIsAskingAI: ({ state, commit }, Value) => {
    commit('setValue', { key: 'IsAskingAI', val: Value });
  },
};
