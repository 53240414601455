import ajax from 'axios';

export const init = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/message_board/init`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const create = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/message_board/create`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const remove = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/message_board/remove`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const good = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/message_board/good`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const bad = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/message_board/bad`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});
