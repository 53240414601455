import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import api from '../../api';
import helper from './common/common';

export const state = {
  text: '',
  sse: '',
  id: null,
  name: null,
  messages: new Map(),
  key: 0,
  b_key: 0,
};

export const mutations = {
  updateField,
  ...helper.mutations,
};

export const getters = {
  userId: (state, getters, rootState) => (rootState.account.card.id > 0 ? rootState.account.card.id : state.id),
  getKey(state) {
    return state.key;
  },
  getField,
};

export const actions = {
  init: ({ commit, state }) => {
    if (!state.sse) {
      const sse = new EventSource(`${process.env.VUE_APP_API_DOMAIN}/public_chat/init`, {
        withCredentials: true,
      });
      commit('setValue', { key: 'sse', val: sse });
      sse.addEventListener('init', (e) => {
        const data = JSON.parse(e.data);
        // eslint-disable-next-line no-bitwise
        commit('setValue', { key: 'id', val: data.id >> 0 });
        commit('setValue', { key: 'name', val: data.name });
        // eslint-disable-next-line no-restricted-syntax
        for (const row of data.messages) {
          const d = JSON.parse(row);
          state.messages.set(d[0], d);
        }
        commit('setValue', { key: 'key', val: state.key + 1 });
      }, false);
      sse.addEventListener('message', (e) => {
        const data = JSON.parse(e.data);
        state.messages.set(data[0], data);
        commit('setValue', { key: 'key', val: state.key + 1 });
      }, false);
      sse.addEventListener('error', (e) => {
        window.console.log('error', e);
      }, false);
    }
  },
  push: ({ commit, state, rootState }) => {
    api.chatRoom.push({
      params: { type: 'group' },
      postId: rootState.account.card.id > 0 ? rootState.account.card.id : state.id,
      content: state.text,
      jwt: window.localStorage.getItem('t'),
    }).then(() => {
      commit('setValue', { key: 'text', val: '' });
      commit('setValue', { key: 'b_key', val: state.b_key + 1 });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
};
