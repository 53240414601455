<template lang="pug">
  .modal-dialog
    .modal-content
      .modal-header.d-flex.justify-content-center.flex-column.align-items-center
        .modal-title.d-flex.flex-column.align-items-center.justify-content-center.mb-2
          img(:src="card.img").mb-2
          p.mb-0 {{card.name}}
        button.close(type='button' data-dismiss='modal' aria-label='Close')
          span(aria-hidden='true') &times;
        p.text-white.mb-0.fz24 我的名片簿
      .modal-body
        .d-flex.flex-column.align-items-center
          .d-flex.justify-content-center
            .redBoxSwitch.my-4.d-flex.justify-content-center.align-items-center(:class="{active:active===1}" @click="active=1") 切換名片
            .redBoxSwitch.my-4.d-flex.justify-content-center.align-items-center.ml-4(:class="{active:active===2}" @click="active=2") 名片管理
          .w-75.totalCardContent.d-flex.flex-wrap(v-if="active===1")
            .w-25.eachCard.d-flex.flex-column.align-items-center.mr-1(v-for="item in user.cards"  :class="{'bg-primary': card.id === item.id}")
              .imgBox.bg-light.p-3.w-75
                img(:src="item.img").mb-2
                p.mb-0.text-dark.font-weight-bold {{item.name}}
              button.btn.font-weight-bold.mt-2.text-light(@click="setCardActive({id:item.id})" :class="{'bg-dark': card.id === item.id}") {{card.id === item.id ? '啟用中' : '切換'}}
          .w-75.addCardBtn(v-if="active===2")
            .addCard.my-3(@click="reset" v-if="user.cards.length < 7")
              p.mb-0.ml-2 新增名片({{user.cards.length}}/7)
            .addCardFunction(v-if="addCardFunction")
              .d-flex.flex-column.align-items-center
                input(type="file" accept="image/x-png,image/gif,image/jpeg" id="head" @change="resize({event:$event,field:'findcompany_business_card_img',maxWidth:350,maxHeight:350})").d-none
                label(for="head" v-bind:style="{ backgroundImage:  findcompany_business_card_img ? 'url(' + findcompany_business_card_img + ')' : findcompany_business_card_img }").headPhoto.d-flex.flex-column.justify-content-center.align-items-center
                  p.mb-0(v-if="!findcompany_business_card_img") 上傳
                  p.mb-0(v-if="!findcompany_business_card_img") 專業頭照
              p.secondTitle.font-weight-bold.text-left.mt-4 個人資訊
              .inputBox
                input.form-control.grayBox.text-right(type="text" v-model="findcompany_business_card_name" placeholder="請輸入暱稱")
                p.mb-0.inputTitle 暱稱
              .d-flex.flex-column.align-items-center
                .redBoxSwitch.my-4.d-flex.justify-content-center.align-items-center.active(@click="submit" v-if="findcompany_business_card_id === 0") 新增
          .w-75.totalCardContent.d-flex.flex-wrap(v-if="active===2")
            .w-25.eachCard.d-flex.flex-column.align-items-center(v-for="item in user.cards" :class="{'bg-primary': findcompany_business_card_id === item.id}")
              .imgBox.bg-light.p-3.w-75
                img(:src="item.img").mb-2
                p.mb-0.text-dark.font-weight-bold {{item.name}}
              button.btn.font-weight-bold.mt-2.text-light(:class="{'bg-dark': findcompany_business_card_id === item.id}" @click="setCard(item)") 點我編輯
          .editCardFunction(v-if="editCardFunction")
            .d-flex.flex-column.align-items-center
              input(type="file" accept="image/x-png,image/gif,image/jpeg" id="head" @change="resize({event:$event,field:'findcompany_business_card_img',maxWidth:350,maxHeight:350})").d-none
              label(for="head" v-bind:style="{ backgroundImage:  findcompany_business_card_img ? 'url(' + findcompany_business_card_img + ')' : findcompany_business_card_img }").headPhoto.d-flex.flex-column.justify-content-center.align-items-center
                p.mb-0(v-if="!findcompany_business_card_img") 上傳
                p.mb-0(v-if="!findcompany_business_card_img") 專業頭照
            p.secondTitle.font-weight-bold.text-left.mt-4 個人資訊
            .inputBox
              input.form-control.grayBox.text-right(type="text" v-model="findcompany_business_card_name" placeholder="請輸入暱稱")
              p.mb-0.inputTitle 暱稱
            .d-flex.flex-column.align-items-center
              .redBoxSwitch.my-4.d-flex.justify-content-center.align-items-center.active(@click="submit" v-if="findcompany_business_card_id > 0") 更新
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  data() {
    return {
      active: 1,
      image: '',
      addCardFunction: false,
      editCardFunction: false,
    };
  },
  name: 'BaseContent',
  props: ['post'],
  methods: {
    ...mapActions('dashboard', ['redirectPost']),
    ...mapActions('account', ['resize', 'saveCard', 'setCardActive']),
    clickDom(e) {
      const { target } = e;
      const addCardDom = document.getElementsByClassName('addCardFunction');
      const editCardDom = document.getElementsByClassName('editCardFunction');

      if (addCardDom.length !== 0 && !addCardDom[0].contains(target)) {
        this.addCardFunction = false;
      }

      if (editCardDom.length !== 0 && !editCardDom[0].contains(target)) {
        this.editCardFunction = false;
      }
    },
    setCard(card) {
      this.editCardFunction = !this.editCardFunction;
      this.addCardFunction = false;
      this.findcompany_business_card_img = card.img;
      this.findcompany_business_card_id = card.id;
      this.findcompany_business_card_name = card.name;
    },
    reset() {
      this.addCardFunction = !this.addCardFunction;
      this.editCardFunction = false;
      this.findcompany_business_card_img = '';
      this.findcompany_business_card_id = 0;
      this.findcompany_business_card_name = '';
    },
    submit() {
      this.editCardFunction = false;
      this.addCardFunction = false;
      this.saveCard();
    },
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapFields('account', ['card', 'findcompany_business_card_img', 'findcompany_business_card_id', 'findcompany_business_card_name']),
    ...mapState({
      switchoption: (state) => state.switchoption,
    }),
  },
  mounted() {
    document.addEventListener('click', (e) => { this.clickDom(e); }, true);
  },
  destroyed() {
    document.removeEventListener('click', (e) => { this.clickDom(e); }, true);
  },
};
</script>

<style lang="scss" scoped>

.w-25 {
  width: 24%!important;
}
.btn,.redBoxSwitch{
  cursor: pointer;
}
.card-name{
  width: 250px;
  margin: auto;
}

// .beautifySettingBox
.beautifySettingBox {
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 59px;
  padding: 16px;
  box-sizing: border-box;
}

.settingPhoto {
  position: relative;
}

.headPhoto {
  width: 120px;
  height: 120px;
  background: #EBEBEB 0% 0% no-repeat padding-box;
  border: 5px solid #FFFFFF;
  border-radius: 30px;
  bottom: -75px;
  color: #2277FF;
  font-size: 20px;
  font-weight: bold;
  background-size: cover;
}

.adminImg {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.adminTitle {
  font-size: 20px;
}

.adminBtn {
  width: 150px;
  height: 54px;
  border-width: 3px;
  box-sizing: border-box;
  border-radius: 27px;
  position: relative;

  &.border-primary, &.border-dark {
    border-left: 0px;
  }

  &:focus {
    outline: none;
  }

  &.borderBlack {
    border-color: black;
    color: #2277FF;
  }

  .imgBox {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;

    &.border-primary {
      border: 3px solid #DD4B6E;
    }

    img {
      width: 30px;
    }
  }
}

.myPost {
  font-size: 20px;
  font-weight: bold;
}

.dynamicWallContent {
  margin-top: 10px;
}

.cardBox {
  margin-top: 10px;
  cursor: pointer;

  .cardBox-head {
    max-width: 130px;
    height: 110px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 2px 2px #00000029;
    position: relative;

    img[alt="main"] {
      max-width: 130px;
      border-radius: 10px;
      z-index: 1;
    }

    .readedNum {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;;
      right: 10px;
      bottom: 10px;
      z-index: 100;

      span {
        margin-left: 10px;
        font-size: 12px;
        line-height: 12px;
        color: white;
      }
    }
  }

  .cardBox-footer {
    max-width: 130px;
    height: 22px;
    line-height: 14px;
    overflow: hidden;
    font-size: 14px;
    color: #BAB9B9;
    padding-top: 10px;
    box-sizing: border-box;
  }
}

.cardBox-body {
  max-height: 152px;
  max-width: 130px;
  overflow: hidden;
  border: 2px solid #F8A6E5;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 13px;
  color: #BAB9B9;
  position: relative;
  padding: 16px;

  .readedNum {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }
}

// 切換卡片modal
.modal-header {
  display:none !important;
}

.modal-dialog {
  max-width: 80vw;
  height: 80vh;
  overflow-y: scroll;
}

.modal-content,.cardSetting {
  max-height: none;
  max-width: none;
  overflow-y: hidden;
  height: auto;
  border: none;
}

.modal-dialog ,.cardSetting {
  max-height: none;
  max-width: none;
  overflow-y: hidden;
  height: auto;
}

.modal-header {
  background: black;
  color: white;
  position: relative;

  .close :not(.cardSetting) {
    color: white;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .modal-title {
    width: 125px;
    height: 125px;
    background: rgba(112, 112, 112, .1);

    img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      box-shadow: 0px 3px 6px #00000029;;
    }
  }
}

.modal-body {
  .totalCardContent {
    height: 490px;
    overflow-y: scroll;
    background: rgba(235, 235, 235, .42);
    padding: 16px;
    box-sizing: border-box;
    border-radius: 30px;

    .eachCard {
      // background: #000;
      margin: 0px 0px 16px 0;
      padding: 10px 0;
      border-radius: 17px;
      height: 220px;

      .imgBox {
        border-radius: 36px;
      }

      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      .btn {
        height: 50px;
        border-radius: 25px;
        background: rgba(0, 0, 0, .13);
      }
      p{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .redBoxSwitch {
    width: 120px;
    height: 50px;
    border-radius: 14px;
    font-size: 20px;
    font-weight: bold;
    color: black;

    &.active {
      background: #DD4B6E;
      color: white;
    }
  }

  .addCardBtn{
    position: relative;
  }

  .addCard{
    width: 120px;
    height: 50px;
    border-radius: 25px;
    display:flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #000;
    left:20px;
    top: 16px;
    cursor: pointer;
  }

  .addCardFunction {
    position:absolute;
    background: white;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    width: 600px;
    padding: 20px 16px;
    border-radius: 13px;
    top:120%;
    left:0;
    color: #292929;
    &::before{
      content:'';
      display:block;
      background: transparent;
      width: 0;
      height: 0;
      // border-top: 10px solid rgba(0,0,0,0);
      border-bottom: 10px solid white ;
      border-right: 10px solid rgba(0,0,0,0);
      border-left: 10px solid rgba(0,0,0,0);
      position:absolute;
      top: -10px;
      left: 15px;
      z-index: 2;
    }
    &::after{
      content:'';
      display:block;
      background: transparent;
      width: 0;
      height: 0;
      // border-top: 10px solid rgba(0,0,0,0);
      border-bottom: 14px solid #FFCEDA ;
      border-right: 14px solid rgba(0,0,0,0);
      border-left: 14px solid rgba(0,0,0,0);
      position:absolute;
      top: -14px;
      left: 11px;
      z-index: 1;
    }
  }

  .addCardFunction,.editCardFunction{
    position:absolute;
    background: white;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    width: 600px;
    padding: 20px 16px;
    border-radius: 13px;
    color: #292929;
  }

  .editCardFunction {
    top: 35%;
  }

  .inputBox{
    position: relative;
    .inputTitle{
      position:absolute;
      top: 10px;
      left: 16px;
    }
    input{
      height: 60px;
    }
  }
}
</style>
