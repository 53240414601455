<template lang="pug">
  .d-flex.flex-wrap(:class="{ 'ml-5': !root, 'border-left': !root, 'mt-4': !root }")
    .badgeProto(:class="{ badgeSelect: selected.has(item.key),badgeUnSelect:!selected.has(item.key) }" :key="idx" v-for="(item,idx) in items" @mouseover="hover=item" @click="select({item:item})" ) {{item.name}}
    template(v-for="(item) in items")
      BaseNode(:items="item.children" v-if="!Array.isArray(item.children) && visible(item)" :selected="selected" :select="select" :root="false")
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'BaseNode',
  props: ['items', 'root', 'selected', 'select'],
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    visible(item) {
      return this.hover === item;
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.classFilter {
  position: absolute;
  width: 800px;
  padding: 16px 30px;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 29px;
  background: white;
  left: 30%;
  top: 100%;
  z-index: 101;
}

.canSelectMore {
  color: #BAB9B9;
  font-size: 20px;
}

.badgeProto {
  padding: 8px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-sizing: border-box;
  height: 48px;
  width: 100px;
  margin-left: 10px;
  margin-bottom: 8px;
}

.badgeSelect {
  border: 2px solid #DD4B6E;
  background: rgba(255, 127, 158, .4);
  cursor: pointer;
}

.badgeUnSelect {
  border: 2px solid black;
  background: white;
  cursor: pointer;
}
</style>
