<template lang="pug">
  div
    hideAt(breakpoint="large")
      .d-flex.grayBg.flex-column
        .w-100
          .personalCover.d-flex.justify-content-center.align-items-center(:style="`background-image: url('${data.cover}');background-size: cover;`")
            p.mb-0
            .blackcircle(:style="`: url('${data.cardImg}');background-size: cover;`")
            p.mb-0.amyNickName.mt-3.ml-2 {{data.cardName}}
          .d-flex.justify-content-end.my-5
            button.adminBtn.border-primary.font-weight-bold(@click.stop="followUser({userId:data.cardId})").mr-3
              .imgBox.border-primary.bg-white
                <img src="../assets/club/pin.png">
              span.font-weight-bold {{followStr(data.cardId)}}
            button.adminBtn.d-flex.borderBlack.align-items-center
              .imgBox.bg-dark
                <img src="../assets/club/chat.png">
              span.font-weight-bold.ml-3(@click.stop="joinChat({id:data.cardId})") 發送訊息
        .w-100
          .socialDetail
            table.w-100
              tbody
                tr
                  td.text-left 社群
                  td.text-left(rowspan="6" v-html="data.about")
                tr
                  td.text-left.text-log(v-if="data.fb")
                    .d-flex.align-items-center.mb-2
                      img(src="../assets/eachPost/fb.svg")
                      p.mb-0.ml-2.socialName(v-html="data.fb")
                  td.text-left
                tr
                  td.text-left.text-log(v-if="data.line")
                    .d-flex.align-items-center.mb-2
                      img(src="../assets/eachPost/line.svg")
                      p.mb-0.ml-2.socialName(v-html="data.line")
                  td.text-left
                tr
                  td.text-left.text-log(v-if="data.ig")
                    .d-flex.align-items-center.mb-2
                      img(src="../assets/eachPost/ig.svg")
                      p.mb-0.ml-2.socialName(v-html="data.ig")
                tr
                  td.text-left.text-log(v-if="data.youtube")
                    .d-flex.align-items-center.mb-2
                      img(src="../assets/eachPost/youtube.svg")
                      p.mb-0.ml-2.socialName(v-html="data.youtube")
                tr
                  td.text-left.text-log(v-if="data.twitter")
                    .d-flex.align-items-center.mb-2
                      img(src="../assets/eachPost/twitter.svg")
                      p.mb-0.ml-2.socialName(v-html="data.twitter")
    showAt(breakpoint="large")
      .d-flex.grayBg.flex-column
        .d-flex
          .w-50
            .personalCover.d-flex.justify-content-center.align-items-center(:style="`background-image: url('${data.cover}');background-size: cover;`")
              p.mb-0
              .blackcircle(:style="`background-image: url('${data.cardImg}');background-size: cover;`")
              p.mb-0.amyNickName.mt-2 {{data.cardName}}
            .d-flex.justify-content-end.mt-3
              button.adminBtn.border-primary.font-weight-bold(@click.stop="followUser({userId:data.cardId})").mr-3
                .imgBox.border-primary.bg-white
                  <img src="../assets/club/pin.png">
                span.ml-4.font-weight-bold {{followStr(data.cardId)}}
              button.adminBtn.d-flex.borderBlack.align-items-center
                .imgBox.bg-dark
                  <img src="../assets/club/chat.png">
                span.ml-5.font-weight-bold(@click.stop="joinChat({id:data.cardId})") 發送訊息
          .w-50
            .socialDetail
              table.w-100
                tbody
                  tr
                    td.text-left 社群
                    td.text-left(rowspan="6" v-html="data.about")
                  tr
                    td.text-left.text-log(v-if="data.fb")
                      .d-flex.align-items-center.mb-2
                        img(src="../assets/eachPost/fb.svg")
                        p.mb-0.ml-2.socialName(v-html="data.fb")
                    td.text-left
                  tr
                    td.text-left.text-log(v-if="data.line")
                      .d-flex.align-items-center.mb-2
                        img(src="../assets/eachPost/line.svg")
                        p.mb-0.ml-2.socialName(v-html="data.line")
                    td.text-left
                  tr
                    td.text-left.text-log(v-if="data.ig")
                      .d-flex.align-items-center.mb-2
                        img(src="../assets/eachPost/ig.svg")
                        p.mb-0.ml-2.socialName(v-html="data.ig")
                  tr
                    td.text-left.text-log(v-if="data.youtube")
                      .d-flex.align-items-center.mb-2
                        img(src="../assets/eachPost/youtube.svg")
                        p.mb-0.ml-2.socialName(v-html="data.youtube")
                  tr
                    td.text-left.text-log(v-if="data.twitter")
                      .d-flex.align-items-center.mb-2
                        img(src="../assets/eachPost/twitter.svg")
                        p.mb-0.ml-2.socialName(v-html="data.twitter")
</template>

<script>
import { showAt, hideAt } from 'vue-breakpoints';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    showAt,
    hideAt,
  },
  name: 'BasePostAbout',
  props: ['data'],
  methods: {
    ...mapActions('account', ['followUser']),
    ...mapActions('chat', ['joinChat']),
  },
  computed: {
    ...mapGetters('account', ['followStr']),
  },
};
</script>

<style lang="scss" scoped>
@media (max-width:980px){
  .adminBtn{
    width: 90px!important;
    height: 24px!important;
    font-size: 9px!important;
    .imgBox{
      width: 21px!important;
      height: 21px!important;
      img {
        width: 15px!important;
        height: 15px!important;
      }
    }
  }
  .personalCover .blackcircle{
    border-radius: 10px!important;
    border: 1px solid #FFFFFF!important;
    left: 14px!important;
  }
}
.eachPostContent {
  padding: 100px;
  // padding-left:30px;
  padding-top: 16px;
  // padding-bottom: 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: scroll;
  height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

hr {
  width: 100px;
  border-color: #ebebeb;
  border-width: 3px;
}

.from {
  font-size: 26px;
  color: #BAB9B9;
  font-weight: bold;
}

.fromBlueBox {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  background: #2277ff;
}

.eachPageName {
  font-size: 20px;
  font-weight: bold;
}

.grayBorderBox {
  margin-top: 40px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 3px #00000029;
}

.nameCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #555;
}

.name {
  font-size: 26px;
  color: #555;
}

.pinGrayBorder {
  height: 40px;
  border: 3px solid #EBEBEB;
  border-radius: 20px;
  border-left: 0;
}

.pin {
  width: 40px;
  height: 40px;
}

.followWord {
  color: #555555;
  margin-left: 8px;
  position: relative;

  .whoFollow {
    width: 600px;
    padding: 16px;
    border-radius: 17px;
    // height: 700px;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    position: absolute;
    top: 150%;
    left: -300px;
    background: white;
    z-index: 100;
  }

  .btn-follow {
    width: 120px;
    height: 40px;
    border-radius: 27px;
    border: 2px solid #2277FF;
    color: #2277ff;
    font-weight: bold;
  }

  .btn-follow:hover {
    background: #2277FF;
    color: white;
  }
}

// 分享跟其他功能 下拉視窗
.grayCircle {
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, .11);
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  .shareOption {
    position: absolute;
    width: 350px;
    padding: 16px;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    background: white;
    z-index: 100;
    top: 130%;
    right: 0;
    border-radius: 17px;
    font-size: 20px;

    .shareRedCircle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #dd4b6e;
    }
  }

  .otherFunction {
    position: absolute;
    width: 300px;
    padding: 16px;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    background: white;
    z-index: 100;
    top: 130%;
    right: 0;
    border-radius: 17px;
    font-size: 20px;

    .shareRedCircle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #dd4b6e;
    }
  }
}

.threeBlueDot {
  width: 8px;
  height: 8px;
  background: #2277ff;
  border-radius: 50%;
}

.photoTitle {
  font-size: 20px;
  color: #555555;
  font-weight: bold;
}

.grayBadge {
  width: 80px;
  height: 32px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .1);
  font-size: 14px;
  color: black;
  margin: 4px 6px;
}

.grayBg {
  padding: 16px;
  background: rgba(235, 235, 235, .4);
  border-radius: 14px;
  margin-top: 40px;
}

.personalCover {
  background: #dd4b6e;
  border-radius: 15px;
  color: white;
  height: 130px;
  position: relative;

  .blackcircle {
    position: absolute;
    width: 70px;
    height: 70px;
    border: 3px solid white;
    background: black;
    border-radius: 50%;
    top: calc(100% - 35px);
    left: 36px;
  }

  .amyNickName {
    top: 125%;
    position: absolute;
    left: 20px;
    color: black;
    font-weight: bold;
  }
}

// adminBtn
.adminBtn {
  width: 140px;
  height: 54px;
  border-width: 2px;
  box-sizing: border-box;
  border-radius: 27px;
  position: relative;
  color: black;

  &.border-primary, &.border-dark {
    border-left: 0px;
  }

  &:focus {
    outline: none;
  }

  &.borderBlack {
    border-color: black;
    color: black;
  }

  .imgBox {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;

    &.border-primary {
      border: 2px solid #DD4B6E;
    }

    img {
      width: 30px;
    }
  }
}

.socialDetail {
  margin-left: 10px;
  padding: 12px;
  background: rgba(235, 235, 235, .5);
  border-radius: 15px;

  td {
    color: #555555;
    font-weight: bold;
    width: 50%;
  }

  .socialName {
    display : inline-block;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    width : 430px;
  }
}

.eachLittlePostContent {
  overflow: auto;
}

.eachLittlePost {
  // overflow-x:scroll;
  width: 150px;

  .imgBox {
    position: relative;
  }

  .mainImg {
    width: 150px;
    height: 150px;
    border-radius: 10px;
  }

  .eye {
    position: absolute;
    bottom: 16px;
    right: 50px;
  }

  .watchedNumber {
    position: absolute;
    color: white;
    font-weight: bold;
    right: 10px;
    bottom: 18px;
    font-size: 14px;
  }

  .detailContent {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //行數
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.all {
  width: 145px;
  height: 50px;
  border-radius: 25px 0 0 25px;
  background: rgba(235, 235, 235, .2);
  font-size: 18px;
  color: #EBEBEB;
  font-weight: bold;
}

.newest {
  width: 145px;
  height: 50px;
  border-radius: 0 25px 25px 0;
  color: #555555;
  font-weight: bold;
  background: #EBEBEB;
}

.eachMemberDiscuss {
  border-radius: 7px;

  .memberCircle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #555;
  }

  .memberName {
    color: #555555;
    font-size: 18px;
    font-weight: bold;
    margin-left: 8px;
  }

  .followRedBox {
    border: 2px solid #DD4B6E;
    border-radius: 17px;
    height: 34px;
    color: black;
    background: #FFCEDA;
    padding: 14px;
    margin-left: 16px;
  }
}

.date {
  font-size: 14px;
  color: #BAB9B9;
  font-weight: bold;
}

.contentDetail {
  color: #555555;
  font-weight: bold;
}

.countBox {
  width: 65px;
  height: 36px;
  border-radius: 18px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #DD4B6E;
  position: relative;

  .badge {
    position: absolute;
    bottom: -8px;
    right: -10px;
  }
}

.threeDotGrayBox {
  width: 45px;
  height: 45px;
  background: rgba(0, 0, 0, .1);
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  .memberDiscussAction {
    box-shadow: 0px 0px 6px #00000029;
    position: absolute;
    width: 250px;
    height: 55px;
    border-radius: 17px;
    color: #2277FF;
    font-size: 14px;
    background: white;
    top: 100%;
    right: 0;
  }
}

.eachMemberDiscuss:nth-child(odd) {
  background: #0000001A;
}

.showMore {
  background: rgba(257, 127, 258, .2);
  color: black;
  font-weight: bold;
  height: 60px;
  border-radius: 30px;
}

.grayBottomBorderBox {
  padding: 16px;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 0px 0px 28px 28px;

  .leaveMessageCircle {
    width: 60px;
    height: 60px;
    background: #555;
    border-radius: 50%;
  }

  .form-control {
    width: 80%;
    border-radius: 24px;
    height: 60px;
    background: rgba(0, 0, 0, .04);
  }

  .btn-send {
    height: 60px;
    border-radius: 30px;
    background: #dd4b6e;
    color: white;
    width: 100px;
  }
}
</style>
