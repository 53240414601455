/* eslint-disable */
import router from '../../router';

export const state = {
  isLoading: false,
  errMsg: 'ee',
  errCode: 0,
  loggedIn: false,
};

// export const actions = {
//   openModal({ state }, { id }) {
//     window.console.log(id);
//     $(id).modal('show');
//   },
//   closeModal({ state }, { id }) {
//     $(id).modal('hide');
//   },
//   resetErrMsg: ({ commit }) => {
//     commit('clearErrorMsg');
//   },
// };

export const mutations = {
  loading(state, { isLoading }) {
    state.isLoading = isLoading;
  },
  ajaxAlertError(state, { error }) {
    if (error.response) {
      state.errCode = error.response.status;
      if (error.response.status === 401) {
        window.localStorage.clear();
        router.push('/login');
        return;
      }
      if (Object.prototype.hasOwnProperty.call(error.response.data, 'error_msg')) {
        // window.alert(error.response.data.error_msg);
      }
    }
  },
  ajaxError(state, { error }) {
    state.errMsg = '';
    state.errCode = 0;
    if (error.response) {
      state.errCode = error.response.status;
      if (error.response.status === 401) {
        window.location.clear();
        window.location.reload();
        return;
      }
      if (Object.prototype.hasOwnProperty.call(error.response.data, 'error_msg')) {
        state.errMsg = error.response.data.error_msg;
      } else {
        state.errMsg = error.toString();
      }
    } else if (error.request) {
      state.errMsg = error.request;
    } else {
      state.errMsg = error.message;
    }
  },
  clearErrorMsg(state) {
    state.errMsg = '';
    state.errCode = 0;
  },
};
