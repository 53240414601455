import ajax from 'axios';

export const init = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/notification/init`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 取得未讀訊息數量
export const unread = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/notification/unread`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});
