import ajax from 'axios';

export const init = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/addNewClubPage/init`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const create = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/addNewClubPage/create`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const getGroups = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/groups`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const groupSwitch = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/group_switch`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const searchCards = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/addNewClubPage/search`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});
