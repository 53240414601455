import { getField, updateField } from 'vuex-map-fields';
import api from '../../api';
import helper from './common/common';

export const state = {
  ...helper.state,
};

export const mutations = {
  updateField,
  ...helper.mutations,
};

export const getters = { getField };

export const actions = {
  init: ({ commit }) => {
    if (!localStorage.getItem('t')) return;
    api.notification.init().then((rep) => {
      commit('setList', { list: rep.data.body.list });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  ...helper.actions,
};
