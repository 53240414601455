import cameCase from 'lodash/camelCase';

const requireModule = require.context('.', false, /\.js$/);
const modules = {};
requireModule.keys().forEach((fileName) => {
  if (fileName === './index.js') return;
  fileName.replace(/(\.\/|\.js)/g, '');
  modules[cameCase(fileName.slice(0, -2))] = {
    ...requireModule(fileName),
  };
});

export default modules;
