import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import $ from 'jquery';
import api from '../../api';
import helper from './common/common';
import router from '../../router';

export const state = {
  keywords: [],
  cards: [],
  cardValue: 0,
  category: [],
  categoryValues: [],
  findcompany_group_id: 0,
  findcompany_group_city: '請選擇',
  findcompany_group_district: '請選擇',
  findcompany_group_cover: false,
  findcompany_group_head: false,
  findcompany_group_name: '',
  findcompany_group_about: '',
  findcompany_group_line: '',
  findcompany_group_ig: '',
  findcompany_group_fb: '',
  findcompany_group_youtube: '',
  findcompany_group_twitter: '',
  findcompany_group_member_publish: false,
  members: [],
  membersAuth: [],
  groups: [],
  groupCount: 0,
  groupPage: 1,
  searchGroupsMembers: [],
  ...helper.state,
};

export const mutations = {
  setCategory: (state, { item }) => {
    state.categoryValues = [...state.categoryValues, item];
  },
  ...helper.mutations,
  updateField,
};

export const getters = { getField };

export const actions = {
  init: ({ commit }) => {
    api.addNewClubPage.init({ params: router.currentRoute.params }).then((rep) => {
      const addCategory = {};
      addCategory['新網美網帥大家PO'] = rep.data.body.category['新網美網帥大家PO'];
      commit('setValue', { key: 'cards', val: rep.data.body.cards });
      commit('setValue', { key: 'category', val: addCategory });
      commit('setValue', { key: 'categoryValues', val: rep.data.body.categoryValues });
      commit('setValue', { key: 'keywords', val: rep.data.body.keywords });
      commit('setValue', { key: 'searchGroupsMembers', val: rep.data.body.members });
      if (rep.data.body.group) {
        commit('setValue', {
          key: 'findcompany_group_id',
          val: rep.data.body.group.findcompany_group_id,
        });
        commit('setValue', {
          key: 'findcompany_group_city',
          val: rep.data.body.group.findcompany_group_city,
        });
        commit('setValue', {
          key: 'findcompany_group_district',
          val: rep.data.body.group.findcompany_group_district,
        });
        commit('setValue', {
          key: 'findcompany_group_cover',
          val: rep.data.body.group.findcompany_group_cover,
        });
        commit('setValue', {
          key: 'findcompany_group_head',
          val: rep.data.body.group.findcompany_group_head,
        });
        commit('setValue', {
          key: 'findcompany_group_name',
          val: rep.data.body.group.findcompany_group_name,
        });
        commit('setValue', {
          key: 'findcompany_group_about',
          val: rep.data.body.group.findcompany_group_about,
        });
        commit('setValue', {
          key: 'findcompany_group_line',
          val: rep.data.body.group.findcompany_group_line,
        });
        commit('setValue', {
          key: 'findcompany_group_ig',
          val: rep.data.body.group.findcompany_group_ig,
        });
        commit('setValue', {
          key: 'findcompany_group_fb',
          val: rep.data.body.group.findcompany_group_fb,
        });
        commit('setValue', {
          key: 'findcompany_group_youtube',
          val: rep.data.body.group.findcompany_group_youtube,
        });
        commit('setValue', {
          key: 'findcompany_group_twitter',
          val: rep.data.body.group.findcompany_group_twitter,
        });
        commit('setValue', {
          key: 'findcompany_group_member_publish',
          val: rep.data.body.group.findcompany_group_member_publish,
        });
      } else {
        commit('setValue', { key: 'findcompany_group_id', val: 0 });
        commit('setValue', { key: 'findcompany_group_city', val: '請選擇' });
        commit('setValue', { key: 'findcompany_group_district', val: '請選擇' });
        commit('setValue', { key: 'findcompany_group_cover', val: false });
        commit('setValue', { key: 'findcompany_group_head', val: false });
        commit('setValue', { key: 'findcompany_group_name', val: '' });
        commit('setValue', { key: 'findcompany_group_about', val: '' });
        commit('setValue', { key: 'findcompany_group_line', val: '' });
        commit('setValue', { key: 'findcompany_group_ig', val: '' });
        commit('setValue', { key: 'findcompany_group_fb', val: '' });
        commit('setValue', { key: 'findcompany_group_youtube', val: '' });
        commit('setValue', { key: 'findcompany_group_twitter', val: '' });
        commit('setValue', { key: 'findcompany_group_member_publish', val: false });
      }
    }).catch(() => {
      localStorage.removeItem('t');
      localStorage.removeItem('u');
      router.push('/');
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  searchCards: ({ commit }) => {
    api.addNewClubPage.searchCards({ searchStr: state.searchStr }).then((rep) => {
      commit('setValue', { key: 'searchGroupsMembers', val: rep.data.body.members });
      $('#inviteModal').modal('show');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  groupSwitch: ({ commit }, { group }) => {
    api.addNewClubPage.groupSwitch({ group, page: state.groupPage }).then((rep) => {
      commit('setValue', { key: 'groups', val: rep.data.body.groups });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  initGroups: ({ commit, state }) => {
    api.addNewClubPage.getGroups({ page: state.groupPage }).then((rep) => {
      commit('setValue', { key: 'groups', val: rep.data.body.groups });
      commit('setValue', { key: 'groupCount', val: rep.data.body.total });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  create: ({ commit, state }) => {
    api.addNewClubPage.create({
      keywords: state.keywords,
      categoryValues: state.categoryValues,
      cardValue: state.cards[state.cardValue],
      findcompany_group_id: state.findcompany_group_id,
      findcompany_group_city: state.findcompany_group_city,
      findcompany_group_district: state.findcompany_group_district,
      findcompany_group_cover: state.findcompany_group_cover,
      findcompany_group_head: state.findcompany_group_head,
      findcompany_group_name: state.findcompany_group_name,
      findcompany_group_about: state.findcompany_group_about,
      findcompany_group_line: state.findcompany_group_line,
      findcompany_group_ig: state.findcompany_group_ig,
      findcompany_group_fb: state.findcompany_group_fb,
      findcompany_group_youtube: state.findcompany_group_youtube,
      findcompany_group_twitter: state.findcompany_group_twitter,
      findcompany_group_member_publish: state.findcompany_group_member_publish,
      members: state.searchGroupsMembers,
    }).then(() => {
      router.push('/');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  ...helper.actions,
};
