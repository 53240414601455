<template lang="pug">
  div(v-html="code")
</template>

<script>

export default {
  props: {
    code: {
      type: String,
    },
  },
  methods: {
    injectScript() {
      const s = document.createElement('script');
      s.async = true;
      s.defer = true;
      s.src = 'https://platform.twitter.com/widgets.js';
      s.id = 'vue-twitter-embed-script';
      document.body.appendChild(s);
      // const { body } = document.body;
      if (document.body) {
        document.body.appendChild(s);
      }
    },
    checkAPI() {
      return new Promise((resolve) => {
        (function checkAPI(selff) {
          const self = selff;
          self.timer = window.setTimeout(() => {
            if (window.twttr) {
              clearTimeout(self.timer);
              resolve();
            } else {
              checkAPI(self);
            }
          }, 20);
        }(this));
      });
    },
  },
  mounted() {
    if (window.twttr) {
      window.twttr.widgets.load();
    } else {
      this.injectScript();
      this.checkAPI().then(() => {
        window.twttr.widgets.load();
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
