import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import api from '../../api';
import helper from './common/common';
import router from '../../router';

export const state = {
  data: null,
  followed: null,
  posts: null,
  empty: false,
  page: 1,
  order: 'desc',
  followers: [],
  visitors: [],
};

export const mutations = {
  updateField,
  ...helper.mutations,
};

export const getters = { getField };

export const actions = {
  init: ({ commit }) => {
    api.group.init({ params: router.currentRoute.params, page: state.page, order: state.order }).then((rep) => {
      commit('setValue', { key: 'data', val: rep.data.body.group });
      commit('setValue', { key: 'followed', val: rep.data.body.followed });
      commit('setValue', { key: 'posts', val: rep.data.body.posts });
      commit('setValue', { key: 'followers', val: rep.data.body.followers });
      commit('setValue', { key: 'visitors', val: rep.data.body.visitors });
      if (rep.data.body.posts.length < 7) {
        commit('setValue', { key: 'empty', val: true });
      }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  follow: ({ commit, rootState, state }) => {
    if (!rootState.account.user) {
      router.push('/');
    }
    api.group.follow({ groupId: router.currentRoute.params.id, flag: !state.followed }).then((rep) => {
      commit('setValue', { key: 'followed', val: rep.data.body.followed });
      window.location.reload();
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  loadMore: ({ state, commit }) => {
    window.console.log('loadMore');
    commit('setValue', { key: 'page', val: state.page + 1 });
    api.group.init({ params: router.currentRoute.params, page: state.page, order: state.order }).then((rep) => {
      commit('setValue', { key: 'posts', val: [...state.posts, ...rep.data.body.posts] });
      if (rep.data.body.posts.length < 7) {
        commit('setValue', { key: 'empty', val: true });
      }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
};
