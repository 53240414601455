import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import api from '../../api';
import helper from './common/common';
import param from './common/params';

export const state = {
  searchStr: '',
  page: 1,
  selectedCategory: new Map(),
  moreFilter: '',
  list: [],
  category: [],
  empty: false,
};

export const mutations = {
  select: (state, { item }) => {
    const key = [item.l1, item.l2, item.l3, item.l4, item.l5].join('');
    if (state.selectedCategory.has(key)) {
      const map = new Map(state.selectedCategory);
      map.delete(key);
      state.selectedCategory = map;
    } else {
      const map = new Map(state.selectedCategory);
      const params = { ...param.categoryOption };
      params.l1 = item.l1;
      params.l2 = item.l2;
      params.l3 = item.l3;
      params.l4 = item.l4;
      params.l5 = item.l5;
      map.set(key, params);
      state.selectedCategory = map;
    }
  },
  updateField,
  ...helper.mutations,
};

export const getters = { getField };

export const actions = {
  init: ({ state, commit }) => {
    api.outhot.init({
      order: state.order,
      page: state.page,
      searchStr: state.searchStr,
      selectedCategory: Array.from(state.selectedCategory.entries()),
      // eslint-disable-next-line no-bitwise
      filterIds: state.list.map((x) => x.id >> 0),
    }).then((rep) => {
      commit('setList', { list: rep.data.body.p });
      commit('setValue', { key: 'category', val: rep.data.body.category });
      commit('setValue', { key: 'selectedCategory', val: new Map(JSON.parse(rep.data.body.outHot)) });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  loadMore: ({ state, commit, dispatch }) => {
    commit('setValue', { key: 'page', val: state.page + 1 });
    dispatch('init');
  },
  saveSelectedCategory: ({ state, commit }) => {
    commit('setValue', { key: 'moreFilter', val: '' });
    commit('resetList');
    api.outhot.saveOutHot({
      order: state.order,
      page: state.page,
      searchStr: state.searchStr,
      selectedCategory: Array.from(state.selectedCategory.entries()),
      // eslint-disable-next-line no-bitwise
      filterIds: state.list.map((x) => x.id >> 0),
    }).then((rep) => {
      commit('setValue', { key: 'list', val: rep.data.body.p });
      commit('setValue', { key: 'category', val: rep.data.body.category });
      if (rep.data.body.p.length === 0) {
        commit('setValue', { key: 'empty', val: true });
      }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
};
