import { getField, updateField } from 'vuex-map-fields';
import router from '@/router';
import api from '../../api';
import helper from './common/common';

const { Configuration, OpenAIApi } = require('openai');

export const state = {
  category: [],
  tagstable: [], // Tag表格(Server傳來之內容)
  tagskeys: [],
  UserRecommend: [],
  sharePostContent: [], // 分享文章內容
  showTagPreviewContent: false, // 顯示Tag預覽Bar
  showTagPreviewIndex: -1, // 顯示Tag預覽的當下軸
  showTagPreviewMode: 1, // 顯示Tag預覽的模式(1:長型 2:寬版)
  showTagPreviewSortKind: 4, // 排序方式 0:時間 1:人氣(點讚數+留言) 2:點讚 3:留言 4:亂數
  showFliterTagPop: [], // 是否顯示選擇TagUI
  strFavoriteTags: '', // 最愛Tags清單
  showOwnerPostFull: false, // 是否顯示主Po文介面
  unreadCount: 0, // 未讀通知數量
  showTagPreviewPage: 1, // 顯示Tag預覽頁數(更換預覽Tag項目則重設為1)
  lastTagID: 0, // 最後點選的Tag編號(選擇Tag用)
  isTag: false,
  responseToken: '',
  responseImageUrl: '',
  ...helper.state,
};

export const mutations = { updateField, ...helper.mutations };

export const getters = { getField, ...helper.getters };

export const actions = {
  updateList: ({ state, commit, dispatch }, data) => {
    commit('setValue', { key: 'list', val: data });
  },
  redirectPost: ({ state }, { data }) => {
    router.push(helper.getRedirectPost(data));
  },
  redirectGroup: ({ state }, { data }) => {
    router.push(`/group/${data.id}`);
    window.location.reload();
  },
  loadPosts: ({ state, commit, dispatch }) => {
    commit('setValue', { key: 'page', val: state.page + 1 });
    // dispatch('init');
  },
  SetLanguage: ({ state, commit }, data) => {
    commit('setValue', { key: 'language', val: data });
  },
  SetShowFliterTagPop: ({ state, commit }, { idx, value }) => {
    if (state.showFliterTagPop.length <= idx) {
      state.showFliterTagPop[idx] = value;
      state.showFliterTagPop.splice(idx, 1, value);
    } else {
      state.showFliterTagPop.splice(idx, 1, value);
    }
  },
  SwitchFliterTagPop: ({ state, commit }, idx) => {
    if (state.showFliterTagPop.length <= idx) {
      state.showFliterTagPop[idx] = true;
      state.showFliterTagPop.splice(idx, 1, true);
    } else {
      state.showFliterTagPop.splice(idx, 1, !state.showFliterTagPop[idx]);
    }
  },
  setSelectTagIndex: ({ state, commit }, data) => {
    commit('setValue', { key: 'selectedTagIndex', val: data });
  },
  loadTagPosts: ({ state, commit, dispatch }, Index) => {
    commit('setValue', { key: 'selectedTagIndex', val: Index });
    if (Index === 0) { // 個人動態軸
      if (localStorage.getItem('u')) {
        commit('addTagListPage', { val: 1, idx: Index });
        // console.log('loadTagPosts idx: 0');
        dispatch('timeline_list', { selectTags: [], condition: 2, ListIndex: Index });
      }
    // } else if (Index === 1) { // 官方預設軸
      // commit('addTagListPage', { val: 1, idx: Index });
      // 椅斯拉智能, 公告
      // dispatch('timeline_list', { selectTags: [[774], [1086]], condition: 0, ListIndex: Index });
    } else {
      // console.log(`loadTagPosts idx:${Index}`);
      commit('addTagListPage', { val: 1, idx: Index });
      const tags = state.taglist.tags[Index];
      const cond = state.taglist.condition[Index];
      dispatch('timeline_list', { selectTags: tags, condition: cond, ListIndex: Index });
    }
  },
  reloadTagList: async ({ state, commit, dispatch }, Index) => {
    commit('setValue', { key: 'selectedTagIndex', val: Index });
    if (Index === 0) { // 個人動態軸
      if (localStorage.getItem('u')) {
        // console.log('reloadTagList idx: 0');
        commit('setTagListPage', { val: 1, idx: Index });
        commit('cleanTagList', { val: Index });
        await dispatch('timeline_list', { selectTags: [], condition: 2, ListIndex: Index });
      }
    // } else if (Index === 1) { // 官方預設軸
      // commit('setTagListPage', { val: 1, idx: Index });
      // commit('cleanTagList', { val: Index });
      // 椅斯拉智能, 公告
      // dispatch('timeline_list', { selectTags: [[774], [1086]], condition: 0, ListIndex: Index });
    } else {
      // console.log(`reloadTagList idx:${Index}`);
      commit('setTagListPage', { val: 1, idx: Index });
      commit('cleanTagList', { val: Index });
      const tags = state.taglist.tags[Index];
      const cond = state.taglist.condition[Index];
      await dispatch('timeline_list', { selectTags: tags, condition: cond, ListIndex: Index });
    }
    // 將所有文章內文顯示Scroll都置底
    // setTimeout(() => {
    //   const objs = document.getElementsByClassName('ArticleContent');
    //   if (objs && objs.length > 0) {
    //     for (let i = 0; i < objs.length; i += 1) {
    //       objs[i].scrollTop = objs[i].scrollHeight; // eslint-disable-line
    //     }
    //   }
    // }, 500);
  },
  report: ({
    state, commit, dispatch, rootState,
  }, { cardId, content }) => {
    if (!rootState.account.user) {
      router.push('/');
    }
    // eslint-disable-next-line no-alert
    const msg = window.prompt('請填寫檢舉理由');
    if (!msg) return;
    api.dashboard.report({
      cardId, msg, url: window.location.href, content,
    }).then(() => {
      // eslint-disable-next-line no-alert
      window.alert('檢舉完成');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
    // window.console.log(window.location.href);
  },
  searchCategory: ({ commit, dispatch, state }, { category }) => {
    // eslint-disable-next-line
    if (!$('.dropdown-menu').hasClass('show') || category.name !== state.currentCate) {
      const {
        searchStr, searchCity, searchDistrict,
      } = state;
      commit('reset');
      commit('setValue', { key: 'currentCate', val: category.name });
      commit('setValue', { key: 'searchCity', val: searchCity });
      commit('setValue', { key: 'searchDistrict', val: searchDistrict });
      commit('setValue', { key: 'searchStr', val: searchStr });
      commit('setValue', { key: 'selectedCategory', val: category });
      // dispatch('init');
    }
  },
  init: ({ commit, state }) => {
    // tmp marked 20210601
    // let selectedCategory = {};
    // if (router.currentRoute.name === 'category' && !state.selectedCategory) {
    //   selectedCategory.l1 = router.currentRoute.params.category1;
    //   selectedCategory.l2 = router.currentRoute.params.category2;
    //   selectedCategory.l3 = router.currentRoute.params.category3;
    //   selectedCategory.l4 = router.currentRoute.params.category4;
    //   selectedCategory.l5 = router.currentRoute.params.category5;
    // } else if (state.selectedCategory) {
    //   selectedCategory = state.selectedCategory ? state.selectedCategory : null;
    // }
    // api.dashboard.init({
    //   order: state.order,
    //   searchStr: state.searchStr,
    //   category: selectedCategory,
    //   page: state.page,
    //   searchCity: state.searchCity,
    //   searchDistrict: state.searchDistrict,
    // // eslint-disable-next-line no-bitwise
    //   filterIds: state.list.map((x) => x.id >> 0),
    // }).then((rep) => {
    //   commit('setValue', { key: 'category', val: rep.data.body.category });
    //   commit('setList', { list: rep.data.body.posts });
    //   commit('setValue', { key: 'unreadCount', val: rep.data.body.unreadCount });
    // }).catch((error) => {
    //   commit('ui/ajaxAlertError', { error }, { root: true });
    // });
  },
  // 要求文章列表
  // selectTags: 選擇的Tag組合 condition: 搜尋條件 ListIndex: 文章放置的軸Idx (-1:預覽軸 0:我的動態 1:官方推薦軸 2~n:使用者訂閱軸),
  /* eslint-disable */
  timeline_list: async ({ commit, dispatch, state }, { selectTags, condition, ListIndex, defaultName }) => {
  /* eslint-enable */
    let TagPage = 1;
    if (ListIndex >= 0) { // 除了預覽軸外
      TagPage = state.taglist.page[ListIndex] ? state.taglist.page[ListIndex] : 1;
    } else { // 預覽軸頁數讀取
      TagPage = state.showTagPreviewPage ? state.showTagPreviewPage : 1;
      // console.log(TagPage);
    }
    const singlelist = [];
    singlelist.push(condition); // 0:AND, 1:OR 2:個人動態牆 3:系統預設動態牆
    singlelist.push(TagPage); // 頁數
    singlelist.push(1); // 排序 1:時間 2:人氣 3:點贊 4:留言
    singlelist.push(0); // 0: desc 1: asc
    const tags = [];
    selectTags.forEach((tag) => {
      tags.push(tag[0]);
    });
    singlelist.push(tags);
    const postData = [];
    postData.push(singlelist);
    if (ListIndex >= 1) {
      commit('setTagList_Tags', { val: selectTags, idx: ListIndex });
      commit('setTagList_Condition', { val: condition, idx: ListIndex });
    }
    // console.log(`timeline_list data:${JSON.stringify(postData)}`);
    await api.dashboard.timelinelist({ language: state.language, data: postData }).then(async (rep) => {
      // 這裡取第一個陣列值當資訊 (其實可以同時傳來多軸列表清單)
      // console.log(`timeline_list rep data:${JSON.stringify(rep.data.body[0])}`);
      commit('setTagList', { list: rep.data.body[0], idx: ListIndex });
      // console.log(`list:${JSON.stringify(rep.data.body[0])}`);
      if (state.taglist.name[ListIndex].length === 0) { // 未命名則拿第一個Tag名稱用
        if (!defaultName) {
          commit('setTagList_Name', { val: selectTags[0][1], idx: ListIndex });
        } else {
          commit('setTagList_Name', { val: defaultName, idx: ListIndex });
        }
      }
      if (TagPage === 1) { // 非第一頁不儲存
        await dispatch('timeline_save');
      }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });

    // 將所有文章內文顯示Scroll都置底
    // setTimeout(() => {
    //   const objs = document.getElementsByClassName('ArticleContent');
    //   if (objs && objs.length > 0) {
    //     for (let i = 0; i < objs.length; i += 1) {
    //       objs[i].scrollTop = objs[i].scrollHeight; // eslint-disable-line
    //     }
    //   }
    // }, 500);
  },
  // 要求文章列表(複數軸)
  /* eslint-disable */
  timeline_multi_list: ({ commit, dispatch, state }, { timelineSets }) => {
  /* eslint-enable */
    const TagPage = 1;
    const postData = [];

    const cIdx = [];
    const cName = [];

    // timelineSets
    // { selectTags, condition, ListIndex, defaultName }
    timelineSets.forEach((timeline) => {
      const singlelist = [];
      singlelist.push(timeline.condition); // 0:AND, 1:OR 2:個人動態牆 3:系統預設動態牆
      singlelist.push(TagPage); // 頁數
      singlelist.push(1); // 排序 1:時間 2:人氣 3:點贊 4:留言
      singlelist.push(0); // 0: desc 1: asc
      const tags = [];
      timeline.selectTags.forEach((tag) => {
        tags.push(tag[0]);
      });
      singlelist.push(tags);

      if (timeline.ListIndex > 1) {
        commit('setTagList_Tags', { val: timeline.selectTags, idx: timeline.ListIndex });
        commit('setTagList_Condition', { val: timeline.condition, idx: timeline.ListIndex });
      }

      cIdx.push(timeline.ListIndex);
      cName.push(timeline.defaultName);

      postData.push(singlelist);
    });

    // console.log(`timeline_multi_list postData:${JSON.stringify(postData)}`);

    api.dashboard.timelinelist({ language: state.language, data: postData }).then((rep) => {
      rep.data.body.forEach((item, index) => {
        commit('setTagList', { list: item, idx: cIdx[index] });
        commit('setTagList_Name', { val: cName[index], idx: cIdx[index] });
      });
      // dispatch('timeline_save');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 取得 Tag同好 清單(常用TAG與自己相同率越高者，越前面)
  load_UserRecommend: ({ commit, state }, tagData) => {
    api.dashboard.loadUserRecommend({ language: state.language, data: tagData }).then((rep) => {
      // console.log(`UserRecommend:${JSON.stringify(rep.data.body)}`);
      commit('setValue', { key: 'UserRecommend', val: rep.data.body });
    }).catch((error) => {
      console.log(error);
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 取得 Tags 所有可選擇清單
  load_tagstable: async ({ commit, state }) => {
    await api.dashboard.loadTagsTable(state.language).then((rep) => {
      // console.log(`tagstable:${JSON.stringify(rep.data.body)}`);
      commit('setValue', { key: 'tagstable', val: rep.data.body });
      // console.log(`tagskeys:${JSON.stringify(Object.keys(rep.data.body).sort())}`);
      commit('setValue', { key: 'tagskeys', val: Object.keys(rep.data.body).sort() });
    }).catch((error) => {
      console.log(error);
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 儲存時間軸
  timeline_save: ({ commit, state }) => {
    const postData = [];
    // 從2開始 0:個人動態軸 1:官方預設軸
    for (let i = 2; i < state.taglist.list.length; i += 1) {
      if (state.taglist.list[i]) {
        const singlelist = [];
        singlelist.push(state.taglist.condition[i]); // 0:AND, 1:OR 2:個人動態牆 3:系統預設動態牆
        singlelist.push(1); // 頁數
        singlelist.push(1); // 排序 1:時間 2:人氣 3:點贊 4:留言
        singlelist.push(0); // 0: desc 1: asc
        const tags = [];
        state.taglist.tags[i].forEach((tag) => {
          tags.push(tag[0]);
        });
        singlelist.push(tags);
        singlelist.push(state.taglist.name[i]);
        singlelist.push(state.taglist.tags[i]); // 加存一份完整Tag訂閱表(初始化顯示用)
        postData.push(singlelist);
      }
    }
    const Language = 0; // 0:中文 1:英文 2:日文
    if (localStorage.getItem('u')) {
      // console.log(`taglist_save:${JSON.stringify(postData)}`);
      api.dashboard.timelineSave({ language: Language, data: postData }).then((rep) => {
        //
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // 讀取時間軸
  timeline_load: async ({ commit, dispatch, state }) => {
    const language = 0; // 0:中文
    if (localStorage.getItem('u')) {
      // console.log('taglist_load');
      await api.dashboard.timelineLoad([]).then((rep) => {
        dispatch('timeline_init', { tagData: rep.data.body }); // 初始化
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
    return false;
  },
  // 初始化訂閱Tag時間軸
  timeline_init: async ({ commit, dispatch, state }, { tagData }) => {
    // console.log(`timeline_init:${JSON.stringify(tagData)}`);
    const Language = state.language; // 0:中文 1:英文 2:日文
    await api.dashboard.timelinelist({ language: Language, data: tagData }).then((rep) => {
      for (let i = 0; i < rep.data.body.length; i += 1) {
        dispatch('setSelectTagIndex', i + 2);
        commit('cleanTagList', { val: i + 2 });
        commit('setTagList', { list: rep.data.body[i], idx: i + 2 });
        commit('setTagList_Name', { val: tagData[i][5], idx: i + 2 });
        commit('setTagList_Condition', { val: tagData[i][0], idx: i + 2 });
        commit('setTagList_Tags', { val: tagData[i][6], idx: i + 2 });
      }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 官方預設Tag軸讀取
  timeline_default: ({ commit, dispatch, state }) => {
    const singlelist = [];
    // singlelist.push(3); // 0:AND, 1:OR 2:個人動態牆 3:系統預設動態牆 20210713 更改
    singlelist.push(0); // 0:AND, 1:OR 2:個人動態牆 3:系統預設動態牆
    singlelist.push(1); // 頁數
    singlelist.push(1); // 排序 1:時間 2:人氣 3:點贊 4:留言
    singlelist.push(0); // 0: desc 1: asc
    // singlelist.push([]); // 20210713 更改
    singlelist.push([774, 1086]); // 椅斯拉智能, 公告
    const postData = [];
    postData.push(singlelist);

    const Language = state.language; // 0:中文 1:英文 2:日文
    api.dashboard.timelinelist({ language: Language, data: postData }).then((rep) => {
      dispatch('setSelectTagIndex', 1);
      commit('setTagList', { list: rep.data.body[0], idx: 1 }); // 這裡取第一個陣列值當資訊 (其實可以同時傳來多軸列表清單)
      // commit('setTagList_Name', { val: '推薦主題', idx: 1 });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  getPost_Content: ({ commit, dispatch, state }, postid) => {
    commit('setValue', { key: 'sharePostContent', val: [] });
    return api.dashboard.getPostContent(postid).then((rep) => {
      commit('setValue', { key: 'sharePostContent', val: rep.data.body });
      // console.log(`Getpost:${JSON.stringify(rep.data.body)}`);
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 取得未讀通知數量
  get_notification_unread: async ({ commit, dispatch, state }) => {
    if (localStorage.getItem('u')) {
      await api.notification.unread([]).then((rep) => {
        commit('setValue', { key: 'unreadCount', val: rep.data.body.count });
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // 追蹤單篇貼文
  followPost: ({ commit, dispatch, state }, postid) => {
    api.dashboard.followPost(postid).then((rep) => {
      // commit('setValue', { key: 'sharePostContent', val: rep.data.body });
      // console.log(`Getpost:${JSON.stringify(rep.data.body)}`);
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 取消追蹤單篇貼文
  unfollowPost: ({ commit, dispatch, state }, postid) => {
    api.dashboard.unfollowPost(postid).then((rep) => {
      // commit('setValue', { key: 'sharePostContent', val: rep.data.body });
      // console.log(`Getpost:${JSON.stringify(rep.data.body)}`);
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // AI詢問
  AICompletion: async ({ commit, dispatch, state }, { prompt }) => {
    // const maxtoken = 1600;
    const maxtoken = 4000;
    const Msgs = [];
    Msgs.push({ role: 'user', content: `${prompt}` });
    const data = {
      model: 'gpt-3.5-turbo',
      messages: Msgs,
      temperature: 0.75,
      max_tokens: maxtoken,
      top_p: 1.0,
      frequency_penalty: 0.2,
      presence_penalty: 0.0,
    };
    await api.dashboard.aiCompletion(data).then((rep) => {
      commit('setValue', { key: 'responseToken', val: JSON.parse(rep.data.body).choices[0].message.content });
    }).catch((error) => {
      commit('setValue', { key: 'responseToken', val: `AILimitOver：${error.response.data.error_msg}` });
    });
  },
  AIImageGenerations: async ({ commit, dispatch, state }, { prompt }) => {
    const configuration = new Configuration({
      apiKey: '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',
    });
    const openai = new OpenAIApi(configuration);
    const response = await openai.createImage({
      prompt: `${prompt}`,
      n: 1,
      size: '512x512',
    });

    commit('setValue', { key: 'responseImageUrl', val: response.data.data[0].url });
  },
  ...helper.actions,
};
