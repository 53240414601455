import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import api from '../../api';
import helper from './common/common';

export const state = {
  myFriends: [],
  joinMe: [],
  visitor: [],
  tag: '追蹤名單',
};

export const mutations = {
  updateField,
  ...helper.mutations,
};

export const getters = { getField };

export const actions = {
  init: ({ commit }) => {
    if (localStorage.getItem('u')) {
      api.friends.init().then((rep) => {
        commit('setValue', { key: 'myFriends', val: rep.data.body.myFriends });
        commit('setValue', { key: 'joinMe', val: rep.data.body.joinMe });
        commit('setValue', { key: 'visitor', val: rep.data.body.visitor });
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      }).finally(() => {
        commit('resetClickOnce');
      });
    }
  },
};
