<template lang="pug">
  .mainContent.d-flex
    .imgArea.w-40
      img(:src="post.images[0]")
    div.w-60
      div.insideWhiteBox.h-100.p-2
        .amyId.d-flex.align-items-center
          .blackCircle(:style="`background-image: url('${post.userHead}');background-size: cover;`")
          p.mb-0.text-log {{post.userName}}
          p.mb-0.text-from.ml-5 -來自
            .blueBox.mx-3(:style="`background-image: url('${post.groupHead}');background-size: cover;`")
            .text-decoration-none.text-log.mb-0(@click="redirectPost({data:post})") {{post.groupName}}
        .description
          p.text-justify.mb-4.content(v-html="post.content")
          .d-flex.align-items-center
            .text-decoration-none.mb-0.text-left.seeMore(@click="redirectPost({data:post})") 看更多>
        //.watchedNum 瀏覽人數{{post.views}}人
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'BaseContent',
  props: ['post'],
  mounted() {
  },
  methods: {
    ...mapActions('dashboard', ['redirectPost']),
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: auto;
}
.seeMore {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}
.seeMore:hover {
  color: #007bff;
}
.mainContent {
  height: 250px;
  border: 2px solid #EBEBEB;
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  box-sizing: border-box;
  .w-40{width: 40%;}
  .w-60{width: 60%;}
  .w-30{width: 20%;}
  .w-70{width: 65%;}
  .imgArea{
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .imgArea>img{
    width: auto;
    height: 100%;
    text-align: center;
  }
  .insideWhiteBox{
    position: relative;
    text-align: left;
    width: 100%;
    .topTitle{
      font-weight: bold;
      font-size: 18px;
      overflow : hidden;
      text-overflow : ellipsis;
      white-space : nowrap;
    }
    .contentArea{
      width: calc(100% - 185px);
    }
    text-log,.text-from {
      margin: 0 5px;
      max-width: 35%;
      overflow : hidden;
      text-overflow : ellipsis;
      white-space : nowrap;
      display: inline-block;
      vertical-align: middle;
      font-weight: normal;
      font-size: 14px;
    }
    p.content,p.title{
      overflow : hidden;
      text-overflow : ellipsis;
      white-space : nowrap;
    }
    p.content{
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
}
</style>
