import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import Vue from 'vue';
import api from '../../api';
import helper from './common/common';
import router from '../../router';

export const state = {
  messages: [],
  page: 1,
  order: 'desc',
  empty: false,
  content: '',
  mute: false,
  rep_data: '',
};

export const mutations = {
  ...helper.mutations,
  updateField,
};

export const getters = { getField };

export const actions = {
  init: ({ commit, state, rootState }) => {
    api.messageBoard.init({
      params: router.currentRoute.params,
      page: state.page,
      order: state.order,
      cardId: rootState.account.card ? rootState.account.card.id : 0,
    }).then((rep) => {
      commit('setValue', { key: 'messages', val: rep.data.body.messages.rows });
      commit('setValue', { key: 'page', val: rep.data.body.messages.page });
      if (rep.data.body.messages.rows.length < 10) {
        commit('setValue', { key: 'empty', val: true });
      }
      commit('setValue', { key: 'mute', val: rep.data.body.mute });
    }).catch((error) => {
      console.log('test');
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  remove: ({ commit, state, rootState }, { removeId }) => {
    api.messageBoard.remove({
      params: router.currentRoute.params,
      page: state.page,
      order: state.order,
      cardId: rootState.account.card ? rootState.account.card.id : 0,
      removeId,
    }).then((rep) => {
      commit('setValue', { key: 'messages', val: rep.data.body.messages.rows });
      commit('setValue', { key: 'page', val: rep.data.body.messages.page });
      if (rep.data.body.messages.rows.length < 10) {
        commit('setValue', { key: 'empty', val: true });
      }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  create: ({ commit, state, rootState }, { params, url }) => {
    if (state.content.trim().length <= 0) {
      return;
    }
    api.messageBoard.create({
      params: params || router.currentRoute.params,
      page: state.page,
      order: state.order,
      cardId: rootState.account.card.id,
      content: state.content,
    }).then((rep) => {
      Vue.$gtag.event('文章數量', {
        event_category: '留言數',
        event_label: rootState.account.card.id,
        value: 1,
      });
      commit('setValue', { key: 'messages', val: rep.data.body.messages.rows });
      commit('setValue', { key: 'page', val: rep.data.body.messages.page });
      commit('setValue', { key: 'content', val: '' });
      if (url) {
        router.push(url);
      }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  good: ({
    commit, state, rootState, dispatch,
  }, { postId }) => {
    // if (!rootState.account.card) router.push('/');
    // 清除回傳結果
    commit('setValue', { key: 'rep_data', val: '' });
    return api.messageBoard.good({
      // params: router.currentRoute.params,
      params: { type: 'inHot' },
      page: state.page,
      order: state.order,
      cardId: rootState.account.card.id,
      postId,
    }).then((rep) => {
      Vue.$gtag.event('文章反應', {
        event_category: '+1',
        event_label: rootState.account.card.id,
        value: 1,
      });
      // rep.data.body {"good":0,"bad":1}
      // 記錄回傳結果
      commit('setValue', { key: 'rep_data', val: rep.data.body });
      // eslint-disable-next-line no-alert
      // window.alert('發送成功');
      // commit('setValue', { key: 'messages', val: rep.data.body.messages.rows });
      // commit('setValue', { key: 'page', val: rep.data.body.messages.page });
      // if (rep.data.body.messages.rows.length < 10) {
      //   commit('setValue', { key: 'empty', val: true });
      // }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  bad: ({
    commit, state, rootState, dispatch,
  }, { postId }) => {
    // if (!rootState.account.card) router.push('/');
    // 清除回傳結果
    commit('setValue', { key: 'rep_data', val: '' });
    return api.messageBoard.bad({
      // params: router.currentRoute.params,
      params: { type: 'inHot' },
      page: state.page,
      order: state.order,
      cardId: rootState.account.card.id,
      postId,
    }).then((rep) => {
      Vue.$gtag.event('文章反應', {
        event_category: '-1',
        event_label: rootState.account.card.id,
        value: 1,
      });
      // 記錄回傳結果
      commit('setValue', { key: 'rep_data', val: rep.data.body });
      // eslint-disable-next-line no-alert
      // window.alert('發送成功');
      // commit('setValue', { key: 'messages', val: rep.data.body.messages.rows });
      // commit('setValue', { key: 'page', val: rep.data.body.messages.page });
      // if (rep.data.body.messages.rows.length < 10) {
      //   commit('setValue', { key: 'empty', val: true });
      // }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
};
