import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import api from '../../api';
import helper from './common/common';

export const state = {
  ...helper.state,
};

export const mutations = {
  updateField,
  ...helper.mutations,
};

export const getters = { getField };

export const actions = {
  init: ({ state, commit }) => {
    api.tracked.init({
      order: state.order, searchStr: state.searchStr, page: state.page,
    }).then((rep) => {
      commit('setList', { list: rep.data.body.posts });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  loadMore: ({ state, commit, dispatch }) => {
    commit('setValue', { key: 'page', val: state.page + 1 });
    dispatch('init');
  },
  ...helper.actions,
};
