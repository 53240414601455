import { getField, updateField } from 'vuex-map-fields';
import api from '../../api';
import helper from './common/common';
import router from '../../router';

export const state = {
  keywords: [],
  cards: [],
  cardValue: 0,
  category: [],
  categoryValue1: null,
  categoryValue2: null,
  categoryValue3: null,
  categoryValue4: null,
  categoryValue5: null,
  L1: [],
  L2: [],
  L3: [],
  L4: [],
  L5: [],
  categoryValues: [],
  findcompany_group_city: '請選擇',
  findcompany_group_district: '請選擇',
  findcompany_group_id: 0,
  findcompany_group_cover: false,
  findcompany_group_head: false,
  findcompany_group_name: '',
  findcompany_group_about: '',
  findcompany_group_line: '',
  findcompany_group_ig: '',
  findcompany_group_fb: '',
  findcompany_group_youtube: '',
  findcompany_group_twitter: '',
  findcompany_group_member_publish: false,
  members: [],
  membersAuth: [],
  ...helper.state,
};

export const mutations = {
  updateField,
  setCategory: (state, { level, child, val }) => {
    state[`categoryValue${level.toString()}`] = val;
    state[`L${level.toString()}`] = child;
    if (Array.isArray(child) && child.length === 0) {
      state.categoryValues.push({
        label: val, category1: state.categoryValue1, category2: state.categoryValue2, category3: state.categoryValue3, category4: state.categoryValue4, category5: state.categoryValue5,
      });
      state.L1 = [];
      state.L2 = [];
      state.L3 = [];
      state.L4 = [];
      state.L5 = [];
      state.categoryValue1 = null;
      state.categoryValue2 = null;
      state.categoryValue3 = null;
      state.categoryValue4 = null;
      state.categoryValue5 = null;
    }
  },
  ...helper.mutations,
};

export const getters = { getField };

export const actions = {
  init: ({ commit }) => {
    api.addNewClubPage.init().then((rep) => {
      commit('setValue', { key: 'cards', val: rep.data.body.cards });
      commit('setValue', { key: 'category', val: rep.data.body.category });
    }).catch((error) => {
      localStorage.removeItem('t');
      localStorage.removeItem('u');
      router.push('/');
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  ...helper.actions,
};
