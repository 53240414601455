import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import Vue from 'vue';
import api from '../../api';
import helper from './common/common';
import router from '../../router';

export const state = {
  group: null,
  followers: [],
  visitors: [],
  blockers: [], // 封鎖群
  owner: false,
  reviewPost: false, // 是否開啟檢視文章細節
  reviewPostBody: [], // 檢視文章細節對象
  reviewPostComments: [], // 文章所有留言
  exclusiveTag_rep: false,
  reviewTopPostList: false, // 是否檢視置頂連結分享
};

export const mutations = {
  updateField,
  ...helper.mutations,
};

export const getters = { getField };

export const actions = {
  del: ({ commit }) => {
    api.post.del(router.currentRoute.params).then(() => {
      this.$router.push('/');
    }).catch(() => {
      this.$router.push('/');
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  init: ({ commit }) => {
    api.post.init(router.currentRoute.params).then((rep) => {
      commit('setValue', { key: 'group', val: rep.data.body.group });
      commit('setValue', { key: 'followers', val: rep.data.body.followers });
      commit('setValue', { key: 'visitors', val: rep.data.body.visitors });
      commit('setValue', { key: 'owner', val: rep.data.body.owner });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  good: ({
    commit, state, rootState, dispatch,
  }, { postId }) => {
    // if (!rootState.account.card) router.push('/');
    api.post.good({
      params: { type: 'group' }, cardId: rootState.account.card.id, postId,
    }).then(() => {
      Vue.$gtag.event('文章反應', {
        event_category: '+1',
        event_label: rootState.account.card.id,
        value: 1,
      });
      dispatch('init');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  bad: ({
    commit, state, rootState, dispatch,
  }, { postId }) => {
    // if (!rootState.account.card) router.push('/');
    api.post.bad({
      params: { type: 'group' }, cardId: rootState.account.card.id, postId,
    }).then(() => {
      Vue.$gtag.event('文章反應', {
        event_category: '-1',
        event_label: rootState.account.card.id,
        value: 1,
      });
      dispatch('init');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 取得文章相關資訊(留言)
  postload: ({ commit }, postItem) => {
    commit('setValue', { key: 'reviewPostBody', val: postItem });
    api.post.getComments(postItem[0]).then((rep) => {
      commit('setValue', { key: 'reviewPostComments', val: rep.data.body });
      commit('setValue', { key: 'reviewPost', val: true });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      // commit('resetClickOnce');
    });
  },
  post_getComments: ({ commit }, postID) => {
    api.post.getComments(postID).then((rep) => {
      commit('setValue', { key: 'reviewPostComments', val: rep.data.body });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      // commit('resetClickOnce');
    });
  },
  setReviewPost: ({ commit }, { IsShow }) => {
    commit('setValue', { key: 'reviewPost', val: IsShow });
  },
  setReviewPostBody: ({ commit }, postItem) => {
    commit('setValue', { key: 'reviewPostBody', val: postItem });
  },
  setReviewTopPostList: ({ commit }, { IsShow }) => {
    commit('setValue', { key: 'reviewTopPostList', val: IsShow });
  },
  comment: ({ commit }, { postID, message }) => {
    const data = { id: postID, content: message };
    // console.log(`comment:${JSON.stringify(data)}`);
    api.post.comment(data).then((rep) => {
      // 更新留言列表
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      console.log(`comment error:${JSON.stringify(error)}`);
    }).finally(() => {
      // commit('resetClickOnce');
    });
  },
  // 刪除文章/留言
  post_del: ({ commit }, { postID }) => {
    const data = { id: postID };
    // console.log(`post_del:${JSON.stringify(data)}`);
    api.post.postDel(data).then((rep) => {
      // 更新文章列表
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      console.log(`postDel error:${JSON.stringify(error)}`);
    }).finally(() => {
      // commit('resetClickOnce');
    });
  },
  // 刪除附件
  post_delFile: ({ commit }, { postID, strURL }) => {
    const data = { id: postID, url: strURL };
    console.log(`postDelFile:${JSON.stringify(data)}`);
    api.post.postDelFile(data).then((rep) => {
      // 更新文章列表
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      console.log(`postDelFile error:${JSON.stringify(error)}`);
    }).finally(() => {
      // commit('resetClickOnce');
    });
  },
  // 文章刪除標籤
  post_delTag: ({ commit }, { postID, tagID }) => {
    const data = { id: postID, tag_id: tagID };
    console.log(`postDelTag:${JSON.stringify(data)}`);
    api.post.postDelTag(data).then((rep) => {
      // 更新文章列表
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      console.log(`postDelTag error:${JSON.stringify(error)}`);
    }).finally(() => {
      // commit('resetClickOnce');
    });
  },
  // 封鎖發文者
  post_Block: ({ commit }, { postID }) => {
    const data = { id: postID };
    console.log(`postBlock:${JSON.stringify(data)}`);
    api.post.postBlock(data).then((rep) => {
      // 更新文章列表
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      console.log(`postBlock error:${JSON.stringify(error)}`);
    }).finally(() => {
      // commit('resetClickOnce');
    });
  },
  // 解鎖發文者
  post_UnBlock: ({ commit }, { postID }) => {
    const data = { id: postID };
    console.log(`postUnBlock:${JSON.stringify(data)}`);
    api.post.postUnBlock(data).then((rep) => {
      // 更新文章列表
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      console.log(`postUnBlock error:${JSON.stringify(error)}`);
    }).finally(() => {
      // commit('resetClickOnce');
    });
  },
  // 取得封鎖名單
  get_BlockList: ({ commit }) => {
    api.post.getBlockList([]).then((rep) => {
      commit('setValue', { key: 'blockers', val: rep.data.body });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      console.log(`postUnBlock error:${JSON.stringify(error)}`);
    }).finally(() => {
      // commit('resetClickOnce');
    });
  },
  // 取得該專屬Tag是否可使用
  AvailableTag: ({ commit, state }, tagData) => {
    api.post.availableTag(tagData).then((rep) => {
      // console.log(`AvailableTag Body:${JSON.stringify(rep.data.body)}`);
      // console.log(`AvailableTag Error:${rep.data.error}`);
      commit('setValue', { key: 'exclusiveTag_rep', val: rep.data.error });
    }).catch((error) => {
      // console.log(error);
      commit('setValue', { key: 'exclusiveTag_rep', val: true });
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 檢舉文章
  post_Report: ({ commit }, { postId }) => {
    const data = { post_id: postId };
    api.post.postReport(data).then((rep) => {
      window.alert('已送出檢舉!'); // eslint-disable-line no-alert
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      console.log(`post_Report error:${JSON.stringify(error)}`);
    }).finally(() => {
      // commit('resetClickOnce');
    });
  },
  // PO內文更新
  PostContentUpdate: ({ state, commit, dispatch }, { PostID, Content, Images, Tags, Secured, AD }) => { // eslint-disable-line
    const formData = new FormData();
    formData.append('id', PostID); // id
    formData.append('content', Content); // 標題
    formData.append('secured', Secured); // 屬性(0: 公開 1: 秘密PO文)
    formData.append('ad', AD); // 3:愛心廣告(顯示提示) 2:愛心廣告 1:有廣告(一般) 0:無
    for (let i = 0; i < Images.length; i += 1) {
      formData.append(`images[${i}]`, Images[i], Images[i].name);
    }
    let idx = 0;
    for (let i = 0; i < Tags.length; i += 1) {
      if (Tags[i][4] > 0) { // 非系統類別
        formData.append(`tag_name_array[${idx}]`, Tags[i][1]); // 標籤名稱 字串陣列 ["標籤1","標籤2"]. 後台會建立標籤
        // console.log(`tag_name_array[${idx}]:${Tags[i][1]}`);
        idx += 1;
      }
    }
    idx = 0;
    for (let i = 0; i < Tags.length; i += 1) {
      if (Tags[i][4] === 0) { // 系統
        formData.append(`system_tag_id_array[${idx}]`, Tags[i][0]); // 系統標籤流水號 int陣列 [1,2,3]. 系統標籤不存在會噴error
        // console.log(`system_tag_id_array[${idx}]:${Tags[i][1]},${Tags[i][0]}`);
        idx += 1;
      }
    }
    if (localStorage.getItem('u')) {
      api.post.postUpdate(formData).then((rep) => {
        // console.log('PostContentUpdate success!');
        window.alert('變更完成!'); // eslint-disable-line no-alert
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
        window.alert('變更失敗，請重新再試。'); // eslint-disable-line no-alert
      });
    }
  },
};
