<template lang="pug">
  div
    .d-flex.mb-3(style="justify-content: space-between")
      div.d-flex.align-items-center
        .nameCircle(:style="`background-image: url('${group.cardImg}');background-size: cover;`")
        .name.font-weight-bold.ml-1 {{group.name}}
      hideAt(breakpoint="large")
        div.mt-2
          .pinGrayBorder.d-flex.align-items-center(v-if="group.postType === 'inHot'").ml-5
            div.follow-btn
              img.pin.mx-auto(src="../assets/eachPost/pin.png")
              p.mb-0.font-weight-bold(@click="followGroup({groupId:group.id})") {{followGroupStr(group.id)}}
    hideAt(breakpoint="large")
      div.data-info.d-flex
        img(src='../assets/eachPost/bluePin.png')
        .font-weight-bold.mb-0.followWord(@click="whoFollow = !whoFollow" style="cursor:pointer") {{group.knockNum}}追蹤
          .whoFollow(v-if="whoFollow")
            .d-flex
              p.mb-5.text-left.mr-auto 這些人都追蹤了：
              .xBox.bg-dark.d-flex.justify-content-center.align-items-center(style="width:30px;height:30px; border-radius:50%; color:white; cursor:pointer;" @click.stop="whoFollow = !whoFollow") X
            .d-flex.flex-column.aligm-items-center
              .d-flex.align-items-center.mb-4(v-for="follower in followers")
                .redCircle.bg-primary(style="width:40px; height:40px; border-radius:50%;")
                p.mb-0.ml-3.mr-auto {{follower.name}}
                .btn-follow.d-flex.justify-content-center.align-items-center(@click="followUser({userId:follower.id})") {{followStr(follower.id)}}
        img(src='../assets/eachPost/eye.png').ml-4
        .font-weight-bold.mb-0.followWord(@click="whoSee = !whoSee" style="cursor:pointer") {{group.postViewNum}}觀看
          .whoFollow(v-if="whoSee")
            .d-flex
              p.mb-5.text-left.mr-auto 這些人都觀看了：
              .xBox.bg-dark.d-flex.justify-content-center.align-items-center(style="width:30px;height:30px; border-radius:50%; color:white; cursor:pointer;" @click.stop="whoSee = !whoSee") X
            .d-flex.flex-column.aligm-items-center
              .d-flex.align-items-center.mb-4(v-for="visitor in visitors")
                .redCircle.bg-primary(style="width:40px; height:40px; border-radius:50%;")
                p.mb-0.ml-3.mr-auto {{visitor.name}}
                .btn-follow.d-flex.justify-content-center.align-items-center(@click="followUser({userId:visitor.id})") {{followStr(visitor.id)}}
        img(src='../assets/eachPost/magazine.png').ml-4
        p.font-weight-bold.mb-0.followWord.mr-3 {{group.postDisplayNum}}顯示
    .d-flex.align-items-center.mb-5
      showAt(breakpoint="large")
        .pinGrayBorder.d-flex.align-items-center(v-if="group.postType === 'inHot'").ml-5
          img.pin(src="../assets/eachPost/pin.png")
          p.mb-0.font-weight-bold.ml-3(@click="followGroup({groupId:group.id})") {{followGroupStr(group.id)}}
          p.mb-0.text-primary.font-weight-bold.mx-2 |
          img(src='../assets/eachPost/bluePin.png')
          .font-weight-bold.mb-0.followWord(@click="whoFollow = !whoFollow" style="cursor:pointer") {{group.knockNum}}追蹤
            .whoFollow(v-if="whoFollow")
              .d-flex
                p.mb-5.text-left.mr-auto 這些人都追蹤了：
                .xBox.bg-dark.d-flex.justify-content-center.align-items-center(style="width:30px;height:30px; border-radius:50%; color:white; cursor:pointer;" @click.stop="whoFollow = !whoFollow") X
              .d-flex.flex-column.aligm-items-center
                .d-flex.align-items-center.mb-4(v-for="follower in followers")
                  .redCircle.bg-primary(style="width:40px; height:40px; border-radius:50%;")
                  p.mb-0.ml-3.mr-auto {{follower.name}}
                  .btn-follow.d-flex.justify-content-center.align-items-center(@click="followUser({userId:follower.id})") {{followStr(follower.id)}}
          img(src='../assets/eachPost/eye.png').ml-2
          .font-weight-bold.mb-0.followWord(@click="whoSee = !whoSee" style="cursor:pointer") {{group.postViewNum}}觀看
            .whoFollow(v-if="whoSee")
              .d-flex
                p.mb-5.text-left.mr-auto 這些人都觀看了：
                .xBox.bg-dark.d-flex.justify-content-center.align-items-center(style="width:30px;height:30px; border-radius:50%; color:white; cursor:pointer;" @click.stop="whoSee = !whoSee") X
              .d-flex.flex-column.aligm-items-center
                .d-flex.align-items-center.mb-4(v-for="visitor in visitors")
                  .redCircle.bg-primary(style="width:40px; height:40px; border-radius:50%;")
                  p.mb-0.ml-3.mr-auto {{visitor.name}}
                  .btn-follow.d-flex.justify-content-center.align-items-center(@click="followUser({userId:visitor.id})") {{followStr(visitor.id)}}
          img(src='../assets/eachPost/magazine.png').ml-2
          p.font-weight-bold.mb-0.followWord.mr-3 {{group.postDisplayNum}}顯示
          .countBox.text-log(@click="good({postId:group.id})") +1
            .badge.badge-dark(v-if="group.good > 0") {{group.good}}
          .countBox.text-log.ml-3(@click="bad({postId:group.id})") -1
            .badge.badge-dark(v-if="group.bad > 0") {{group.bad}}
      showAt(breakpoint="large")
        .d-flex.ml-auto
          .grayCircle.d-flex.align-items-center.justify-content-center(@click="shareOption = !shareOption")
            img(src="../assets/eachPost/share.png")
            .shareOption(v-if="shareOption")
              .d-flex.align-items-center.mb-4
                .shareRedCircle
                p.mb-0.font-weight-bold.ml-3(@click="copyHref()") 複製連結分享
              .d-flex.align-items-center
                .shareRedCircle
                p.mb-0.font-weight-bold.ml-3(@click="copyHref()") 分享到我的Amy
              //-.d-flex.border-left.ml-4.pl-4.flex-column
                .d-flex.align-items-center.mb-4
                  .shareRedCircle
                  p.mb-0.font-weight-bold.ml-3 專頁(社團)連結
                .d-flex.align-items-center.mb-4
                  .shareRedCircle
                  p.mb-0.font-weight-bold.ml-3 WEB註冊連結(擇一)
                .d-flex.align-items-center.mb-4
                  .shareRedCircle
                  p.mb-0.font-weight-bold.ml-3 FB 連結(擇一)
                .d-flex.align-items-center.mb-4
                  .shareRedCircle
                  p.mb-0.font-weight-bold.ml-3 LINE 註冊連結(擇一)
          .grayCircle.d-flex.align-items-center.justify-content-center.mx-2(@click="otherFunction = !otherFunction")
            .threeBlueDot
            .threeBlueDot.mx-2
            .threeBlueDot
            .otherFunction(v-if="otherFunction")
              .d-flex.align-items-center(@click="report({cardId:group.cardId,content:''})")
                .shareRedCircle
                p.mb-0.font-weight-bold.ml-3 檢舉
              .d-flex.align-items-center(@click="del({id:group.id})" v-if="group.postType === 'inHot' && !owner")
                .shareRedCircle
                p.mb-0.font-weight-bold.ml-3 刪除
</template>

<script>
import { showAt, hideAt } from 'vue-breakpoints';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    showAt,
    hideAt,
  },
  props: ['group'],
  name: 'BasePostHeader',
  data() {
    return {
      actionShowIdx: null,
      whoFollow: false,
      whoSee: false,
      shareOption: false,
      otherFunction: false,
    };
  },
  computed: {
    ...mapGetters('account', ['followGroupStr', 'followStr']),
    ...mapState('post', ['followers', 'visitors', 'owner']),
  },
  methods: {
    ...mapActions('post', ['init', 'good', 'bad', 'del']),
    ...mapActions('account', ['followGroup', 'followUser']),
    ...mapActions('dashboard', ['report']),
    actionShowIdxMethod(idx) {
      if (this.actionShowIdx === null) {
        this.actionShowIdx = idx;
      } else {
        this.actionShowIdx = null;
      }
    },
    clickDom(e) {
      const { target } = e;
      const shareOptionDom = document.getElementsByClassName('shareOption');
      const otherFunctionDom = document.getElementsByClassName('otherFunction');

      if (shareOptionDom.length !== 0 && !shareOptionDom[0].contains(target)) {
        this.shareOption = false;
      }

      if (otherFunctionDom.length !== 0 && !otherFunctionDom[0].contains(target)) {
        this.otherFunction = false;
      }
    },
  },
  mounted() {
    // window.console.log(this.group);
    document.addEventListener('click', (e) => {
      this.clickDom(e);
    }, true);
  },
  destroyed() {
    document.removeEventListener('click', (e) => {
      this.clickDom(e);
    }, true);
  },
};

</script>

<style lang="scss" scoped>
@media (max-width: 980px) {
  .pinGrayBorder {
    border: 0px !important;
  }
  .pin {
    width: 30px !important;
    height: 30px !important;
  }
  .follow-btn {
    text-align: center;
    width: 50px;
  }
  .data-info {
    img {
      width: 20px;
      height: auto;
    }

    background: rgba(235, 235, 235, 0.4);
    width: 100%;
    height: 30px;
    border-radius: 10px;
    padding: 3px;
  }
}

.eachPostContent {
  padding: 100px;
  // padding-left:30px;
  padding-top: 16px;
  // padding-bottom: 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: scroll;
  height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

hr {
  width: 100px;
  border-color: #ebebeb;
  border-width: 3px;
}

.from {
  font-size: 18px;
  color: #BAB9B9;
  font-weight: bold;
}

.fromBlueBox {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  background: #2277ff;
}

.eachPageName {
  font-size: 20px;
  font-weight: bold;
}

.grayBorderBox {
  margin-top: 40px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 3px #00000029;
}

.nameCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #555;
}

.name {
  font-size: 18px;
  color: #555;
}

.pinGrayBorder {
  height: 40px;
  border: 3px solid #EBEBEB;
  border-radius: 20px;
  border-left: 0;
}

.pin {
  width: 40px;
  height: 40px;
}

.followWord {
  color: #555555;
  margin-left: 8px;
  position: relative;

  .whoFollow {
    width: 600px;
    padding: 16px;
    border-radius: 17px;
    // height: 700px;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    position: absolute;
    top: 150%;
    left: -300px;
    background: white;
    z-index: 100;
  }

  .btn-follow {
    width: 120px;
    height: 40px;
    border-radius: 27px;
    border: 2px solid #2277FF;
    color: #2277ff;
    font-weight: bold;
  }

  .btn-follow:hover {
    background: #2277FF;
    color: white;
  }
}

// 分享跟其他功能 下拉視窗
.grayCircle {
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, .11);
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  .shareOption {
    position: absolute;
    width: 280px;
    padding: 16px;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    background: white;
    z-index: 100;
    top: 130%;
    right: 0;
    border-radius: 17px;
    font-size: 18px;

    .shareRedCircle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #dd4b6e;
    }
  }

  .otherFunction {
    position: absolute;
    width: 280px;
    padding: 16px;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    background: white;
    z-index: 100;
    top: 130%;
    right: 0;
    border-radius: 17px;
    font-size: 18px;

    .shareRedCircle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #dd4b6e;
    }
  }
}

.threeBlueDot {
  width: 8px;
  height: 8px;
  background: #2277ff;
  border-radius: 50%;
}

.photoTitle {
  font-size: 20px;
  color: #555555;
  font-weight: bold;
}

.grayBadge {
  width: 80px;
  height: 32px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .1);
  font-size: 14px;
  color: black;
  margin: 4px 6px;
}

.grayBg {
  padding: 16px;
  background: rgba(235, 235, 235, .4);
  border-radius: 14px;
  margin-top: 40px;
}

.personalCover {
  background: #dd4b6e;
  border-radius: 15px;
  color: white;
  height: 130px;
  position: relative;

  .blackcircle {
    position: absolute;
    width: 70px;
    height: 70px;
    border: 3px solid white;
    background: black;
    border-radius: 50%;
    top: calc(100% - 35px);
    left: 20px;
  }

  .amyNickName {
    top: 125%;
    position: absolute;
    left: 20px;
    color: black;
    font-weight: bold;
  }
}

// adminBtn
.adminBtn {
  width: 140px;
  height: 54px;
  border-width: 3px;
  box-sizing: border-box;
  border-radius: 27px;
  position: relative;
  background: transparent;
  background: white;
  color: black;

  &.border-primary, &.border-dark {
    border-left: 0px;
  }

  &:focus {
    outline: none;
  }

  &.borderBlack {
    border-color: black;
    color: black;
  }

  .imgBox {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;

    &.border-primary {
      border: 3px solid #DD4B6E;
    }

    img {
      width: 30px;
    }
  }
}

.socialDetail {
  margin-left: 10px;
  padding: 12px;
  background: rgba(235, 235, 235, .5);
  border-radius: 15px;

  td {
    color: #555555;
    font-weight: bold;
    width: 50%;
  }
}

.eachLittlePostContent {
  overflow: auto;
}

.eachLittlePost {
  // overflow-x:scroll;
  width: 150px;

  .imgBox {
    position: relative;
  }

  .mainImg {
    width: 150px;
    height: 150px;
    border-radius: 10px;
  }

  .eye {
    position: absolute;
    bottom: 16px;
    right: 50px;
  }

  .watchedNumber {
    position: absolute;
    color: white;
    font-weight: bold;
    right: 10px;
    bottom: 18px;
    font-size: 14px;
  }

  .detailContent {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //行數
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.all {
  width: 145px;
  height: 50px;
  border-radius: 25px 0 0 25px;
  background: rgba(235, 235, 235, .2);
  font-size: 18px;
  color: #EBEBEB;
  font-weight: bold;
}

.newest {
  width: 145px;
  height: 50px;
  border-radius: 0 25px 25px 0;
  color: #555555;
  font-weight: bold;
  background: #EBEBEB;
}

.eachMemberDiscuss {
  border-radius: 7px;

  .memberCircle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #555;
  }

  .memberName {
    color: #555555;
    font-size: 18px;
    font-weight: bold;
    margin-left: 8px;
  }

  .followRedBox {
    border: 2px solid #DD4B6E;
    border-radius: 17px;
    height: 34px;
    color: black;
    background: #FFCEDA;
    padding: 14px;
    margin-left: 16px;
  }
}

.date {
  font-size: 14px;
  color: #BAB9B9;
  font-weight: bold;
}

.contentDetail {
  color: #555555;
  font-weight: bold;
}

.countBox {
  width: 65px;
  height: 36px;
  border-radius: 18px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #DD4B6E;
  position: relative;

  .badge {
    position: absolute;
    bottom: -8px;
    right: -10px;
  }
}

.threeDotGrayBox {
  width: 45px;
  height: 45px;
  background: rgba(0, 0, 0, .1);
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  .memberDiscussAction {
    box-shadow: 0px 0px 6px #00000029;
    position: absolute;
    width: 250px;
    height: 55px;
    border-radius: 17px;
    color: #2277FF;
    font-size: 14px;
    background: white;
    top: 100%;
    right: 0;
  }
}

.eachMemberDiscuss:nth-child(odd) {
  background: #0000001A;
}

.showMore {
  background: rgba(257, 127, 258, .2);
  color: black;
  font-weight: bold;
  height: 60px;
  border-radius: 30px;
}

.grayBottomBorderBox {
  padding: 16px;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 0px 0px 28px 28px;

  .leaveMessageCircle {
    width: 60px;
    height: 60px;
    background: #555;
    border-radius: 50%;
  }

  .form-control {
    width: 80%;
    border-radius: 24px;
    height: 60px;
    background: rgba(0, 0, 0, .04);
  }

  .btn-send {
    height: 60px;
    border-radius: 30px;
    background: #dd4b6e;
    color: white;
    width: 100px;
  }
}

</style>
