<template lang="pug">
  .discussContainer.d-flex.justify-content-center.align-items-center.mb-3
    .postPicBox
      img(:src="data.images[0]").img-fluid
    .discussContent
      .d-flex.align-items-center
        .darkCircle(:style="`background-image: url('${data.userHead}');background-size: cover;`")
        p.memberName.mb-0.ml-2.text-light {{data.userName}}
        .postTime {{data.created}}
      p.text-justify.mt-3.text-light(v-html="data.content")
      p.mb-1.moreText(@click="redirectPost({data:data})") 更多
      p.mb-4.text-light.text-left.viewNumber  瀏覽人數{{data.views}}
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'BasePostPic',
  props: ['data'],
  methods: {
    ...mapActions('dashboard', ['redirectPost']),
  },
};

</script>
<style scoped lang="scss">
.picturePostContent{
  height: 100vh;
  overflow: scroll;
  padding: 50px 60px 50px 95px;
  &::-webkit-scrollbar {
    display: none;
  }
}
.backArrowBox{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #EBEBEB;
}
.backWord{
  color: black;
  font-weight: bold;
  margin-left: 16px;
}
.detailContent{
  padding:30px;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 59px 59px 59px 59px;
  box-sizing: border-box;
  margin-top: 40px;
  &.unIn{
    background: #1D1D1D;
  }
}
// .beautifySettingBox
.beautifySettingBox{
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 59px;
  padding: 16px;
  box-sizing: border-box;
}
.uploadCover{
  height: 300px;
  background: #292929 0% 0% no-repeat padding-box;
  border-radius: 40px;
  color:#FFFFFF;
  font-weight: bold;
  font-size: 30px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.settingPhoto{
  position:relative;
}
.headPhoto{
  width: 120px;
  height: 120px;
  background: black;
  border: 5px solid #FFFFFF;
  border-radius: 30px;
  position:absolute;
  bottom: -75px;
  color:#2277FF;
  font-size: 20px;
  font-weight: bold;
}
.title{
  color: white;
  font-size: 28px;
  margin-top: 60px;
}
.share{
  position:absolute;
  width: 150px;
  height: 60px;
  border-radius: 30px;
  display:flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #000;
  left:20px;
  top: 16px;
  cursor: pointer;
}
.shareContent{
  width: 500px;
  height: 500px;
  top:120%;
  left:20%;
  position:absolute;
  background: white;
  box-shadow: 0px 0px 20px #00000033;
  border: 6px solid #FFCEDA;
  border-radius: 13px;
  color:#292929;
  font-size: 24px;
  &::before{
    content:'';
    display:block;
    background: transparent;
    width: 0;
    height: 0;
    // border-top: 10px solid rgba(0,0,0,0);
    border-bottom: 10px solid white ;
    border-right: 10px solid rgba(0,0,0,0);
    border-left: 10px solid rgba(0,0,0,0);
    position:absolute;
    top: -10px;
    left:54px;
    z-index: 2;
  }
  &::after{
    content:'';
    display:block;
    background: transparent;
    width: 0;
    height: 0;
    // border-top: 10px solid rgba(0,0,0,0);
    border-bottom: 14px solid #FFCEDA ;
    border-right: 14px solid rgba(0,0,0,0);
    border-left: 14px solid rgba(0,0,0,0);
    position:absolute;
    top: -17px;
    left:50px;
    z-index: 1;
  }

}
.redCircle{
  width: 47px;
  height: 47px;
  background: #DD4B6E;
  border-radius: 50%;
}
.threeDotBox{
  position:absolute;
  width: 90px;
  height: 60px;
  border-radius: 30px;
  background: #000;
  display:flex;
  align-items: center;
  justify-content: center;
  color: white;
  top:16px;
  right:20px;
  cursor: pointer;
  span{
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
  }
}
.threeDotBoxContent{
  position:absolute;
  background: white;
  box-shadow: 0px 0px 20px #00000033;
  border: 6px solid #FFCEDA;
  width: 280px;
  padding: 20px 16px;
  border-radius: 13px;
  top:120%;
  right:0;
  color: #292929;
  font-size: 24px;
  &::before{
    content:'';
    display:block;
    background: transparent;
    width: 0;
    height: 0;
    // border-top: 10px solid rgba(0,0,0,0);
    border-bottom: 10px solid white ;
    border-right: 10px solid rgba(0,0,0,0);
    border-left: 10px solid rgba(0,0,0,0);
    position:absolute;
    top: -10px;
    left:214px;
    z-index: 2;
  }
  &::after{
    content:'';
    display:block;
    background: transparent;
    width: 0;
    height: 0;
    // border-top: 10px solid rgba(0,0,0,0);
    border-bottom: 14px solid #FFCEDA ;
    border-right: 14px solid rgba(0,0,0,0);
    border-left: 14px solid rgba(0,0,0,0);
    position:absolute;
    top: -17px;
    left:210px;
    z-index: 1;
  }
}
// 敲敲門
.knockknock{
  cursor: pointer;
}
.knockBox{
  width: 570px;
  padding:10px 16px;
  border: 5px solid #555555;
  border-radius: 24px;
  font-size: 20px;
  color: white;
  span{
    cursor:pointer;
  }
}
.knockNum,.watchNum{
  position:relative;
}
.knockNumList,.watchNumList{
  position:absolute;
  width: 400px;
  height: 600px;
  box-sizing: border-box;
  padding: 16px;
  background: white;
  box-shadow: 0px 0px 20px #00000033;
  border: 6px solid #FFCEDA;
  top: 120%;
  left:-20px;
  border-radius: 13px;
  z-index: 4;
  font-size: 20px;
  font-weight: bold;
  color:#292929;
  overflow-y:auto;
  .btn-outline-log{
    border: 3px solid #2277FF;
    border-radius: 23px;
    font-weight: bold;
  }
  &::before{
    content:'';
    display:block;
    background: transparent;
    width: 0;
    height: 0;
    // border-top: 10px solid rgba(0,0,0,0);
    border-bottom: 10px solid white ;
    border-right: 10px solid rgba(0,0,0,0);
    border-left: 10px solid rgba(0,0,0,0);
    position:absolute;
    top: -10px;
    left:20px;
    z-index: 2;
  }
  &::after{
    content:'';
    display:block;
    background: transparent;
    width: 0;
    height: 0;
    // border-top: 10px solid rgba(0,0,0,0);
    border-bottom: 14px solid #FFCEDA ;
    border-right: 14px solid rgba(0,0,0,0);
    border-left: 14px solid rgba(0,0,0,0);
    position:absolute;
    top: -17px;
    left:16px;
    z-index: 1;
  }
}
.knockSelect{
  box-shadow: 0px 3px 6px #00000029;
  border: 4px solid #555555;
  border-radius: 14px;
  padding: 30px;
  position:relative;
  img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .talkBox1{
    width: 208px;
    height: 70px;
    box-shadow: 0px 3px 6px #00000029;
    border: 5px solid #DD4B6E;
    border-radius: 7px 23px 23px 41px;
  }
  .talkBox2{
    width: 208px;
    height: 70px;
    box-shadow: 0px 3px 6px #00000029;
    border: 5px solid #BAB9B9;
    border-radius: 41px 23px 23px 7px;
    background: white;
  }
  .timesBox{
    background: rgba(186,185,185,.4);
    width: 38px;
    height: 38px;
    color: black;
    border-radius: 50%;
    font-size: 24px;
    position:absolute;
    top:16px;
    right:16px;
    font-weight: bold;
    cursor: pointer;
  }
  .knockNotice{
    background: black;
    box-shadow: 0px 3px 6px #00000029;
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-radius: 18px;
    color: white;
    height: 46px;
  }
  &::before{
    content:'';
    display:block;
    background: transparent;
    width: 0;
    height: 0;
    // border-top: 10px solid rgba(0,0,0,0);
    border-bottom: 10px solid #1D1D1D ;
    border-right: 10px solid rgba(0,0,0,0);
    border-left: 10px solid rgba(0,0,0,0);
    position:absolute;
    top: -10px;
    left:154px;
    z-index: 2;
  }
  &::after{
    content:'';
    display:block;
    background: transparent;
    width: 0;
    height: 0;
    // border-top: 10px solid rgba(0,0,0,0);
    border-bottom: 14px solid #555555 ;
    border-right: 14px solid rgba(0,0,0,0);
    border-left: 14px solid rgba(0,0,0,0);
    position:absolute;
    top: -17px;
    left:150px;
    z-index: 1;
  }
}
.blackCircleBox{
  position:relative;
}
.iAmAdmin{
  position:absolute;
  width: 159px;
  height: 54px;
  background: #000000 0% 0% no-repeat padding-box;
  border: 3px solid #EBEBEB;
  border-radius: 41px 23px 23px 7px;
  color: white;
  font-size: 18px;
  top:-15px;
  left: 120%;
}
.blackCircle{
  width: 70px;
  height: 70px;
  background:#555555;
  border-radius: 50%;
}
.name{
  font-size: 26px;
  color:#555555;
  font-weight: bold;
}
.w-45{
  width: 45%;
  padding: 16px;
  background: rgba(0,0,0,.63);
  color: white;
  border-radius: 13px;
}
// .track-followedNum
// .track-followedNum{
//   box-shadow: 0px 0px 5px #00000029;
//   border: 2px solid #EB50C7;
//   padding: 12px 16px;
//   box-sizing: border-box;
//   color: white;
//   background: #EB50C780;
//   border-radius: 23px;
// }
// .line{
//   color:#EB50C7;
//   font-size: 20px;
// }
.gridContent{
  // display:grid;
  // grid-template-rows: [row1] 30px [row2] auto [row3] 80px [row4];
  // grid-template-columns:[one] 50% [two] 50% [three];
  width: 100%;
  padding:24px;
  // height: 254px;
  border: 9px solid #DD4B6E;
  border-radius: 41px;
}
.fakeImg{
  grid-row: 1/4;
  grid-column: 1/2;
  max-width: 400px;
}
.mainTitle{
  font-size: 20px;
  color: white;
  grid-row:1/2;
  grid-column: 2/3;
  width: 100%;
  text-align:left;
  font-weight: bold;
}
.about{
  text-align: left;
  padding:16px 0px;
  box-sizing: border-box;
  grid-row:2/3;
  grid-column: 2/3;
}
// .badge{
//   width: 70px;
//   height: 32px;
//   padding:5px 12px;
//   box-sizing: border-box;
//   background: rgba(0,0,0,.1);
//   font-weight: bold;
//   font-size: 14px;
//   border-radius: 16px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 8px;
// }
// .badgeContent{
//   grid-row: 3/4;
//   grid-column: 2/3;
//   padding:5px 16px;
// }
// personal info
.personalInfo{
  margin-top: 30px;
  padding:30px;
  box-sizing: border-box;
  background: rgba(235,235,235,.4);
  border-radius: 5px;
  .personalPic{
    width: 420px;
    height: 130px;
    display: flex;
    justify-content:center;
    align-items: center;
    font-size: 18px;
    color:#FFFFFF;
    background: #F8A6E5;
    border-radius: 5px;
    position: relative;
    // .blackCircle{
    //   width: 75px;
    //   height: 75px;
    //   border-radius: 50%;
    //   background: #000;
    //   border: 2px solid #FFFFFF;
    //   position: absolute;
    //   left: 20px;
    //   bottom: -37px;
    // }
    .nickName{
      font-size: 18px;
      color: #555555;
      position: absolute;
      left: 20px;
      bottom: -60px;
    }
  }
  .motiontopersion{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 46px;
    border-radius: 23px;
    border: 2px solid #EB50C7;
    cursor: pointer;
  }
  .gototrack{
    color: white;
    background: #EB50C780;
    margin-left: auto;
  }
  .personalDetail{
    margin-left: 10px;
    width: 495px;
    height: 188px;
    padding: 16px 0 16px 8px;
    box-sizing: border-box;
    background: rgba(235,235,235,.5);
    border-radius: 15px;
  }
  .fb{
    color:#2277FF;
  }
}
// card box
.cardBox{
  margin-top: 10px;
  cursor: pointer;
  flex-shrink:1;
  .cardBox-head{
    max-width:130px;
    min-height: 110px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px #00000029;
    position: relative;
    img[alt="main"]{
      max-width: 130px;
      border-radius: 10px;
      z-index: 1;
    }
    .readedNum{
      display: flex;
      align-items:center;
      justify-content: flex-end;
      position: absolute;;
      right:10px;
      bottom :10px;
      z-index: 100;
      span{
        margin-left: 10px;
        font-size: 12px;
        line-height: 12px;
        color: white;
      }
    }
  }
  .cardBox-footer{
    max-width: 130px;
    height: 22px;
    line-height: 14px;
    overflow:hidden;
    font-size: 14px;
    color:#BAB9B9;
    padding-top: 10px;
    box-sizing: border-box;
  }
}
.cardBox-body{
  min-height: 152px;
  max-width:130px;
  overflow: hidden;
  border: 2px solid #F8A6E5;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 13px;
  color:#BAB9B9;
  position:relative;
  padding: 16px;
  .readedNum{
    position:absolute;
    bottom: 5px;
    right:10px;
  }
}
.postContent{
  overflow: hidden;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
}
//showall or new
.showall-new-box{
  width: 140px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.showall{
  background: rgba(235,235,235,.3);
  color:#BAB9B9;
  border-radius: 25px 0px 0px 25px;
}
.new{
  color:#555555;
  background: #EBEBEB;
  border-radius: 0 25px 25px 0;
}
// postPicBox
.postPicBox{
  width:30%;
}
// .discussContent
.discussContainer:nth-child(odd){
  background: #000;
  border-radius: 13px;
}
.discussContainer{
  &.borderBlack{
    border: 6px solid #000000;
    border-radius: 13px;
  }
}
.discussContent{
  margin-top: 30px;
  padding:16px 20px;
  border-radius: 7px;
  width: 65%;

  .darkCircle{
    width: 32px;
    height: 32px;
    background: #555555;
    border-radius: 50%;
  }
  .memberName{
    font-size: 18px;
    font-weight: bold;
  }
  .postTime{
    font-size: 14px;
    color: #BAB9B9;
    margin-left: auto;
    font-weight: bold;
  }
  .moreText{
    font-size: 14px;
    color: #2277FF;
    text-align: left;
    font-weight: bold;
    cursor: pointer;
  }
  .viewNumber{
    font-size: 14px;
  }
}
.discussContainer:nth-child(odd){
  .button{
    border: 2px solid #EB50C7;
    color:#2277FF;
  }
}
.discussContainer:nth-child(even){
  .button{
    border: 2px solid #EB50C7;
    color:white;
    background: #eb50c7;
  }
}
.button{
  width: 90px;
  height: 36px;
  border-radius: 18px;

  padding:7px 20px;
  box-sizing:border-box;

  font-weight: bold;
  font-size: 14px;
  position:relative;
  display:flex;
  justify-content: center;
  align-items: center;
}
.button-num{
  max-width: 40px;
  height: 22px;
  padding: 2px 12px;
  box-sizing: border-box;
  border-radius: 11px;
  color: white;
  font-size: 12px;
  position: absolute;
  bottom:-14px;
  right:-20px;
}
.reply-delete{
  padding:20px;
  box-sizing: border-box;
  background: white;
  position: absolute;
  top:99%;
  right: 0;
  width: 125px;
  font-size: 14px;
  display:none;
  border-radius: 17px;
  box-shadow: 0px 3px 6px #00000029;
}
.seeMorePost{
  padding: 16px 0;
  width: 100%;
  background: rgba(255, 127, 158, .3);
  max-width: 700px;
  font-weight: bold;
  border-radius: 30px;
  margin-top: 30px;
  color: white;
}
.addNewMessage{
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 0px 0px 28px 28px;
  padding:30px;
  box-sizing: border-box;
  .darkCircle{
    width: 65px;
    height: 65px;
    border-radius:50%;
    background: #555555;
  }
  .form-control{
    max-width: 700px;
    height: 65px;
    border-radius: 32px;
    background: rgba(0,0,0,.04);
  }
  .btn-primary{
    width: 134px;
    height: 65px;
    font-size: 20px;
    border-radius: 32px;
  }
}
// adminBtn
.adminBtn{
  width: 150px;
  height: 54px;
  border-width: 3px;
  box-sizing: border-box;
  border-radius: 27px;
  position:relative;
  background: transparent;
  &.border-primary,&.border-dark{
    border-left :0px;
  }
  &:focus{
    outline: none;
  }
  &.borderBlack{
    border-color:white;
    color: #2277FF;
  }
  .imgBox{
    height: 48px;
    width: 48px;
    border-radius: 50%;
    position: absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    top: 0;
    left:0;
    &.border-primary{
      border: 3px solid #DD4B6E;
    }
    img{
      width: 30px;
    }
  }
}
// 社群設定框
td{
  span{
    overflow:hidden;
  }
}
</style>
