<template lang="pug">
  <div id="app">
    router-view
    hideAt(breakpoint="large")
      //- <div id="DoInstall" style="display: block;">
        img(src="./assets/logo62.png" alt="")
        <button class="lang" k="97" onclick="TriggerInstall();">安裝AmyTag</button>
        <button onclick="CloseInstallAlert();">X</button>
      //- </div>
  </div>
</template>
<script src="./assets/pwa.js" integrity="sha256-4+XzXVhsDmqanXGHaHvgh1gMQKX40OUvDEBTu8JcmNs=" crossorigin="anonymous"></script>
<script src="./assets/service-worker.js" integrity="sha256-4+XzXVhsDmqanXGHaHvgh1gMQKX40OUvDEBTu8JcmNs=" crossorigin="anonymous"></script>

<script>
import { showAt, hideAt } from 'vue-breakpoints';

export default {
  components: {
    showAt,
    hideAt,
  },
  methods: {
    IsIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    },
    CloseInstallAlert() {
      if (document.getElementById('DoInstall')) {
        document.getElementById('DoInstall').style.display='none';
      }
    },
  },
  created() {
    if (this.IsIOS()) {
      setTimeout(() => { this.CloseInstallAlert(); }, 500);
    }
    if (window.location.href.includes('/ad')) {
      setTimeout(() => { this.CloseInstallAlert(); }, 500);
    }
    if (window.location.href.includes('/bggpost')) {
      setTimeout(() => { this.CloseInstallAlert(); }, 500);
    }
  },
};
</script>

<style lang="scss">
@import "./assets/all.scss";
#app {
  font-family: Montserrat,Noto Sans TC,微軟正黑體,Microsoft JhengHei,Heiti TC,黑體,Nunito Sans,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#DoInstall {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background: #ff7f9e;
  border-radius: 5px;
  padding: 7px 10px;
  z-index: 88;
  display: none;
}
#DoInstall button {
  display: inline-block;
  vertical-align: bottom;
  background: #db2e58;
  color: #fff;
  font-weight: bold;
  border: 0;
  padding: 8px 12px;
  margin-left: 6px;
}
#DoInstall img {
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 10px;
}
@media only screen and (max-width: 800px){
  #DoInstall {
    bottom: 0px;
    left: unset;
  }
}
</style>
