<template lang="pug">
  .mainContent
    .d-grid
      .pic(v-if="post.embed")
        div(v-if="post.embed" v-html="post.embed" style="width:100%")
        .amyId.d-flex.align-items-center
        .blackCircle(:style="`background-image: url('${post.userHead}');background-size: cover;`")
        p.mb-0.font-weight-bold {{post.userName}}
      .pic(v-else="post.videos.length > 0")
        <video width="100%" height="100%" controls>
          <source :src="post.videos[0]">
        </video>
        .amyId.d-flex.align-items-center
        .blackCircle(:style="`background-image: url('${post.userHead}');background-size: cover;`")
        p.mb-0.font-weight-bold {{post.userName}}
      .description
        p.text-justify.mb-4(v-html="post.content")
        .d-flex.align-items-center
          .mb-0.text-left.seeMore.mr-5.text-decoration-none(@click="redirectPost({data:post})") 看更多
          p.mb-0.text-from -來自
          .blueBox.mx-3
          .text-log.mb-0.text-decoration-none(@click="redirectPost({data:post})") {{post.groupName}}
      //.watchedNum 瀏覽人數{{post.views}} 人
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'BaseContent',
  props: ['post'],
  methods: {
    ...mapActions('dashboard', ['redirectPost']),
  },
  mounted() {
  },
};
</script>
