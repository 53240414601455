<template lang="pug">
  .orderOptionBox
    .d-flex.align-items-center
      p.mb-0.default.theNewest.mr-auto 最新發佈
      .switchOn.mr-4(v-if="this.$store.state[module].order === 'news'" @click="off()")
        p.mb-0.ml-3
        .check √
      .switchOff.mr-4(v-if="this.$store.state[module].order !== 'news'" @click="on({val:'news'})")
        .close X
        p.mb-0 關閉
    .d-flex.align-items-center
      p.mb-0.default.theNewest.mr-auto 一小時內
      .switchOn.mr-4(v-if="this.$store.state[module].order === 'hour'" @click="off()")
        p.mb-0.ml-3
        .check √
      .switchOff.mr-4(v-if="this.$store.state[module].order !== 'hour'" @click="on({val:'hour'})")
        .close X
        p.mb-0 關閉
    .d-flex.align-items-center
      p.mb-0.default.theNewest.mr-auto 今天
      .switchOn.mr-4(v-if="this.$store.state[module].order === 'today'" @click="off()")
        p.mb-0.ml-3
        .check √
      .switchOff.mr-4(v-if="this.$store.state[module].order !== 'today'" @click="on({val:'today'})")
        .close X
        p.mb-0 關閉
    .d-flex.align-items-center
      p.mb-0.default.theNewest.mr-auto 本周
      .switchOn.mr-4(v-if="this.$store.state[module].order === 'week'" @click="off()")
        p.mb-0.ml-3
        .check √
      .switchOff.mr-4(v-if="this.$store.state[module].order !== 'week'" @click="on({val:'week'})")
        .close X
        p.mb-0 關閉
    .d-flex.align-items-center
      p.mb-0.default.theNewest.mr-auto 本月
      .switchOn.mr-4(v-if="this.$store.state[module].order === 'month'" @click="off()")
        p.mb-0.ml-3
        .check √
      .switchOff.mr-4(v-if="this.$store.state[module].order !== 'month'" @click="on({val:'month'})")
        .close X
        p.mb-0 關閉
    .d-flex.align-items-center
      p.mb-0.default.theNewest.mr-auto 今年
      .switchOn.mr-4(v-if="this.$store.state[module].order === 'year'" @click="off()")
        p.mb-0.ml-3
        .check √
      .switchOff.mr-4(v-if="this.$store.state[module].order !== 'year'" @click="on({val:'year'})")
        .close X
        p.mb-0 關閉
    .d-flex.align-items-center
      p.mb-0.default.theNewest.mr-auto +1數量
      .switchOn.mr-4(v-if="this.$store.state[module].order === 'good'" @click="off()")
        p.mb-0.ml-3
        .check √
      .switchOff.mr-4(v-if="this.$store.state[module].order !== 'good'" @click="on({val:'good'})")
        .close X
        p.mb-0 關閉
    .d-flex.align-items-center
      p.mb-0.default.theNewest.mr-auto -1數量
      .switchOn.mr-4(v-if="this.$store.state[module].order === 'bad'" @click="off()")
        p.mb-0.ml-3
        .check √
      .switchOff.mr-4(v-if="this.$store.state[module].order !== 'bad'" @click="on({val:'bad'})")
        .close X
        p.mb-0 關閉
    .d-flex.align-items-center
      p.mb-0.default.theNewest.mr-auto 最多留言
      .switchOn.mr-4(v-if="this.$store.state[module].order === 'messages'" @click="off()")
        p.mb-0.ml-3
        .check √
      .switchOff.mr-4(v-if="this.$store.state[module].order !== 'messages'" @click="on({val:'messages'})")
        .close X
        p.mb-0 關閉
    .d-flex.align-items-center
      p.mb-0.default.theNewest.mr-auto 最多追蹤
      .switchOn.mr-4(v-if="this.$store.state[module].order === 'follow'" @click="off()")
        p.mb-0.ml-3
        .check √
      .switchOff.mr-4(v-if="this.$store.state[module].order !== 'follow'" @click="on({val:'follow'})")
        .close X
        p.mb-0 關閉
</template>

<script>

export default {
  name: 'BaseOrderOptions',
  props: ['module'],
  methods: {
    on({ val }) {
      this.$store.commit(`${this.module}/setValue`, { key: 'order', val });
      this.$store.commit(`${this.module}/resetList`);
      this.$store.dispatch(`${this.module}/init`);
    },
    off() {
      this.$store.commit(`${this.module}/setValue`, { key: 'order', val: '' });
      this.$store.commit(`${this.module}/resetList`);
      this.$store.dispatch(`${this.module}/init`);
    },
  },
  mounted() {
    // window.console.log(this.$store);
  },
};
</script>

<style lang="scss" scoped>
.dynamicWall {
  margin-top: 12px;
}

.tag {
  width: 90px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    background: #DD4B6E;
    color: white;
  }
}

.filterOption {
  font-size: 14px;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  opacity: 1;
  padding: 3px 10px;
  cursor: pointer;
}

.filterbyorder {
  position: relative;
}

// dynamicWallContent
.dynamicWallContent {
  margin-top: 10px;
}

.cardBox {
  margin-top: 10px;
  cursor: pointer;

  .cardBox-head {
    max-width: 130px;
    min-height: 110px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px #00000029;
    position: relative;

    img[alt="main"] {
      max-width: 130px;
      border-radius: 10px;
      z-index: 1;
    }

    .readedNum {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;;
      right: 10px;
      bottom: 10px;
      z-index: 100;

      span {
        margin-left: 10px;
        font-size: 12px;
        line-height: 12px;
        color: white;
      }
    }
  }

  .cardBox-footer {
    max-width: 130px;
    height: 22px;
    line-height: 14px;
    overflow: hidden;
    font-size: 14px;
    color: #BAB9B9;
    padding-top: 10px;
    box-sizing: border-box;
  }
}

.cardBox-body {
  min-height: 152px;
  max-width: 130px;
  overflow: hidden;
  border: 2px solid #DD4B6E;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 13px;
  color: #BAB9B9;
  position: relative;
  padding: 16px;

  .readedNum {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }
}

// dot box
.dotBox {
  width: 56px;
  height: 35px;
  background: #EBEBEB 0% 0%;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-left: 16px;
  cursor: pointer;
}

.dot {
  width: 5px;
  height: 5px;
  background: #2277FF;
  border-radius: 50%;
}

.dotBox:hover {
  background: #2277ff;

  .dot {
    background: white;
  }
}

// order
.orderOutBox {
  position: relative;
  z-index: 100;
}

.order {
  font-size: 14px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  opacity: 1;
  padding: 3px 10px;
  cursor: pointer;
}

.orderOptionBox {
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 17px;
  padding: 16px;
  box-sizing: border-box;
  position: absolute;
  top: 30px;
  right: 0;
  margin-top: 16px;
  z-index: 1000;
  background: white;
  width: 240px;
  .align-items-center{
    margin-bottom: 8px;
  }
}

.default {
  position: relative;
  z-index: 100;
}

.grayBox {
  width: 32px;
  height: 32px;
  background: #BAB9B9;
  border-radius: 50%;
}

.pinkBox {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #F8A6E5;
}

//
.switchOn {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  background: #2277FF;
  font-size: 16px;
  color: white;
  font-weight: bold;
  border-radius: 23px;
}

.switchOff {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  background: #c4c2c2;
  font-size: 16px;
  color: white;
  font-weight: bold;
  border-radius: 23px;

  &.onlyLook {
    width: 150px;
  }
}

.check {
  width: 24px;
  height: 24px;
  background: white;
  color: #555555;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  width: 24px;
  height: 24px;
  background: white;
  color: #555555;
  border-radius: 50%;
  margin-left: 16px;
  margin-right: 10px;
  cursor: pointer;
  padding-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
