import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import Vue from 'vue';
import jwtDecode from 'jwt-decode';
import api from '../../api';
import helper from './common/common';
import router from '../../router';

const user = localStorage.getItem('u') ? JSON.parse(localStorage.getItem('u')) : null;
const card = user ? user.active : {
  id: 0,
  name: '訪客',
  img: '/img/logo.ca6c658e.png',
  code: '',
  unread: 0,
};
const followUsers = user ? user.followUsers : [];
const followGroups = user ? user.followGroups : [];

// 點擊記錄(帳戶顯示用)
const ADCounter = {
  open: false,
  resetDate: '2021-xx-xx',
  siteClicks: 621,
  goods: 147,
  personal: 102,
  personalGoods: 120,
  tags: { '+Tag': 310, '++Tag': 571, '-Tag': 758 },
};

// 帳戶記錄
const ADRecords = {
  balance: 0,
  records: {
    rows: [['2021-xx-xx 14:10:50', '出帳', '206', '已完成', '849'], ['2021-xx-xx 11:30:30', '個人結算入帳', '375', '已完成', '1055'], ['2021-xx-xx 11:30:20', '專屬TAG結算入帳', '680', '已完成', '680']],
    count: 3,
    pages: 0,
  },
};

// 好友排行榜-最近互動
const RankingRecords = {
  data: {
    definition: ['使用者id', '暱稱', '圖片', '時間'],
    rows: [[0, '好友xxx', '/img/logo.ca6c658e.png', '2021-xx-xx 14:39:36'], [0, '好友zzz', '/img/logo.ca6c658e.png', '2021-xx-xx 11:08:16']],
    count: 2,
    pages: 0,
  },
};

// 好友排行榜-點擊
const RankingClicks = {
  data: {
    definition: ['點擊數', '使用者id', '暱稱', '圖片'],
    rows: [['50', 0, '好友xxx', '/img/logo.ca6c658e.png'], ['24', 0, '好友yyy', '/img/logo.ca6c658e.png'], ['10', 0, '好友zzz', '/img/logo.ca6c658e.png'], ['3', 0, '好友abc', '/img/logo.ca6c658e.png']],
    count: 2,
    pages: 0,
  },
};

// 全站排行榜-點擊
const RankingClicksAll = {
  data: {
    definition: ['點擊數', '使用者id', '暱稱', '圖片'],
    rows: [['100', 0, '某A', '/img/logo.ca6c658e.png'], ['64', 0, '某B', '/img/logo.ca6c658e.png'], ['33', 0, '某C', '/img/logo.ca6c658e.png'], ['12', 0, '某D', '/img/logo.ca6c658e.png']],
    count: 2,
    pages: 0,
  },
};

// Bgg探險任務資料
const BggQuests = {
  outingStatus: {
    definition: ['id', '探索完成', '蛋號碼', '已按讚', '事件時間', '結果標題', '結果內容', 'bones1', 'bones2'],
    rows: [[4, true, 3, true, '2022-02-26 17:13:48', '三號蛋', '隨手摘了一朵香菇來吃，覺得身神清氣爽～', 1500, 0.15], [3, true, 8, false, '2022-02-26 16:43:48', '八號蛋', '在路邊講古給聽泡茶的爺爺聽，獲得打賞。\r\n', 4000, 0.4], [3, true, 1, false, '2022-02-26 16:43:48', '一號蛋', '在路邊講古給聽泡茶的爺爺聽，獲得打賞。\r\n', 4000, 0.4]],
    count: 2,
    pages: 1,
  },
  state: 'free', // enum('outing', 'waiting', 'free')
  bggCoin: 5000,
  amyCoin: 0,
  quests: {
    definition: ['id', '拔古幣', 'amy幣', '探險時長'],
    rows: [[4, 24, 0, 480], [3, 12, 0, 240], [2, 6, 0, 120], [1, 3, 0, 60]],
    count: 4,
    pages: 1,
  },
  goodPoint: 100,
  solitaire: ['偉賓小雞', 'Fenge小雞', '匡瑋小雞'],
  power: 1.3,
  group: '', // 連動群組ID
  startDateTime: '2022-03-15 15:47:04',
  intervalMinute: 30,
  totalMinute: 60,
  endDateTime: '2022-03-15 23:47:04',
  nextEventSec: 1073,
  finishSec: 2084,
};

export const state = {
  tag: 'outsideSearch',
  jwt: localStorage.getItem('t'),
  user,
  card,
  followUsers,
  followGroups,
  account: '',
  password: '',
  crawlers: [],
  findcompany_business_card_img: '',
  findcompany_business_card_id: 0,
  findcompany_business_card_name: '',
  searchGroupsMembers: [],
  settingItem: [],
  ADCounter,
  ADRecords,
  RankingRecords,
  RankingClicks,
  RankingClicksAll,
  BggQuests, // 挖礦任務
  BggRanks: '', // 網紅人氣秀排名
  ADEmail: '',
  ADEmailSend: false,
  IsClickADAvailable: true, // 是否可點擊(文章)廣告
  StorageValue: '',
  Bgg_OutdoorInfoIsLoaded: false, // 拔古雞外出資訊是否讀取
  ...helper.state,
};

export const mutations = {
  updateField,
  ...helper.mutations,
};

export const getters = {
  cardId(state) {
    return state.card ? state.card.id : -1;
  },
  followStr: (state) => (id) => (state.followUsers.indexOf(id) > -1 ? '已追蹤' : '去追蹤'),
  followGroupStr: (state) => (id) => (state.followGroups.indexOf(id) > -1 ? '已追蹤' : '去追蹤'),
  getField,
};

// saveCard
export const actions = {
  setting: ({ state, commit }, { key, val }) => {
    api.account.setting({ key, val }).then((rep) => {
      const jwtData = jwtDecode(rep.data.body.t);
      localStorage.setItem('u', JSON.stringify(jwtData.data));
      localStorage.setItem('t', rep.data.body.t);
      const user = jwtData.data;
      commit('setValue', { key: 'user', val: user });
      commit('setValue', { key: 'card', val: user.active });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  login: ({ state, commit }) => {
    api.account.login({ mail: state.account, password: state.password }).then((rep) => {
      const jwtData = jwtDecode(rep.data.body.t);
      localStorage.setItem('u', JSON.stringify(jwtData.data));
      localStorage.setItem('t', rep.data.body.t);
      const user = jwtData.data;
      commit('setValue', { key: 'user', val: user });
      commit('setValue', { key: 'card', val: user.active });
      router.push('/');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  saveCard: ({ state, commit }) => {
    if (!state.findcompany_business_card_name.trim()) return;
    api.account.saveCard({
      findcompany_business_card_id: state.findcompany_business_card_id,
      findcompany_business_card_name: state.findcompany_business_card_name,
      findcompany_business_card_img: state.findcompany_business_card_img,
    }).then((rep) => {
      const jwtData = jwtDecode(rep.data.body.t);
      localStorage.setItem('u', JSON.stringify(jwtData.data));
      localStorage.setItem('t', rep.data.body.t);
      const user = jwtData.data;
      commit('setValue', { key: 'user', val: user });
      commit('setValue', { key: 'card', val: user.active });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  setCardActive: ({ state, commit }, { id }) => {
    api.account.setCard({
      findcompany_business_card_id: id,
    }).then((rep) => {
      const jwtData = jwtDecode(rep.data.body.t);
      localStorage.setItem('u', JSON.stringify(jwtData.data));
      localStorage.setItem('t', rep.data.body.t);
      const user = jwtData.data;
      commit('setValue', { key: 'user', val: user });
      commit('setValue', { key: 'card', val: user.active });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  followAdmin: ({ state, commit }) => {
    if (!state.jwt) {
      router.push('/');
      return;
    }
    const userId = process.env.VUE_APP_ADMIN_CARD_ID;
    const flag = state.followUsers.indexOf(userId) > -1;
    if (!flag) {
      api.account.followUser({ cardId: userId, flag: true }).then((rep) => {
        const jwtData = jwtDecode(rep.data.body.jwt);
        localStorage.setItem('t', rep.data.body.jwt);
        localStorage.setItem('u', JSON.stringify(jwtData.data));
        commit('setValue', { key: 'followUsers', val: rep.data.body.users });
        commit('setValue', {
          key: 'user',
          val: localStorage.getItem('u') ? JSON.parse(localStorage.getItem('u')) : null,
        });
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  followUser: ({ state, commit }, { userId }) => {
    const flag = state.followUsers.indexOf(userId) > -1;
    api.account.followUser({ cardId: userId, flag: !flag }).then((rep) => {
      const jwtData = jwtDecode(rep.data.body.jwt);
      localStorage.setItem('t', rep.data.body.jwt);
      localStorage.setItem('u', JSON.stringify(jwtData.data));
      commit('setValue', { key: 'followUsers', val: rep.data.body.users });
      commit('setValue', {
        key: 'user',
        val: localStorage.getItem('u') ? JSON.parse(localStorage.getItem('u')) : null,
      });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  followGroup: ({ state, commit }, { groupId }) => {
    const flag = state.followGroups.indexOf(groupId) > -1;
    api.account.followGroup({ groupId, flag: !flag }).then((rep) => {
      const jwtData = jwtDecode(rep.data.body.jwt);
      localStorage.setItem('t', rep.data.body.jwt);
      localStorage.setItem('u', JSON.stringify(jwtData.data));
      commit('setValue', { key: 'followGroups', val: rep.data.body.groups });
      commit('setValue', {
        key: 'user',
        val: localStorage.getItem('u') ? JSON.parse(localStorage.getItem('u')) : null,
      });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  addFriend: ({ state, commit }, { code }) => {
    if (!code) return;
    api.account.addFriend({ code }).then((rep) => {
      const jwtData = jwtDecode(rep.data.body.t);
      localStorage.setItem('u', JSON.stringify(jwtData.data));
      localStorage.setItem('t', rep.data.body.t);
      const user = jwtData.data;
      commit('setValue', { key: 'user', val: user });
      commit('setValue', { key: 'card', val: user.active });
      // eslint-disable-next-line no-alert
      window.alert('增加成功');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 更正使用者姓名,圖片
  UpdateProfile: ({ state, commit, dispatch }, { UserID, NewName, NewImage }) => {
    // {"name":"sakilu","image":""}
    // console.log(`NewImage:${JSON.stringify(NewImage)}`);
    const data = { name: NewName, image: NewImage };
    if (localStorage.getItem('u')) {
      api.account.updateProfile(data).then((rep) => {
        // const jwtData = jwtDecode(rep.data.body.t);
        // localStorage.setItem('u', JSON.stringify(jwtData.data));
        // localStorage.setItem('t', rep.data.body.t);
        // const user = jwtData.data;
        // commit('setValue', { key: 'user', val: user });
        // commit('setValue', { key: 'card', val: user.active });
        window.alert('變更成功!'); // eslint-disable-line no-alert
        dispatch('setCardActive', { id: UserID });
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
        // console.log(`UpdateProfile error:${JSON.stringify(error)}`);
        // window.alert('變更失敗!'); // eslint-disable-line no-alert
      });
    }
  },
  // 開啟/關閉 廣告(收益)
  Advertisement: ({ state, commit, dispatch }, value) => {
    if (localStorage.getItem('u')) {
      api.account.advertisement(value).then((rep) => {
        // window.alert('變更成功!'); // eslint-disable-line no-alert
        dispatch('CounterAD');
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
        // window.alert('變更失敗!'); // eslint-disable-line no-alert
      });
    }
  },
  // 點擊廣告
  ClickAD: ({ state, commit, dispatch }, postid) => {
    api.account.clickAd(postid).then((rep) => {
      if (rep.data.body.HourError) {
        window.alert('點擊間隔時間尚未超過，稍待2分鐘左右!'); // eslint-disable-line no-alert
      } else if (rep.data.body.DailyError) {
        window.alert('您已超過每天點擊上限!'); // eslint-disable-line no-alert
      } else {
        window.alert(`感謝廣告點擊！您累計今日成功點擊次數：${rep.data.body.DailyClickedCount}`); // eslint-disable-line no-alert
        Vue.$gtag.event('廣告', {
          event_category: '點擊廣告成功',
          event_label: state.card.id,
          value: postid,
        });
      }
      Vue.$gtag.event('廣告', {
        event_category: '點擊廣告',
        event_label: state.card.id,
        value: postid,
      });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      if (Object.prototype.hasOwnProperty.call(error.response.data, 'error_msg')) {
        window.alert(error.response.data.error_msg); // eslint-disable-line no-alert
      }
    });
  },
  // 是否可點擊文章廣告
  ClickADAvailable: ({ state, commit, dispatch }, postid) => {
    commit('setValue', { key: 'IsClickADAvailable', val: true });
    api.account.clickAdAvailable(postid).then((rep) => {
      commit('setValue', { key: 'IsClickADAvailable', val: rep.data.body.available });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 點擊記錄
  CounterAD: ({ state, commit, dispatch }) => {
    if (localStorage.getItem('u')) {
      api.account.counterAd('').then((rep) => {
        commit('setValue', { key: 'ADCounter', val: rep.data.body.data });
        // rep.data.body.data.open 是否開啟收益 true
        // rep.data.body.data.resetDate 計算起始日(不包含起始當天) "2021-09-05"
        // rep.data.body.data.siteClicks 全站點擊
        // rep.data.body.data.personal 個人點擊
        // rep.data.body.data.tags (陣列: key為個人tag名稱 value為點擊數.)
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // 帳務記錄
  RecordsAD: ({ state, commit, dispatch }, { Page, PerPage }) => {
    if (localStorage.getItem('u')) {
      api.account.recordsAd(Page, PerPage).then((rep) => {
        if (rep.data.body.records.pages > 0) { // 有記錄才取代
          commit('setValue', { key: 'ADRecords', val: rep.data.body });
        }
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // 帳務出帳 {"type":1,"amount":50,"userId":"","mail":"","code":""}
  /* eslint-disable */
  TransferAD: ({ state, commit, dispatch }, { Type, Amount, UserID, Mail, Code }) => {
  /* eslint-enable */
    if (localStorage.getItem('u')) {
      /* eslint-disable */
      api.account.transferAd({ type: Type, amount: Amount, userId: UserID, mail: Mail, code: Code }).then((rep) => {
      /* eslint-enable */
        window.alert('申請成功!'); // eslint-disable-line no-alert
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
        if (Object.prototype.hasOwnProperty.call(error.response.data, 'error_msg')) {
          window.alert(error.response.data.error_msg); // eslint-disable-line no-alert
        }
      });
    }
  },
  // 好友排行榜-最近互動
  GetRankingRecords: ({ state, commit, dispatch }, { Page, PerPage, All }) => {
    if (localStorage.getItem('u')) {
      api.account.rankingRecords(Page, PerPage, All).then((rep) => {
        if (rep.data.body.data.pages > 0) { // 有記錄才取代
          commit('setValue', { key: 'RankingRecords', val: rep.data.body });
        }
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // 好友排行榜-點擊
  GetRankingClicks: ({ state, commit, dispatch }, { Page, PerPage, All }) => {
    if (localStorage.getItem('u')) {
      api.account.rankingClicks(Page, PerPage, All).then((rep) => {
        if (rep.data.body.data.pages > 0) { // 有記錄才取代
          commit('setValue', { key: 'RankingClicks', val: rep.data.body });
        }
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // 全站排行榜-點擊
  GetRankingClicksAll: ({ state, commit, dispatch }, { Page, PerPage, All }) => {
    api.account.rankingClicksAll(Page, PerPage, All).then((rep) => {
      if (rep.data.body.data.pages > 0) { // 有記錄才取代
        commit('setValue', { key: 'RankingClicksAll', val: rep.data.body });
      }
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // Bgg探險任務資料
  GetBggQuests: ({ state, commit, dispatch }) => {
    if (localStorage.getItem('u')) {
      return api.account.BggQuests('').then((rep) => {
        if (rep.data.body.quests.pages > 0) { // 有記錄才取代
          commit('setValue', { key: 'BggQuests', val: rep.data.body });
        }
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }

    return null;
  },
  // Bgg開始探險
  SendBggStartQuest: ({ state, commit }, data) => {
    if (localStorage.getItem('u')) {
      api.account.BggStartQuest(data).then((rep) => {
        if (rep.data.body.quests.pages > 0) { // 有記錄才取代
          commit('setValue', { key: 'BggQuests', val: rep.data.body });
        }
        Vue.$gtag.event('挖礦', {
          event_category: '挖礦開始',
          event_label: state.card.id,
          value: data.id,
        });
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // Bgg任務按讚
  SendBggGoodQuest: ({ state, commit }, data) => {
    if (localStorage.getItem('u')) {
      api.account.BggGoodQuest(data).then((rep) => {
        if (rep.data.body.quests.pages > 0) { // 有記錄才取代
          commit('setValue', { key: 'BggQuests', val: rep.data.body });
        }
        Vue.$gtag.event('挖礦', {
          event_category: '挖礦任務按讚',
          event_label: state.card.id,
          value: data.eventId,
        });
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // Bgg完成任務領獎賞
  SendBggCompleteQuest: ({ state, commit }, groupID) => {
    if (localStorage.getItem('u')) {
      const data = { groupId: groupID };
      api.account.BggCompleteQuest(data).then((rep) => {
        if (rep.data.body.quests.pages > 0) { // 有記錄才取代
          commit('setValue', { key: 'BggQuests', val: rep.data.body });
        }
        Vue.$gtag.event('挖礦', {
          event_category: '挖礦完成任務領獎賞',
          event_label: state.card.id,
          value: groupID,
        });
      }).catch((error) => {
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // 取得Bgg網紅排名(當日)
  GetBggRank: ({ state, commit, dispatch }, strDate) => {
    const data = { date: strDate };
    api.account.BggRank(data).then((rep) => {
      commit('setValue', { key: 'BggRanks', val: rep.data.body });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 收款E-Mail
  EmailCode: ({ state, commit, dispatch }, { Email }) => {
    if (localStorage.getItem('u')) {
      api.account.emailCode({ mail: Email }).then((rep) => {
        commit('setValue', { key: 'ADEmail', val: Email });
        commit('setValue', { key: 'ADEmailSend', val: true });
      }).catch((error) => {
        commit('setValue', { key: 'ADEmailSend', val: false });
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // 儲存資料
  StorageSave: ({ state, commit, dispatch }, data) => {
    if (localStorage.getItem('u')) {
      api.account.storageSave({ key: data.key, value: data.value }).then((rep) => {
        // console.log('StorageSave Success!');
      }).catch((error) => {
        // console.log('StorageSave Fail!');
        commit('ui/ajaxAlertError', { error }, { root: true });
      });
    }
  },
  // 讀取資料
  StorageLoad: ({ state, commit, dispatch }, keyname) => {
    commit('setValue', { key: 'StorageValue', val: '' });
    return api.account.storageLoad({ key: keyname }).then((rep) => {
      commit('setValue', { key: 'StorageValue', val: rep.data.body.v });
      // console.log(`StorageLoad Success! key:${keyname},v:${rep.data.body.v}`);
    }).catch((error) => {
      // console.log('StorageLoad Fail!');
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  ...helper.actions,
};
