import ajax from 'axios';
import EXIF from 'exif-js';

import vConsole from 'vconsole';
import { setTimeout } from 'timers';

import router from '../../../router';

const type = {
  categoryOption: {
    l1: '',
    l2: '',
    l3: '',
    l4: '',
    l5: '',
  },
};

// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
const { Buffer } = require('buffer/');

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line
  new vConsole();
}
const errHandel = (context, error) => {
  if (error.response.status >= 500) {
    context.commit('setValue', { key: 'msg', val: error.response.data });
    return;
  }
  if (error.response.status === 404) {
    context.dispatch('logout');
    return;
  }
  if (Array.isArray(error.response.data.error_msg)) {
    context.commit('setValue', { key: 'msg', val: error.response.data.error_msg.join('<br>') });
  } else {
    context.commit('setValue', { key: 'msg', val: error.response.data.error_msg });
  }
};

const getters = {};

// 自定義Tag時間軸---結構[n] 0:我的動態軸 1:官方推薦軸 2~n:訂閱軸
const TagListStruct = {
  list: [], // 文章列表 -- [n][list]
  page: [], // 目前頁數(int) -- [n][page]
  tags: [], // 搜尋Tags -- [n][tags]
  name: [], // 自定義名稱(string) -- [n][name]
  condition: [], // 搜尋Tags條件 -- [n][condition] 0:AND 1:OR
  nowselectTags: [], // 目前選定的Tag組合(PO文用)
  isAnd: [], // 是否完全符合(boolean) -- [n][boolean]
  previewlist: [], // 預覽結果列表 -- [n][list]
  previewLastSelectTag: [], // 預覽的選擇Tag [n][tag]
  previewTagsList: [], // 預覽Tag選擇的歷程(最多15個, 超過就取代之前)
  nowPreviewIdx: 0, // 目前預覽到的Tag順位
};

const state = {
  buttonKey: 1,
  order: '',
  page: 1,
  list: [],
  preview: {},
  postUrlPreview: '',
  MyRelatedTags: '', // 相關聯Tag名稱列表(取得Tag同好判定用)
  taglist: { ...TagListStruct }, // 自定義Tag時間軸
  selectedTagIndex: 0, // 目前作用時間軸 -1:預覽軸 0 ~ N
  empty: false,
  url: '',
  searchStr: '',
  searchCity: '',
  searchDistrict: '',
  selectedCategory: null,
  tags: [],
  canVideoScroll: true, // for video
  videoNumber: -1, // for video
  videoList: [], // for video
  videoBanList: [], // for video
  UserTagIcons: [], // 暫存使用者頭像列表
  showChatRoom: false, // ChatRoom Open/Close
  showAdultStage: -1, // Adult Block: -1:default 0:login block,1: allow,2:deny
  showAddTaglistHint: 0, // 0: need hint 1: already know
  showAddPostHint: 0, // 0: need hint 1: already know
  showRegisterHint: 1, // 0: Open 1: Close
  language: 0, // 0:中文 1:英文 2:日文
  taglistSortKind: 0, // Tag軸搜尋類別 0:時間 1:人氣 2:點讚 3:留言
  showTutorial: localStorage.getItem('Tutorial'),
  showTutorialM: localStorage.getItem('TutorialM'),
  showAdvertiseHint: 0, // 0: Open 1: Close 2: WaitLoginComplete
  bggMode: false, // 拔古雞圓宇宙模式顯示
  showGuide: 0, // PO文導引教學 0:發文 1:點自己文章廣告 2:開帳戶頁面 3:顯示開啟帳戶說明Hint
  showShareStepHint: 1, // 顯示好分享步驟教學 0:顯示 >0:暫不顯示
  showClickADTutorial: 0, // 顯示加強點擊廣告提示 0:關閉 1:顯示教學(點擊有算分享積分) 2:加強文字
  showShareCountUI: 0, // 顯示分享帳戶UI 0:關閉 1:顯示
  showSharePostInputMsgUI: 0, // 顯示酷分享連結輸入訊息UI 0:關閉 >0:顯示(文章ID)
  IsShareClickAD: false, // 是否點擊分享贊助廣告
  showShareSelectTagsUI: 0, // 顯示分享選擇TagUI 0:關閉 1:顯示瀏覽用 2:顯示PO文選擇用
  showSendMsgLoginAskUI: 0, // 顯示需登入才能傳訊息詢問UI 0:關閉 >0:顯示
};
function base64ToArrayBuffer(base64) {
  // eslint-disable-next-line no-param-reassign
  base64 = base64.replace(/^data:([^;]+);base64,/gmi, '');
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

const actions = {
  logout: () => {
    window.localStorage.clear();
    router.push('/login');
    window.location.reload();
  },
  logout2: () => {
    window.localStorage.clear();
    router.push('/');
    window.location.reload();
  },
  listSearch: (context) => {
    context.commit('resetList');
    context.dispatch('init');
  },
  getBase64ImageFromUrl(url) {
    return ajax.get(url, { responseType: 'arraybuffer' }).then((response) => Buffer.from(response.data, 'binary').toString('base64'));
  },
  resize({ commit }, {
    event, field, maxWidth = 1024, maxHeight = 768, fixed = false,
  }) {
    let exif;
    const input = event.target;
    if (input.files && input.files[0]) {
      const img = window.document.createElement('img');
      const canvas = window.document.createElement('canvas');
      const reader = new FileReader();
      reader.onload = (e) => {
        exif = EXIF.readFromBinaryFile(base64ToArrayBuffer(e.target.result));
        img.src = e.target.result;
      };
      img.addEventListener('load', () => {
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        let { width } = img;
        let { height } = img;
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
        if (fixed) {
          width = maxWidth;
          height = maxHeight;
        }
        if (exif.Orientation > 4 && exif.Orientation < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        ctx = canvas.getContext('2d');
        switch (exif.Orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            break;
        }
        ctx.drawImage(img, 0, 0, width, height);
        commit('setValue', { key: field, val: canvas.toDataURL('image/jpge') });
      });
      reader.readAsDataURL(input.files[0]);
    }
  },
  loadMore: ({ state, commit, dispatch }) => {
    commit('setValue', { key: 'page', val: state.page + 1 });
    dispatch('init');
  },
  // Yt Video fuc
  addList: ({ commit }, { data }) => {
    commit('pushVideoList', { val: data });
  },
  addBanList: ({ state, commit }, { count }) => {
    if (state.videoBanList.includes(Number(count)) === false) commit('pushBanListVal', { val: Number(count) });
  },
  removeBanList: ({ state, commit }, { count }) => {
    if (state.videoBanList.includes(Number(count))) commit('removeBanListVal', { val: Number(count) });
  },
  playNuberOfVideo: ({ state, commit }, { count }) => {
    state.videoList.forEach(async (element) => {
      if (Number(element[0]) === Number(count) && state.videoBanList.includes(Number(count)) === false) {
        commit('setVideoNumber', { val: Number(element[0]) });
        element[1].playVideo();
      }
    });
  },
  pauseOthers: ({ state, commit, dispatch }, { count }) => {
    commit('setVideoNumber', { val: Number(count) });
    state.videoList.forEach(async (element) => {
      if (Number(element[0]) !== Number(count)) {
        element[1].pauseVideo();
      }
    });
    dispatch('setScrollBanTime');
  },
  pauseAllVideos: ({ state, commit }) => {
    commit('setVideoNumber', { val: -1 });
    state.videoList.forEach((element) => {
      if (element[1]) element[1].pauseVideo();
    });
  },
  toggleCanVideoScroll: ({ commit }) => {
    commit('toggleCanVideoScroll');
  },
  setScrollBanTime: ({ state }) => {
    state.canVideoScroll = false;
    setTimeout(() => {
      state.canVideoScroll = true;
    }, 500);
  },
  DoVideoReset: ({ commit }) => {
    commit('videoVarReset');
  },
  DoAdultStage: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showAdultStage', val: count });
  },
  DoAddTaglistHint: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showAddTaglistHint', val: count });
  },
  DoAddPostHint: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showAddPostHint', val: count });
  },
  DoAddRegisterHint: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showRegisterHint', val: count });
  },
  DoTutorial: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showTutorial', val: count });
  },
  DoTutorialM: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showTutorialM', val: count });
  },
  DoBggMode: ({ state, commit }, value) => {
    commit('setValue', { key: 'bggMode', val: value });
  },
  DoGuide: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showGuide', val: count });
  },
  DoShareStepHint: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showShareStepHint', val: count });
  },
  DoClickADTutorial: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showClickADTutorial', val: count });
  },
  DoShareCount: ({ state, commit }, value) => {
    commit('setValue', { key: 'showShareCountUI', val: value });
  },
  DoSharePostInputMsg: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showSharePostInputMsgUI', val: count });
  },
  DoIsShareClickAD: ({ state, commit }, value) => {
    commit('setValue', { key: 'IsShareClickAD', val: value });
  },
  DoShareSelectTags: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showShareSelectTagsUI', val: count });
  },
  DoSendMsgLoginAsk: ({ state, commit }, { count }) => {
    commit('setValue', { key: 'showSendMsgLoginAskUI', val: count });
  },
};

const mutations = {
  setValue: (state, {key, val}) => { // eslint-disable-line
    state[key] = val;
    // console.log(`setValue:${key} ${val}`);
  },
  setList: (state, {list}) => { // eslint-disable-line
    state.list = [...state.list].concat(list);
    state.empty = list.length < 7;
  },
  setPreview: (state, {preview}) => { // eslint-disable-line
    state.preview = preview;
  },
  setPostUrlPreview: (state, {key, val}) => { // eslint-disable-line
    if (!state.postUrlPreview) {
      state.postUrlPreview = new Map();
    }
    state.postUrlPreview.set(key, val);
  },
  setMyRelatedTags: (state, {key, val}) => { // eslint-disable-line
    if (!state.MyRelatedTags) {
      state.MyRelatedTags = new Map();
    }
    state.MyRelatedTags.set(key, val);
  },
  setTagList: (state, {list, idx}) => { // eslint-disable-line
    if (idx === -1) { // 預覽
      // console.log(`previewlist data:${JSON.stringify(list)}`);
      if (!state.taglist.previewlist[0]) {
        state.taglist.previewlist.splice(0, 1, [...list]);
      } else {
        state.taglist.previewlist.splice(0, 1, [...state.taglist.previewlist[0]].concat(list));
      }
      return;
    }

    if (!state.taglist.list[0]) {
      state.taglist.list.splice(0, 1, []);
      // console.log('state.taglist.list.splice(0, 1, [])');
    }

    // console.log(`setTagList --- tagIndex:${idx} list:${JSON.stringify(list)}`);

    if (!state.taglist.list[idx]) {
      state.taglist.list[idx] = [];
      state.taglist.list.splice(idx, 1, [...list]);
      state.taglist.name[idx] = '';
    } else {
      state.taglist.list.splice(idx, 1, [...state.taglist.list[idx]].concat(list));
    }
  },
  // 刪除單一軸
  delTagList: (state, { val }) => {
    if (state.taglist.list[val]) {
      state.taglist.list.splice(val, 1);
      state.taglist.page.splice(val, 1);
      state.taglist.tags.splice(val, 1);
      state.taglist.name.splice(val, 1);
      state.taglist.condition.splice(val, 1);
      state.taglist.nowselectTags.splice(val, 1);
    }
  },
  // 清空Tag軸清單
  cleanTagList: (state, { val }) => {
    if (state.taglist.list[val]) {
      state.taglist.list[val] = [];
      state.taglist.page[val] = 1;
    }
  },
  // 清空預覽Tag軸
  cleanPreviewTagList: (state) => {
    if (state.taglist.previewlist[0]) {
      state.taglist.previewlist.splice(0, 1, []);
    }
  },
  setTagListPage: (state, { val, idx }) => {
    if (!state.taglist.page[idx]) {
      state.taglist.page[idx] = 1;
    }
    state.taglist.page.splice(idx, 1, val);
  },
  addTagListPage: (state, { val, idx }) => {
    if (!state.taglist.page[idx]) {
      state.taglist.page[idx] = 1;
    }
    state.taglist.page.splice(idx, 1, state.taglist.page[idx] + val);
  },
  // 設定Tag軸的 tag組合
  setTagList_Tags: (state, { val, idx }) => {
    if (!state.taglist.tags[idx]) {
      state.taglist.tags[idx] = [];
    }
    state.taglist.tags.splice(idx, 1, val);
  },
  // 設定Tag軸的 自訂主題名稱
  setTagList_Name: (state, { val, idx }) => {
    if (!state.taglist.name[idx]) {
      state.taglist.name[idx] = '我的訂閱主題';
    }
    state.taglist.name.splice(idx, 1, val);
  },
  setTagList_Condition: (state, { val, idx }) => {
    if (!state.taglist.condition[idx]) {
      state.taglist.condition[idx] = 1; // 0:AND 1:OR 2:個人動態牆
    }
    state.taglist.condition.splice(idx, 1, val);
  },
  // 設定預覽軸所選擇的Tag
  setTagList_LastSelectTag: (state, { val, idx }) => {
    if (!state.taglist.previewLastSelectTag[idx]) {
      state.taglist.previewLastSelectTag[idx] = [];
    }
    state.taglist.previewLastSelectTag.splice(idx, 1, val);
  },
  resetClickOnce: (state) => {
    state.buttonKey += 1;
    state.canVideoScroll = true; // for video
    state.videoList = []; // for video
    state.videoBanList = []; // for video
    state.playNowNb = 0; // for video
  },
  reset: (state) => {
    state.page = 1;
    state.empty = false;
    state.searchStr = '';
    state.list = [];
    state.searchCity = '';
    state.searchDistrict = '';
    state.selectedCategory = null;
    state.canVideoScroll = true; // for video
    state.videoList = []; // for video
    state.videoBanList = []; // for video
    state.playNowNb = 0; // for video
  },
  resetList: (state) => {
    state.page = 1;
    state.empty = false;
    state.list = [];
    state.canVideoScroll = true; // for video
    state.videoList = []; // for video
    state.videoBanList = []; // for video
    state.playNowNb = 0; // for video
  },
  videoVarReset: (state) => { // for video
    state.canVideoScroll = true;
    state.videoList = [];
    state.videoBanList = [];
    state.playNowNb = 0;
  },
  pushVideoList: (state, { val }) => { // for video
    state.videoList.push(val);
  },
  setVideoNumber: (state, { val }) => { // for video
    state.videoNumber = val;
  },
  pushBanListVal: (state, { val }) => { // for video
    state.videoBanList.push(val);
  },
  removeBanListVal: (state, { val }) => { // for video
    state.videoBanList[state.videoBanList.indexOf(val)] = -1;
  },
  toggleCanVideoScroll: (state) => { // for video
    state.canVideoScroll = !state.canVideoScroll;
  },
  resetSelectTags: (state) => {
    state.taglist.nowselectTags = [];
  },
  // 將選擇Tag放入選取清單中
  pushSelectTags: (state, { val }) => {
    if (state.taglist.nowselectTags.length >= 7) { // Po文Tag數量上限
      return;
    }
    let find = false;
    state.taglist.nowselectTags.forEach((tag) => {
      // 判斷是否重複
      if ((tag[1] === val[1]) && (tag[4] === val[4])) {
        find = true;
      }
    });

    if (!find) {
      state.taglist.nowselectTags.splice(state.taglist.nowselectTags.length, 1, val);
    }
  },
  removeSelectTags: (state, { val }) => {
    const idx = state.taglist.nowselectTags.indexOf(val);
    if (idx > -1) {
      state.taglist.nowselectTags.splice(idx, 1);
    }
  },
  // 放入預覽tag清單
  pushPreviewTagsInList: (state, { val }) => {
    state.taglist.previewTagsList.push(val);
    if (state.taglist.previewTagsList.length > 15) {
      state.taglist.previewTagsList.splice(0, 1);
    }
    state.taglist.nowPreviewIdx = state.taglist.previewTagsList.length - 1;
  },
  clearPreviewTagsInList: (state) => {
    state.taglist.nowPreviewIdx = 0;
    state.taglist.previewTagsList = [];
  },
  // 往前移動一個預覽目標
  prevPreviewTags: (state) => {
    state.taglist.nowPreviewIdx -= 1;
    if (state.taglist.nowPreviewIdx < 0) {
      state.taglist.nowPreviewIdx = 0;
    }
    state.taglist.previewLastSelectTag.splice(0, 1, state.taglist.previewTagsList[state.taglist.nowPreviewIdx]);
  },
  // 往後移動一個預覽目標
  nextPreviewTags: (state) => {
    state.taglist.nowPreviewIdx += 1;
    if (state.taglist.nowPreviewIdx > state.taglist.previewTagsList.length - 1) {
      state.taglist.nowPreviewIdx = state.taglist.previewTagsList.length - 1;
    }
    state.taglist.previewLastSelectTag.splice(0, 1, state.taglist.previewTagsList[state.taglist.nowPreviewIdx]);
  },
  // 放入使用者TagIcon清單
  pushUserTagIcons: (state, { val }) => {
    let find = false;
    if (state.UserTagIcons) {
      state.UserTagIcons.forEach((icon) => {
        // 判斷是否重複
        if ((icon[0] === val[0])) {
          find = true;
        }
      });
    }

    if (!find) {
      state.UserTagIcons.splice(state.UserTagIcons.length, 1, val);
    }
  },
  resetUserTagIcons: (state) => {
    state.UserTagIcons = [];
  },
};

// eslint-disable-next-line func-names
const getRedirectPost = function (data) {
  // eslint-disable-next-line no-prototype-builtins
  if (!data || !data.hasOwnProperty('type')) {
    return window.location.href;
  }
  if (data.type === 'image') {
    return `/eachPost/${data.postType}/${data.postId}`;
  } if (data.type === 'videos') {
    return `/eachVideoPost/${data.postType}/${data.postId}`;
  } if (data.type === 'word') {
    return `/eachWordPost/${data.postType}/${data.postId}`;
  }
  return window.location.href;
};

export default {
  errHandel,
  actions,
  state,
  mutations,
  getters,
  type,
  getRedirectPost,
};
