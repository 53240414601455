<template lang="pug">
  .dynamicWall
    .row.dynamicWallContent.flex-wrap
      .col-md-12(v-for="item in tt")
        .cardBox.d-flex.flex-column.align-items-center(v-if="item.type === 'word'")
          .cardBox-body(@click="redirectPost({data:item})")
            p.mb-0.text-justify.font-weight-bold(v-html="item.content")
            //-div.readedNum
              img(src="../assets/home/eye.png" alt="eye")
              span.font-weight-bold 0
        .cardBox.d-flex.flex-column.align-items-center(v-if="item.type === 'videos' && item.postType === 'inHot'")
          .cardBox-body(:class="{'isLongHeight' : item.videos && item.videos[0] && (item.videos[0].includes('<blockquote') || item.videos[0].includes('www.tiktok.com'))}")
            p.mb-0.text-justify(@click="redirectPost({data:item})")
              <div v-if="item.videos && item.videos[0] && item.videos[0].length > 0 && item.videos[0].includes('</')"></div>
                IgEmbed(v-if="item.videos[0].includes('www.instagram.com')" :code="item.videos[0]")
                TwitterEmbed(v-else-if="item.videos[0].includes('twitter.com')" :code="item.videos[0]")
                div(v-else-if="item.videos[0].includes('youtube.com')" v-html="item.videos[0]")
                <video e-else width="100%" height="100%" controls>
                  <source :src="item.videos[0]">
                </video>
        .cardBox.d-flex.flex-column.align-items-center(v-if="item.type === 'videos' && item.postType === 'outHot'")
          .cardBox-body(:class="{'isLongHeight' : item.videos && item.videos[0] && (item.videos[0].includes('<blockquote') || item.videos[0].includes('www.tiktok.com'))}")
            IgEmbed(v-if="item.videos[0].includes('www.instagram.com')" :code="item.videos[0]")
            TwitterEmbed(v-else-if="item.videos[0].includes('twitter.com')" :code="item.videos[0]")
            div(v-else-if="item.videos[0].includes('youtube.com')" v-html="item.videos[0]")
            //- p.mb-0.text-justify(v-html="item.embed")
        .cardBox.d-flex.flex-column.align-items-center(v-if="item.type === 'image'")
          .cardBox-head.row.justify-content-center(@click="redirectPost({data:item})")
            img(:src="item.images[0]" alt="main")
          .cardBox-footer(@click="redirectPost({data:item})")
            span.font-weight-bold(v-html="item.content")
    div.dotBox(v-if="!empty" @click="loadMore")
      span.dot
      span.dot.ml-1
      span.dot.ml-1
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import IgEmbed from './videoEmbed/IgEmbed.vue';
import TwitterEmbed from './videoEmbed/TwitterEmbed.vue';

export default {
  components: {
    IgEmbed,
    TwitterEmbed,
  },
  data() {
    return {
      orderBox: false,
      embedW: '310',
      embedH: '185',
    };
  },
  computed: {
    ...mapFields('history', ['list', 'empty']),
    resizedEmbedList() {
      this.$store.state.history.list.forEach((element, nb) => { // 改變embed寬高
        if (['embed'] in element === true) {
          if (element.embed.includes('www.youtube.com') || element.embed.includes('www.facebook.com')) {
            if (element.embed.includes('&width=') === true) {
              const fbW = element.embed.slice(element.embed.indexOf('&width=') + 7, element.embed.length).indexOf('&') + element.embed.indexOf('&width=') + 7;
              const fbOutW = element.embed.slice(element.embed.indexOf('&width='), fbW);
              this.$store.state.history.list[nb].embed = element.embed.replace(fbOutW, `&width=${this.embedW}`);
            }
            if (element.embed.includes('&height=') === true) {
              const fbH = element.embed.slice(element.embed.indexOf('&height=') + 8, element.embed.length).indexOf('&') + element.embed.indexOf('&height=') + 8;
              const fbOutH = element.embed.slice(element.embed.indexOf('&height='), fbH);
              this.$store.state.history.list[nb].embed = element.embed.replace(fbOutH, `&height=${this.embedH}`);
            }
            const st1End = element.embed.slice(element.embed.indexOf('width="') + 7, element.embed.length).indexOf('"') + element.embed.indexOf('width="') + 8;
            const st1 = element.embed.slice(element.embed.indexOf('width="'), st1End);
            const st2End = element.embed.slice(element.embed.indexOf('height="') + 8, element.embed.length).indexOf('"') + element.embed.indexOf('height="') + 9;
            const st2 = element.embed.slice(element.embed.indexOf('height="'), st2End);
            this.$store.state.history.list[nb].embed = element.embed.replace(st1, `width="${this.embedW}"`);
            this.$store.state.history.list[nb].embed = element.embed.replace(st2, `height="${this.embedH}"`);
          }
        }
      });
      return this.$store.state.history.list;
    },
    tt() {
      this.$store.state.history.list.forEach((element, nb) => { // 改變embed寬高
        if (['videos'] in element === true && element.videos[0] && element.videos.length > 0 && element.videos[0].length > 0) {
          if (element.videos[0].includes('www.youtube.com') === true || element.videos[0].includes('www.facebook.com') === true) {
            if (element.videos[0].includes('&width=') === true) {
              const fbW = element.videos[0].slice(element.videos[0].indexOf('&width=') + 7, element.videos[0].length).indexOf('&') + element.videos[0].indexOf('&width=') + 7;
              const fbOutW = element.videos[0].slice(element.videos[0].indexOf('&width='), fbW);
              this.$store.state.history.list[nb].videos[0] = element.videos[0].replace(fbOutW, `&width=${this.embedW}`);
            }
            if (element.videos[0].includes('&height=') === true) {
              const fbH = element.videos[0].slice(element.videos[0].indexOf('&height=') + 8, element.videos[0].length).indexOf('&') + element.videos[0].indexOf('&height=') + 8;
              const fbOutH = element.videos[0].slice(element.videos[0].indexOf('&height='), fbH);
              this.$store.state.history.list[nb].videos[0] = element.videos[0].replace(fbOutH, `&height=${this.embedH}`);
            }
            const st1End = element.videos[0].slice(element.videos[0].indexOf('width="') + 7, element.videos[0].length).indexOf('"') + element.videos[0].indexOf('width="') + 8;
            const st1 = element.videos[0].slice(element.videos[0].indexOf('width="'), st1End);
            const st2End = element.videos[0].slice(element.videos[0].indexOf('height="') + 8, element.videos[0].length).indexOf('"') + element.videos[0].indexOf('height="') + 9;
            const st2 = element.videos[0].slice(element.videos[0].indexOf('height="'), st2End);
            this.$store.state.history.list[nb].videos[0] = element.videos[0].replace(st1, `width="${this.embedW}"`);
            this.$store.state.history.list[nb].videos[0] = element.videos[0].replace(st2, `height="${this.embedH}"`);
          } else if (element.videos[0].includes('www.tiktok.com') === true && element.videos[0].includes('data-video-id="') === true) { // tiktok
            const tkID = element.videos[0].split('data-video-id="')[1].split('"')[0];
            this.$store.state.history.list[nb].videos[0] = `<iframe src="https://www.tiktok.com/embed/v2/${tkID}?lang=zh-TW" style="width: 100%; height: 500px;display: block;visibility: unset;"></iframe>`;
          }
        }
      });
      return this.$store.state.history.list;
    },
  },
  methods: {
    ...mapActions('history', ['init', 'loadMore']),
    ...mapMutations('history', ['reset']),
    ...mapActions('dashboard', ['redirectPost']),
  },
  mounted() {
    this.reset();
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.dynamicWall{
  margin-top: 12px;
}
.tag{
  width: 90px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active{
    background: #DD4B6E;
    color: white;
  }
}
.filterOption{
  font-size: 14px;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  opacity: 1;
  padding: 3px 10px;
  cursor: pointer;
}
.filterbyorder{
  position:relative;
}
// dynamicWallContent
.dynamicWallContent{
  margin-top: 10px;
}
.cardBox{
  cursor: pointer;
    position: relative;
    width: 330px;
    margin: 25px auto 0 auto;
  .cardBox-head{
    // max-width:130px;
    // height: 110px;
    width: 100%;
    height: 180px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 2px 2px #00000029;
    position: relative;
    img[alt="main"]{
      width: auto;
      height: 100%;
      text-align: center;
      border-radius: 10px;
      z-index: 1;
    }
    .readedNum{
      display: flex;
      align-items:center;
      justify-content: flex-end;
      position: absolute;;
      right:10px;
      bottom :10px;
      z-index: 100;
      span{
        margin-left: 10px;
        font-size: 12px;
        line-height: 12px;
        color: white;
      }
    }
  }
  .cardBox-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #EBEBEB;
    height: 28px;
    overflow:hidden;
    font-size: 14px;
    color: #000;
    padding-top: 0px 12px;
    box-sizing: border-box;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    span{
      width:calc(100% - 24px);
      text-align: center;
      overflow : hidden;
      text-overflow : ellipsis;
      white-space : nowrap;
      display: inline-block;
      vertical-align: middle;
      margin-top: 4px;
    }
  }
}
.cardBox-body{
  // max-height: 152px;
  // max-width:130px;
  max-height: 200px;
  max-width:100%;
  overflow: hidden;
  border: 2px solid #DD4B6E;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 13px;
  color:#BAB9B9;
  position:relative;
  padding: 5px;
  .readedNum{
    position:absolute;
    bottom: 5px;
    right:10px;
  }
}
.cardBox-body.isLongHeight {
  height: auto;
  max-height: 500px;
  overflow: hidden;
}
// dot box
.dotBox{
  width: 56px;
  height: 35px;
  background: #EBEBEB 0% 0% ;
  border-radius: 22px;
  display: flex;
  align-items:center;
  justify-content: center;
  margin-top: 16px;
  margin-left: 16px;
  cursor: pointer;
}
.dot{
  width: 5px;
  height: 5px;
  background: #2277FF;
  border-radius: 50%;
}
.dotBox:hover{
  background: #2277ff;
  .dot{
    background: white;
  }
}
// order
.orderOutBox{
  position:relative;
  z-index: 100;
}
.order{
  font-size: 14px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  opacity: 1;
  padding:3px 10px;
  cursor: pointer;
}
.orderOptionBox{
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 17px;
  padding: 16px;
  box-sizing: border-box;
  position:absolute;
  top: 30px;
  right:0;
  margin-top: 16px;
  z-index: 1000;
  background: white;
  width: 270px;
}
.default{
  position:relative;
  z-index:100;
}
.grayBox{
  width: 32px;
  height: 32px;
  background: #BAB9B9;
  border-radius: 50%;
}
.pinkBox{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #F8A6E5;
}

//
.switchOn{
  width: 110px;
  height: 40px;
  display: flex;
  align-items: center;
  background: #2277FF;
  font-size: 18px;
  color: white;
  font-weight: bold;
  border-radius: 23px;
}
.switchOff{
  width: 110px;
  height: 40px;
  display: flex;
  align-items: center;
  background: #c4c2c2;
  font-size: 18px;
  color: white;
  font-weight: bold;
  border-radius: 23px;
  &.onlyLook{
    width: 150px;
  }
}
.check{
  width: 30px;
  height: 30px;
  background: white;
  color: #555555;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close{
  width: 30px;
  height: 30px;
  background: white;
  color: #555555;
  border-radius: 50%;
  margin-left: 16px;
  margin-right: 10px;
  cursor: pointer;
  padding-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
