import Vue from 'vue';
import Vuex from 'vuex';
import Multiselect from 'vue-multiselect';
import { VueMasonryPlugin } from 'vue-masonry';
import $ from 'jquery';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import axios from 'axios';
import VueYoutube from 'vue-youtube';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import jwtDecode from 'jwt-decode';
import Ads from 'vue-google-adsense';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap';
import helper from './store/modules/common/common';

Vue.use(require('vue-script2'));

Vue.use(VueMeta);
Vue.use(Ads.Adsense);
Vue.use(Ads.InArticleAdsense);
Vue.use(Ads.InFeedAdsense);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_API },
});

const params = new URL(window.location.href).searchParams;
if (params.has('t')) {
  localStorage.setItem('t', params.get('t'));
  const jwtData = jwtDecode(params.get('t'));
  localStorage.setItem('u', JSON.stringify(jwtData.data));
  Vue.$gtag.event('會員人數 ', {
    event_category: '新註冊會員',
    event_label: jwtData.data.mail,
    value: 1,
  });
  window.location = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}${window.location.hash}`;
}

if (params.has('bot_login')) {
  const type = params.get('bot_login');
  if (localStorage.getItem('t')) {
    window.location = process.env.VUE_APP_AD_ACCOUNT_URL;
  } else if (type === 'line') {
    window.location = `https://access.line.me/oauth2/v2.1/authorize?client_id=${process.env.VUE_APP_API_LINE_ID}&redirect_uri=${process.env.VUE_APP_BOT_OAUTH}line&response_type=code&state=line&scope=profile%20openid%20email`;
  } else if (type === 'fb') {
    window.location = `https://www.facebook.com/v16.0/dialog/oauth?client_id=${process.env.VUE_APP_API_FB_ID}&redirect_uri=${encodeURI(process.env.VUE_APP_BOT_OAUTH)}fb&state=fb&scope=email`;
  }
}

if (params.has('bgg')) {
  if (params.get('bgg') !== '1') {
    localStorage.setItem('bggID', params.get('bgg'));
  }
}

const requireComponent = require.context(
  './components',
  false,
  /Base[A-Z]\w+\.(vue|js)$/,
);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, ''),
    ),
  );
  Vue.component(
    componentName,
    componentConfig.default || componentConfig,
  );
});

// todo axios multiple request problem
let semaphore = 0;

axios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-prototype-builtins
    if (config.hasOwnProperty('url') && (config.url.indexOf('chat/') > 0)) {
      return config;
    }
    let elm = window.document.getElementById('amy-mask');
    if (!elm) {
      elm = window.document.createElement('div');
      elm.setAttribute('id', 'amy-mask');
      window.document.body.appendChild(elm);
    }
    elm.style.display = 'block';
    semaphore += 1;
    return config;
  },
);

axios.interceptors.response.use(
  (response) => {
    // eslint-disable-next-line no-prototype-builtins
    if (response.hasOwnProperty('config')) {
      const { config } = response;
      // eslint-disable-next-line no-prototype-builtins
      if (config.hasOwnProperty('url') && (config.url.indexOf('chat/') > 0)) {
        return response;
      }
    }
    semaphore -= 1;
    if (semaphore <= 0) {
      semaphore = 0;
      const elm = window.document.getElementById('amy-mask');
      elm.style.display = 'none';
    }
    return response;
  },
  (error) => {
    // eslint-disable-next-line no-prototype-builtins
    if (error.hasOwnProperty('request') && (error.request.status === 504)) {
      return Promise.reject(error);
    }
    semaphore -= 1;
    if (semaphore <= 0) {
      semaphore = 0;
      const elm = window.document.getElementById('amy-mask');
      elm.style.display = 'none';
    }
    return Promise.reject(error);
  },
);

window.$ = $;
Vue.component('multiselect', Multiselect);
Vue.use(VueMasonryPlugin);
Vue.use(VueYoutube);
Vue.use(Vuex);

Vue.mixin({
  methods: {
    copyText(text) {
      const textArea = window.document.createElement('textarea');
      textArea.value = text;
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      window.document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = window.document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        window.console.log(`Fallback: Copying text command was ${msg}`);
      } catch (err) {
        window.console.error('Fallback: Oops, unable to copy', err);
      }
      window.document.body.removeChild(textArea);
    },
    copyHref(item) {
      // eslint-disable-next-line no-prototype-builtins
      if (!item || !item.hasOwnProperty('type')) {
        this.copyText(helper.getRedirectPost());
        return;
      }
      this.copyText(`${window.location.protocol}//${window.location.host}/#${helper.getRedirectPost(item)}`);
    },
  },
});
Vue.config.productionTip = false;

Vue.$gtag.pageview({
  page_path: '/',
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
