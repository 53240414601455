import ajax from 'axios';

export const init = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/chat/init`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const polling = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/chat/polling`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const message = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/chat/message`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const read = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/chat/read`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const join = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/chat/join`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});
