import ajax from 'axios';

export const login = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/oauth/login`, data, {
  headers: {
    // Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
  },
});

export const create = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/group/create`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const saveCard = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/card`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const setCard = (data) => ajax.put(`${process.env.VUE_APP_API_DOMAIN}/user/card`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const followUser = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/follow_user`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const setting = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/setting`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const followGroup = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/follow_group`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const addFriend = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/add_friend`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 更正使用者姓名,圖片
export const updateProfile = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/profile`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 開啟/關閉 廣告
export const advertisement = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/advertisement/${data}`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 點擊廣告
export const clickAd = (postID) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/click_ad/${postID}`, postID, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 是否可點擊文章廣告
export const clickAdAvailable = (postID) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/click_ad_available/${postID}`, postID, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 點擊記錄
export const counterAd = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/advertisement/counter`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 帳務記錄
export const recordsAd = (page, perpage) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/advertisement/account_records?page=${page}&per_page=${perpage}`, [], {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 帳務出帳
export const transferAd = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/advertisement/transfer`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 好友排行榜-最近互動
export const rankingRecords = (page, perpage, all) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/ranking/records?page=${page}&per_page=${perpage}&all=${all}`, [], {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 好友排行榜-點擊
export const rankingClicks = (page, perpage, all) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/ranking/clicks?page=${page}&per_page=${perpage}&all=${all}`, [], {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 全站排行榜-點擊
export const rankingClicksAll = (page, perpage, all) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/ranking/all_clicks?page=${page}&per_page=${perpage}&all=${all}`, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 收款E-Mail
export const emailCode = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/advertisement/code`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 儲存key值
export const storageSave = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/storage/save`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 讀取key值
export const storageLoad = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/storage/load`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// bgg探險任務資料
export const BggQuests = (bggID) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/bgg/init`, bggID, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// bgg送出探險(找蛋)
export const BggStartQuest = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/bgg/start_quest`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// bgg任務按讚
export const BggGoodQuest = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/bgg/good`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// bgg完成任務領獎賞
export const BggCompleteQuest = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/bgg/complete`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// bgg網紅比賽排名(當日)
export const BggRank = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/bgg/rank`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});
