import ajax from 'axios';

export const sendMail = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/oauth/send_code`, data, {
  headers: {
    // Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
  },
});

export const createUser = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/oauth/register`, data, {
  headers: {
    // Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
  },
});
