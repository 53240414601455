<template lang="pug">
div
  //-.d-flex.justify-content-center.my-5
    .all.d-flex.justify-content-center.align-items-center 全部
    .newest.d-flex.justify-content-center.align-items-center 顯示最新
  .d-flex.flex-column.align-items-center
  div
    .grayBottomBorderBox.d-flex.align-items-center.justify-content-between(v-if="cardId > 0 && !mute")
      .leaveMessageCircle(:style="`background-image: url('${card.img}');background-size: cover;`")
      input.form-control(type="text" v-on:keyup.enter.stop="create" v-model="content").mx-1
      button.btn.btn-send(@click="create" type="button") 送出
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'BaseMessageBoard',
  data() {
    return {
      idx: -1,
    };
  },
  methods: {
    ...mapActions('messageBoard', ['init', 'create', 'remove', 'good', 'bad']),
    ...mapActions('dashboard', ['report']),
  },
  computed: {
    ...mapFields('messageBoard', ['page', 'messages', 'empty', 'content', 'mute']),
    ...mapFields('account', ['card']),
    ...mapGetters('account', ['cardId']),
  },
  mounted() {
    this.page = 1;
    this.content = '';
    this.init();
  },
};

</script>

<style lang="scss" scoped>
.eachPostContent {
  padding: 100px 150px;
  // padding-left:30px;
  padding-top: 16px;
  // padding-bottom: 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: scroll;
  height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

hr {
  width: 100px;
  border-color: #ebebeb;
  border-width: 3px;
}

.from {
  font-size: 18px;
  color: #BAB9B9;
  font-weight: bold;
}

.fromBlueBox {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  background: #2277ff;
}

.eachPageName {
  font-size: 18px;
  font-weight: bold;
}

.grayBorderBox {
  margin-top: 40px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 3px #00000029;
}

.nameCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #555;
}

.name {
  font-size: 18px;
  color: #555;
}

.pinGrayBorder {
  height: 40px;
  border: 3px solid #EBEBEB;
  border-radius: 20px;
  border-left: 0;
}

.pin {
  width: 40px;
  height: 40px;
}

.followWord {
  color: #555555;
  margin-left: 8px;
  position: relative;

  .whoFollow {
    width: 600px;
    padding: 16px;
    border-radius: 17px;
    // height: 700px;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    position: absolute;
    top: 150%;
    left: -300px;
    background: white;
    z-index: 100;
  }

  .btn-follow {
    width: 120px;
    height: 40px;
    border-radius: 27px;
    border: 2px solid #2277FF;
    color: #2277ff;
    font-weight: bold;
  }

  .btn-follow:hover {
    background: #2277FF;
    color: white;
  }
}

// 分享跟其他功能 下拉視窗
.grayCircle {
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, .11);
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  .shareOption {
    position: absolute;
    width: 350px;
    padding: 16px;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    background: white;
    z-index: 100;
    top: 130%;
    right: 0;
    border-radius: 17px;
    font-size: 18px;

    .shareRedCircle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #dd4b6e;
    }
  }

  .otherFunction {
    position: absolute;
    width: 300px;
    padding: 16px;
    box-shadow: 0px 0px 20px #00000033;
    border: 2px solid #FFCEDA;
    background: white;
    z-index: 100;
    top: 130%;
    right: 0;
    border-radius: 17px;
    font-size: 18px;

    .shareRedCircle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #dd4b6e;
    }
  }
}

.threeBlueDot {
  width: 8px;
  height: 8px;
  background: #2277ff;
  border-radius: 50%;
}

.photoTitle {
  font-size: 18px;
  color: #555555;
  font-weight: bold;
}

.grayBadge {
  width: 80px;
  height: 32px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .1);
  font-size: 14px;
  color: black;
  margin: 4px 6px;
}

.grayBg {
  padding: 16px;
  background: rgba(235, 235, 235, .4);
  border-radius: 14px;
  margin-top: 40px;
}

.personalCover {
  background: #dd4b6e;
  border-radius: 15px;
  color: white;
  height: 130px;
  position: relative;

  .blackcircle {
    position: absolute;
    width: 70px;
    height: 70px;
    border: 3px solid white;
    background: black;
    border-radius: 50%;
    top: calc(100% - 35px);
    left: 20px;
  }

  .amyNickName {
    top: 125%;
    position: absolute;
    left: 20px;
    color: black;
    font-weight: bold;
  }
}

// adminBtn
.adminBtn {
  width: 140px;
  height: 54px;
  border-width: 2px;
  box-sizing: border-box;
  border-radius: 27px;
  position: relative;
  background: transparent;
  background: white;
  color: black;

  &.border-primary, &.border-dark {
    border-left: 0px;
  }

  &:focus {
    outline: none;
  }

  &.borderBlack {
    border-color: black;
    color: black;
  }

  .imgBox {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;

    &.border-primary {
      border: 2px solid #DD4B6E;
    }

    img {
      width: 30px;
    }
  }
}

.socialDetail {
  margin-left: 10px;
  padding: 12px;
  background: rgba(235, 235, 235, .5);
  border-radius: 15px;

  td {
    color: #555555;
    font-weight: bold;
    width: 50%;
  }
}

.eachLittlePostContent {
  overflow: auto;
}

.eachLittlePost {
  // overflow-x:scroll;
  width: 150px;

  .imgBox {
    position: relative;
  }

  .mainImg {
    width: 150px;
    height: 150px;
    border-radius: 10px;
  }

  .eye {
    position: absolute;
    bottom: 16px;
    right: 50px;
  }

  .watchedNumber {
    position: absolute;
    color: white;
    font-weight: bold;
    right: 10px;
    bottom: 18px;
    font-size: 14px;
  }

  .detailContent {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; //行數
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.all {
  width: 145px;
  height: 50px;
  border-radius: 25px 0 0 25px;
  background: rgba(235, 235, 235, .2);
  font-size: 18px;
  color: #EBEBEB;
  font-weight: bold;
  cursor: pointer;
}

.newest {
  width: 145px;
  height: 50px;
  border-radius: 0 25px 25px 0;
  color: #555555;
  font-weight: bold;
  background: #EBEBEB;
  cursor: pointer;
}

.eachMemberDiscuss {
  border-radius: 7px;

  .memberCircle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #555;
  }

  .memberName {
    color: #555555;
    font-size: 18px;
    font-weight: bold;
    margin-left: 8px;
  }

  .followRedBox {
    border: 2px solid #DD4B6E;
    border-radius: 17px;
    height: 34px;
    color: black;
    background: #FFCEDA;
    padding: 14px;
    margin-left: 16px;
  }
}

.date {
  font-size: 14px;
  color: #BAB9B9;
  font-weight: bold;
}

.contentDetail {
  color: #555555;
  font-weight: bold;
}

.countBox {
  width: 65px;
  height: 36px;
  border-radius: 18px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #DD4B6E;
  position: relative;
  cursor: pointer;

  .badge {
    position: absolute;
    bottom: -8px;
    right: -10px;
  }
}

.threeDotGrayBox {
  width: 45px;
  height: 45px;
  background: rgba(0, 0, 0, .1);
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  .memberDiscussAction {
    box-shadow: 0px 0px 6px #00000029;
    position: absolute;
    width: 250px;
    height: 55px;
    border-radius: 17px;
    color: #2277FF;
    font-size: 14px;
    background: white;
    top: 100%;
    right: 0;
  }
}

.eachMemberDiscuss:nth-child(odd) {
  background: #0000001A;
}

.showMore {
  background: rgba(257, 127, 258, .2);
  color: black;
  font-weight: bold;
  height: 60px;
  border-radius: 30px;
}

.grayBottomBorderBox {
  padding: 16px;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 0px 0px 28px 28px;

  .leaveMessageCircle {
    width: 45px;
    height: 45px;
    background: #555;
    border-radius: 50%;
  }

  .form-control {
    width: 80%;
    border-radius: 24px;
    height: 45px;
    background: rgba(0, 0, 0, .04);
    font-size: 16px;
  }

  .btn-send {
    height: 45px;
    border-radius: 30px;
    background: #dd4b6e;
    color: white;
    width: 80px;
  }
}

@media (max-width:980px){
  .grayBottomBorderBox {
    padding: 0.5rem!important;
  }
  .grayBottomBorderBox .btn-send, .grayBottomBorderBox .form-control{
    height: 35px!important;
  }
  .grayBottomBorderBox .leaveMessageCircle {
    width: 30px!important;
    height: 30px!important;
  }
}
</style>
