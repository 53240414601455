import ajax from 'axios';

export const init = () => ajax.get(`${process.env.VUE_APP_API_DOMAIN}/public_chat/init`, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const push = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/public_chat/push`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});
