import Vue from 'vue';
import Vuex from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import cameCase from 'lodash/camelCase';

const requireModule = require.context('./modules', false, /\.js$/);
const modules = {};
requireModule.keys().forEach((fileName) => {
  fileName.replace(/(\.\/|\.js)/g, '');
  modules[cameCase(fileName.slice(0, -2))] = {
    namespaced: true,
    ...requireModule(fileName),
  };
});
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showRightContent: false,
    // 開啟新增社團modal
    showNewClubModal: false,
    // 開啟通知Ｍodal
    showNoticeModal: false,
    // 開啟訊息Modal
    showMessageModal: false,
    // 左下角對話視窗
    showChatModal: false,
    // 首頁左下角兩個黑色小視窗
    smallPost: false,
    smallChat: false,
    showBigChat: false,
    // 專頁列表
    pageList: false,
    // 邀請好友
    showInviteFriend: false,
    settingModal: false,
    // 切換首頁右邊欄位內容(動態牆 瀏覽過的)
    switchoption: 'wall',
    rightContentOption: 'inhot',
    // 開啟關於我們
    showAboutUS: false,
    // 開啟主帳戶設定
    showMainAccount: false,
    // 開啟使用者條款
    showUserTerm: false,
    // 開啟廣告記帳
    showAdvertise: false,
    // 開啟拔古雞Content
    showBggContent: false,
    // 開啟隱私政策
    showPrivacyPolicy: false,
    // 顯示小雞幫導引首頁
    showGroupIntroUI: false,
    // 開啟離開PO文提示UI
    showLeavePostHint: false,
    // 清除PO文內容提示UI
    showClearPostHint: false,
    // AI詢問次數上限提示UI
    showAILimitMax: 0, // 0: 不顯示 >0 限制次數
  },
  mutations: {
    updateField,
    // 展開右邊
    showRightContent(state) {
      if (!state.account.user) {
        window.localStorage.clear();
        this.$router.push('/login');
        return;
      }
      state.showRightContent = !state.showRightContent;
    },
    // 切換動態牆 瀏覽過的 ID name
    switchOption(state, payLoad) {
      state.switchoption = payLoad;
    },
  },
  actions: {},
  getters: {
    getField,
  },
  modules,
});
