import { getField, updateField } from 'vuex-map-fields/dist/index.esm';
import Vue from 'vue';
import api from '../../api';
import helper from './common/common';
import router from '../../router';

export const state = {
  mail: '',
  step: 'three',
  code: '',
  showModal: false,
  password: '',
  ...helper.state,
};

export const mutations = { updateField, ...helper.mutations };

export const getters = { getField };

export const actions = {
  sendMail: ({ state, commit }) => {
    api.register.sendMail({ mail: state.mail }).then(() => {
      commit('setValue', { key: 'step', val: 'three' });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    }).finally(() => {
      commit('resetClickOnce');
    });
  },
  createUser: ({ state, commit }) => {
    api.register.createUser({
      mail: state.mail,
      code: state.code,
      password: state.password,
    }).then(() => {
      commit('setValue', { key: 'showModal', val: true });
      Vue.$gtag.event('會員人數', {
        event_category: '會員人數',
        event_label: state.mail,
        value: 1,
      });
      setTimeout(() => {
        commit('setValue', { key: 'showModal', val: false });
        router.push('/login/loginEnterBox#/');
      }, 3000);
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
      commit('resetClickOnce');
    });
  },
  lineLogin: () => {
    if (window.location.hostname === 'localhost') {
      window.location = `https://access.line.me/oauth2/v2.1/authorize?client_id=${process.env.VUE_APP_API_LINE_ID}&redirect_uri=${process.env.VUE_APP_OAUTH}line/1&response_type=code&state=line&scope=profile%20openid%20email`;
    } else {
      window.location = `https://access.line.me/oauth2/v2.1/authorize?client_id=${process.env.VUE_APP_API_LINE_ID}&redirect_uri=${process.env.VUE_APP_OAUTH}line&response_type=code&state=line&scope=profile%20openid%20email`;
    }
  },
  fbLogin: () => {
    if (window.location.hostname === 'localhost') {
      window.location = `https://www.facebook.com/v16.0/dialog/oauth?client_id=${process.env.VUE_APP_API_FB_ID}&redirect_uri=${encodeURI(process.env.VUE_APP_OAUTH)}fb/1&state=fb&scope=email`;
    } else {
      window.location = `https://www.facebook.com/v16.0/dialog/oauth?client_id=${process.env.VUE_APP_API_FB_ID}&redirect_uri=${encodeURI(process.env.VUE_APP_OAUTH)}fb&state=fb&scope=email`;
    }
  },
  googleLogin: () => {
    if (window.location.hostname === 'localhost') {
      window.location = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.VUE_APP_API_GOOGLE_ID}&redirect_uri=${process.env.VUE_APP_OAUTH}google/1&response_type=code&scope=profile email&state=google`;
    } else {
      window.location = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.VUE_APP_API_GOOGLE_ID}&redirect_uri=${process.env.VUE_APP_OAUTH}google&response_type=code&scope=profile email&state=google`;
    }
  },
};
