<template lang="pug">
  .mainContentOnlyWord.onlyWordBorder
    .d-grid-word
      .amyId.d-flex.align-items-center
        .blackCircle(:style="`background-image: url('${post.userHead}');background-size: cover;`")
        p.mb-0.font-weight-bold {{post.userName}}
      .description
        p.text-justify.mb-4(v-html="post.content")
        .d-flex.align-items-center
          .text-decoration-none.mb-0.text-left.seeMore.mr-5.text-log(@click="redirectPost({data:post})") 看更多
          p.mb-0.text-from -來自
          .blueBox.mx-3(:style="`background-image: url('${post.groupHead}');background-size: cover;`")
          .text-decoration-none.text-log.mb-0(@click="redirectPost({data:post})") {{post.groupName}}
      //.watchedNum 瀏覽人數{{post.views}}人
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'BaseContent',
  props: ['post'],
  methods: {
    ...mapActions('dashboard', ['redirectPost']),
  },
};
</script>
