import { getField, updateField } from 'vuex-map-fields';
import api from '../../api';
import helper from './common/common';

export const state = {
  tag: 'insideSearch',
  order: 'desc',
  ...helper.state,
};

export const mutations = {
  updateField,
  ...helper.mutations,
};

export const getters = {
  getField,
  ...helper.getters,
};

export const actions = {
  init: ({ commit, rootState }) => {
    api.dynamicWall.init({
      page: state.page, order: state.order, cardId: rootState.account.card ? rootState.account.card.id : 0, tag: state.tag,
    }).then((rep) => {
      commit('setList', { list: rep.data.body.response });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  changeTagName: ({
    commit, rootState, state, dispatch,
  }, { tag }) => {
    commit('setValue', { key: 'tag', val: tag });
    commit('reset');
    dispatch('init');
  },
  loadMore: ({ state, commit, dispatch }) => {
    commit('setValue', { key: 'page', val: state.page + 1 });
    dispatch('init');
  },
  ...helper.actions,
};
