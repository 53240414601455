<template lang="pug">
  div(v-html="code")
</template>

<script>

export default {
  props: {
    code: {
      type: String,
    },
  },
  methods: {
    injectScript() {
      const protocolToUse = 'https:';
      const s = document.createElement('script');
      s.async = true;
      s.defer = true;
      s.src = `${protocolToUse}//platform.instagram.com/en_US/embeds.js`;
      s.id = 'vue-instagram-embed-script';
      // const { body } = document.body;
      if (document.body) {
        document.body.appendChild(s);
      }
    },
    checkAPI() {
      return new Promise((resolve) => {
        (function checkAPI(selff) {
          const self = selff;
          self.timer = window.setTimeout(() => {
            if (window.instgrm) {
              clearTimeout(self.timer);
              resolve();
            } else {
              checkAPI(self);
            }
          }, 20);
        }(this));
      });
    },
  },
  mounted() {
    if (window.instgrm) {
      // this.fetchEmbed();
      window.instgrm.Embeds.process();
    } else {
      this.injectScript();
      this.checkAPI().then(() => {
        window.instgrm.Embeds.process();
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
