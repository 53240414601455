import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue';
import api from '../../api';
import helper from './common/common';

export const state = {
  contents: [],
  text: '',
  users: [],
  activeUserId: -1,
  lastRead: [],
  lastId: -1,
  start: false,
  ...helper.state,
};

export const mutations = {
  updateField,
  ...helper.mutations,
  setActiveUserId: (state, { id }) => {
    state.activeUserId = id;
    state.lastRead[id] = +new Date();
    state.lastRead = { ...state.lastRead };
  },
  setLast: (state) => {
    if (state.users.length <= 0) return;
    state.lastRead[state.activeUserId >= 0 ? state.activeUserId : state.users[0].id] = +new Date();
    state.lastRead = { ...state.lastRead };
  },
};

export const getters = {
  targetId(state) {
    return state.activeUserId >= 0 ? state.activeUserId : state.users[0].id;
  },
  unreadTotal(state) {
    if (state.users <= 0) return 0;
    let unread = 0;
    Object.keys(state.users).forEach((k) => {
      const { id } = state.users[k];
      if (!Object.prototype.hasOwnProperty.call(state.contents, id)) {
        return;
      }
      if (!Object.prototype.hasOwnProperty.call(state.lastRead, id)) {
        return;
      }
      unread += state.contents[id].filter((ele) => ele[4] > state.lastRead[id]).length;
    });
    return unread;
  },
  unread(state) {
    if (state.users <= 0) return [];
    const unread = [];
    Object.keys(state.users).forEach((k) => {
      const { id } = state.users[k];
      if (!Object.prototype.hasOwnProperty.call(state.contents, id)) {
        unread[id] = 0;
        return;
      }
      if (!Object.prototype.hasOwnProperty.call(state.lastRead, id)) {
        unread[id] = state.contents[id].length;
        return;
      }
      unread[id] = state.contents[id].filter((ele) => ele[4] > state.lastRead[id]).length;
    });
    return unread;
  },
  currentContents(state, getters) {
    if (state.users <= 0) return [];
    const userId = getters.targetId;
    // eslint-disable-next-line no-prototype-builtins
    return state.contents.hasOwnProperty(userId) ? state.contents[userId].sort((a, b) => new Date(a[2]) - new Date(b[2])) : [];
  },
  getField,
};

export const actions = {
  // 初始化
  init: ({
    commit, rootState, state, dispatch,
  }) => {
    if (state.start) return;
    if (!localStorage.getItem('t')) return;
    commit('setValue', { key: 'start', val: true });
    api.chat.init({ cardId: rootState.account.card.id }).then((rep) => {
      commit('setValue', { key: 'users', val: rep.data.body.users });
      commit('setValue', { key: 'contents', val: rep.data.body.contents });
      commit('setValue', { key: 'lastRead', val: rep.data.body.lastRead });
      commit('setValue', { key: 'lastId', val: rep.data.body.lastId });
      dispatch('polling');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 初始化
  chatinit: ({
    commit, rootState, state, dispatch,
  }) => {
    if (state.start) return;
    if (!localStorage.getItem('t')) return;
    commit('setValue', { key: 'start', val: true });
    api.chat.init({ cardId: rootState.account.card.id }).then((rep) => {
      commit('setValue', { key: 'users', val: rep.data.body.users });
      commit('setValue', { key: 'contents', val: rep.data.body.contents });
      commit('setValue', { key: 'lastRead', val: rep.data.body.lastRead });
      commit('setValue', { key: 'lastId', val: rep.data.body.lastId });
      dispatch('polling');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 抓訊息
  polling: ({
    commit, dispatch, rootState, state,
  }) => {
    api.chat.polling({ cardId: rootState.account.card.id, start: state.lastId }).then((rep) => {
      commit('setValue', { key: 'users', val: rep.data.body.users });
      commit('setValue', { key: 'lastId', val: rep.data.body.lastId });
      Object.keys(rep.data.body.contents).forEach((k) => {
        if (Object.prototype.hasOwnProperty.call(state.contents, k)) {
          state.contents[k] = [...rep.data.body.contents[k], ...state.contents[k]];
        } else {
          state.contents[k] = rep.data.body.contents[k];
        }
      });
      commit('setValue', { key: 'contents', val: { ...state.contents } });
      dispatch('polling');
    }).catch((error) => {
      if (error.response && error.response.status === 504) {
        // dispatch('polling');
        return;
      }
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 傳訊息
  message: ({
    commit, getters, rootState, state,
  }) => {
    if (!state.text) return;
    api.chat.message({
      cardId: rootState.account.card.id,
      targetId: getters.targetId,
      text: state.text,
    }).then((rep) => {
      commit('setValue', { key: 'text', val: '' });
      commit('setValue', { key: 'lastId', val: rep.data.body.lastId });
      state.lastRead[getters.targetId] = +new Date();
      Vue.$gtag.event('發送訊息', {
        event_category: '發送訊息',
        event_label: rootState.account.card.id,
        value: +new Date(),
      });
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 把UI目前聊天畫面切換到這使用者
  setActiveUserId: ({ commit, getters, rootState }, { id }) => {
    commit('setActiveUserId', { id });
    if (!localStorage.getItem('t')) return;
    api.chat.read({ cardId: rootState.account.card.id, targetId: getters.targetId }).then(() => {
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // 把使用者加到聊天室UI的使用者列表
  joinChat: ({
    state, commit, getters, rootState, dispatch,
  }, { id }) => {
    if (!localStorage.getItem('t')) return;
    if (id === rootState.account.card.id || id <= 0) return;
    api.chat.join({ cardId: rootState.account.card.id, targetId: id }).then((rep) => {
      // eslint-disable-next-line no-param-reassign
      rootState.showBigChat = true;
      if (state.start) return;
      commit('setValue', { key: 'start', val: true });
      commit('setValue', { key: 'users', val: rep.data.body.users });
      commit('setValue', { key: 'contents', val: rep.data.body.contents });
      commit('setValue', { key: 'lastRead', val: rep.data.body.lastRead });
      commit('setValue', { key: 'lastId', val: rep.data.body.lastId });
      dispatch('init');
    }).catch((error) => {
      commit('ui/ajaxAlertError', { error }, { root: true });
    });
  },
  // join
  ...helper.actions,
};
