import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    children: [
      {
        path: '',
        name: 'loginbox',
        component: () => import('../components/login/loginBox.vue'),
      }, {
        path: 'loginEnterBox',
        name: 'loginEnterBox',
        component: () => import('../components/login/loginEnterBox.vue'),
      },
      {
        path: 'registerBox',
        name: 'registerBox',
        component: () => import('../components/login/registerBox.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/ad',
    name: 'ad',
    component: () => import('../views/adbegin.vue'),
  },
  {
    path: '/adcount',
    name: 'adcount',
    component: () => import('../views/quickpost.vue'),
  },
  {
    path: '/adpost',
    name: 'adpost',
    component: () => import('../views/quickpost.vue'),
  },
  {
    path: '/bggpost',
    name: 'bggpost',
    component: () => import('../views/bggpost.vue'),
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('../views/guide.vue'),
  },
  {
    path: '/groupshare',
    name: 'groupshare',
    component: () => import('../views/groupshare.vue'),
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/greeting.vue'),
  },
  {
    path: '/msg',
    name: 'msg',
    component: () => import('../views/greetingMsg.vue'),
  },
  {
    path: '/share/:id',
    name: 'postShare',
    component: () => import('../views/postShare.vue'),
  },
  {
    path: '/share2/:id',
    name: 'postShare2',
    component: () => import('../views/postShare.vue'),
  },
  {
    path: '/ad/:id',
    name: 'postTimeList',
    component: () => import('../views/postTimeList.vue'),
  },
  {
    path: '/bggpost/:id',
    name: 'bggpostTimeList',
    component: () => import('../views/postTimeListBgg.vue'),
  },
  {
    path: '/post/:id',
    name: 'post',
    component: () => import('../views/post.vue'),
  },
  {
    path: '/category/:category1/:category2?/:category3?/:category4?/:category5?',
    name: 'category',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/group/:id',
    name: 'group',
    component: () => import('../views/group.vue'),
  },
  {
    path: '/eachPost/:type/:id',
    name: 'eachPost',
    component: () => import('../views/eachPost.vue'),
  },
  {
    path: '/eachVideoPost/:type/:id',
    name: 'eachVideoPost',
    component: () => import('../views/eachVideoPost.vue'),
  },
  {
    path: '/eachWordPost/:type/:id',
    name: 'eachWordPost',
    component: () => import('../views/eachWordPost.vue'),
  },
  {
    path: '/longRightBase',
    name: 'longRightBase',
    component: () => import('../views/longRightBase.vue'),
    children: [
      {
        path: 'addNewClubPage',
        name: 'addNewClubPage',
        component: () => import('../components/longrightbase/addNewClubPage.vue'),
        children: [
          {
            path: '',
            name: 'settingAttribute',
            component: () => import('../components/longrightbase/settingAttribute.vue'),
          },
          {
            path: 'beautifyPages',
            name: 'beautifyPages',
            component: () => import('../components/longrightbase/beautifyPages.vue'),
          },
          {
            path: 'authorityAdmin',
            name: 'authorityAdmin',
            component: () => import('../components/longrightbase/authorityAdmin.vue'),
          },
        ],
      },
      {
        path: 'adminOldClub',
        name: 'adminOldClub',
        component: () => import('../components/longrightbase/adminOldClub.vue'),
        children: [
          {
            path: '',
            name: 'adminOldClubList',
            component: () => import('../components/longrightbase/adminOldClubList.vue'),
          },
          {
            path: 'settingAttribute/:id',
            name: 'settingAttribute',
            component: () => import('../components/longrightbase/settingAttribute.vue'),
          },
          {
            path: 'beautifyPages',
            name: 'beautifyPages',
            component: () => import('../components/longrightbase/beautifyPages.vue'),
          },
          {
            path: 'authorityAdmin',
            name: 'authorityAdmin',
            component: () => import('../components/longrightbase/authorityAdmin.vue'),
          },
        ],
      },
      {
        path: 'setting',
        name: 'setting',
        // component: () => import('../components/longrightbase/setting.vue'),
        component: () => import('../components/longrightbase/setting_AI.vue'),
        children: [
          {
            path: '',
            name: 'mainAccount',
            component: () => import('../components/settingcomponent/mainAccount.vue'),
          },
          {
            path: 'cardSetting',
            name: 'cardSetting',
            component: () => import('../components/settingcomponent/cardSetting.vue'),
          },
          {
            path: 'postShowSetting',
            name: 'postShowSetting',
            component: () => import('../components/settingcomponent/postShowSetting.vue'),
          },
          {
            path: 'messageAlertSetting',
            name: 'messageAlertSetting',
            component: () => import('../components/settingcomponent/messageAlertSetting.vue'),
          },
          {
            path: 'userTerm',
            name: 'userTerm',
            component: () => import('../components/settingcomponent/userTerm.vue'),
          },
          {
            path: 'aboutUs',
            name: 'aboutUs',
            component: () => import('../components/settingcomponent/aboutUs.vue'),
          },
          {
            path: 'advertise',
            name: 'advertise',
            component: () => import('../components/settingcomponent/advertise.vue'),
          },
          {
            path: 'privacyPolicy',
            name: 'privacyPolicy',
            component: () => import('../components/settingcomponent/privacyPolicy.vue'),
          },
        ],
      },
      {
        path: 'inviteFriend',
        name: 'inviteFriend',
        component: () => import('../components/invitefriend/inviteFriend.vue'),
        children: [
          {
            path: '',
            name: 'myInviteCode',
            component: () => import('../components/invitefriend/myInviteCode.vue'),
          },
          {
            path: 'friendList',
            name: 'friendList',
            component: () => import('../components/invitefriend/friendList.vue'),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath !== '/') {
    Vue.$gtag.pageview({
      page_path: to.fullPath,
    });
  }
  next();
});

export default router;
