import ajax from 'axios';

export const init = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/dashboard/post`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const del = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/dashboard/post_del`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const good = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/group/good`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const bad = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/group/bad`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 取得文章留言列表
export const getComments = (postID) => ajax.get(`${process.env.VUE_APP_API_DOMAIN}/post/comments/${postID}`, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 留言
export const comment = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/post/comment`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 刪除文章/留言
export const postDel = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/post/del`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 刪除附件
export const postDelFile = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/post/del_file`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 文章刪除標籤
export const postDelTag = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/post/del_tag`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 判定可否使用該Tag
export const availableTag = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/post/available_tag`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 編輯文章
export const postUpdate = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/post/update`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 封鎖
export const postBlock = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/block`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 解封
export const postUnBlock = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/unblock`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 解封
export const getBlockList = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/user/block_list`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 檢舉文章
export const postReport = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/post/report`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});
