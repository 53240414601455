import ajax from 'axios';

export const init = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/inhot/init`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const saveInHot = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/inhot/save_categories`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});
