import ajax from 'axios';

export const init = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/exposed/init`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const createForm = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/exposed/create_form`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
    'Content-Type': 'multipart/form-data',
  },
});

export const initList = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/exposed/list`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const postcreate = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/post/create`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 讀取 Meta
export const getUrlMeta = (data) => ajax.post(`${process.env.VUE_APP_LINK_API_DOMAIN}?url=${encodeURIComponent(data.url)}`, {
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});
