import ajax from 'axios';
import Vue from 'vue';

const tags = new Map();

export const init = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/dashboard/init`, (data), {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const searchCategory = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/dashboard/search_category`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const loadPosts = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/dashboard/load_posts`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const report = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/dashboard/report`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const loadTagsTable = (language) => ajax.get(`${process.env.VUE_APP_API_DOMAIN}/dashboard/tags/${language}`, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
}).then((result) => {
  Object.values(result.data.body).forEach((v) => {
    Object.values(v.tags).forEach((t) => {
      tags.set(t[0], t[1]);
    });
  });
  return result;
});

export const timelinelist = ({ language, data }) => {
  // window.console.log(data);
  const date = new Date().toISOString().slice(0, 10);
  data.forEach((element) => {
    element[4].forEach((tag) => {
      if (tags.get(tag)) {
        const key = date + tag;
        Vue.$gtag.event('重複計算', {
          event_category: '點擊Tag',
          event_label: tags.get(tag),
          value: 1,
        });
        if (!window.localStorage.getItem(key)) {
          Vue.$gtag.event('不重複計算', {
            event_category: '點擊Tag',
            event_label: tags.get(tag),
            value: 1,
          });
        }
      }
    });
  });
  return ajax.post(`${process.env.VUE_APP_API_DOMAIN}/timeline/list/${language}?per_page=20`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('t')}`,
    },
  });
};

export const timelineSave = ({
  language,
  data,
}) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/timeline/save`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const timelineLoad = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/timeline/load`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 取得單篇文章內容
export const getPostContent = (PostID) => ajax.get(`${process.env.VUE_APP_API_DOMAIN}/timeline/get/${PostID}`, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

export const loadUserRecommend = ({ language, data }) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/dashboard/user_recommend/${language}`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 追蹤單篇文章
export const followPost = (PostID) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/post/follow/${PostID}`, {}, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// 取消追蹤單篇文章
export const unfollowPost = (PostID) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/post/unfollow/${PostID}`, {}, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});

// AI詢問
export const aiCompletion = (data) => ajax.post(`${process.env.VUE_APP_API_DOMAIN}/ai/completion`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('t')}`,
  },
});
